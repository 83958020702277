import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  marginTop40: {
    marginTop: '40px',
    '@media (max-width:900px)': {
      marginTop: '20px'
    }
  },
  marginTop20: {
    '@media (max-width:505px)': {
      marginTop: '20px'
    }
  },
  fullWidth: {
    width: '100%'
  },
  iconWrapper: {
    height: '52px',
    alignItems: 'center',
    borderLeft: '1px solid #e0e6eb',
    width: '54px',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.primary.main
  },
  outlinedIcon: {
    pointerEvents: 'none'
  },
  endAdornment: {
    paddingRight: 0
  },
  asterisk: {
    '@media (max-width:505px)': {
      marginTop: '20px'
    },
    '& .MuiFormLabel-asterisk': {
      color: '#005FEE'
    },
    maxHeight:' 60px',
    color: '#888',
  },
  txtField: {
    border: 'none',
    height: '50px'
  },
  grey: {
    color: 'grey'
  },
  inputContainer: {
    '@media (min-width:505px)': {
      gridTemplateColumns: '1fr',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr', 
      gap: '20px',
    },
    marginTop: '20px',
  },
  background: {
    padding: '35px',
    maxWidth: '550px',
    background: '#ffffff',
    borderRadius: '10px',
  },
  fontSize20: {
    fontSize: '20px',
    fontWeight: 500
  },
  width200: {
    width: '200px'
  }
}));

export default useStyles;
