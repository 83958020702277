import { Fragment, Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import LoadingState from '../../components/LoadingState/LoadingState';
import DroppableContent from '../../components/DroppableContent/DroppableContent';
import SideModal from '../../components/Snackbar/SideModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import Button from '../../components/Buttons/ActionButtons';
import Modal from '../../components/Modal/Modal';
import SearchInput from 'components/SearchInput/SearchInput';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';
import Radio from 'components/RadioBtn/RadioBtn';

import {
  fetchTatmisCategories,
  reassignCategories,
  changeModal,
  generateCategoriesOnTatami,
  getTatamisTheme,
  downloadFile,
  exportTournamentTatamiReport
} from '../../helpers/util';
import { REASSIGN_CATEGORIES_SORT_OPTIONS } from 'helpers/constants';
import { categoryTypesPresentOnTournament } from '../../helpers/selectors';

import { styles } from './StylesDragDrop';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
//moves an item from one list to another list
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class DragDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentData: props.tournamentData || {},
      categoryTypes: categoryTypesPresentOnTournament(
        props?.categoryTypes ?? [],
        props?.tournamentData?.category_types ?? []
      ),
      loading: true,
      isFilterOpen: false,
      showModal: false,
      selectedCheckboxes: [],
      blocks: [],
      tatamisTheme: [],
      searchBar: '',
      activeBlockIdx: 0,
      activeTatamiIdx: 0,
      openModal: false,
      openModalId: '',
      selCheckbox: ''
    };

    this.fetchTatmisCategories = fetchTatmisCategories.bind(this);
    this.reassignCategories = reassignCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.generateCategoriesOnTatami = generateCategoriesOnTatami.bind(this);
    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.exportTournamentTatamiReport = exportTournamentTatamiReport.bind(this);
    this.downloadFile = downloadFile.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryTypes, tournamentData } = this.props;

    if (prevProps?.categoryTypes !== categoryTypes) {
      const tournamentCat = categoryTypesPresentOnTournament(
        categoryTypes,
        tournamentData?.category_types
      );
      this.setState({ categoryTypes: tournamentCat });
    }
  }

  componentDidMount() {
    const { tournamentData } = this.state;
    const { id } = tournamentData;
    const { onGetPageHeaderStatistics } = this.props;

    onGetPageHeaderStatistics();
    this.fetchTatmisCategories(id, (categories) => {
      this.getTatamisTheme();
      this.fetchData(categories);
    });
  }

  fetchData = (reassignedCategories, cb) => {
    let blocks = reassignedCategories.reduce((acc, item) => {
      const { block } = item;
      if (!acc[block]) {
        acc[block] = {
          tatamis: [],
          block_id: item.block,
          block_name: item.block_name
        };
      }

      acc[block].tatamis.push({
        ...item,
        filteredCategories: item.categories
      });

      return acc;
    }, {});

    blocks = Object.values(blocks);

    this.setState({ blocks }, () => cb && cb());
  };

  resetCategories = () => {
    const { tournamentData } = this.state;
    this.fetchTatmisCategories(tournamentData.id, (categories) => {
      this.setState({ selectedCheckboxes: [] });
      this.fetchData(categories);
    });
    this.handleCloseModal();
  };

  generateCategoriesAndCloseModal = (evt, id) => {
    this.generateCategoriesOnTatami(evt, id);
    this.handleCloseModal();
  };

  onChangeTatami = (el, elIdx) => {
    this.setState({ activeTatamiIdx: elIdx, selCheckbox: '' });
  };

  onChangeBlock = (elIdx) => {
    this.setState({
      activeBlockIdx: elIdx,
      activeTatamiIdx: 0,
      selCheckbox: ''
    });
  };

  onDragEnd = (result) => {
    const { tournamentData, blocks, activeTatamiIdx, activeBlockIdx } =
      this.state;
    const { source, destination } = result;
    const destinationSplit = destination.droppableId.split('_');
    const destinationBlockIdx = destinationSplit[2];
    const destinationTatamiIdx = destinationSplit[4];

    let cpyBlocks = [...blocks];
    const tatamiDroppableId = `droppable_block_${activeBlockIdx}_tatami_${activeTatamiIdx}`;
    const blockDroppableId = `droppable_blocks_${activeBlockIdx}_tatami_${activeTatamiIdx}`;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const reorderedList = reorder(
        cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories,
        source.index,
        destination.index
      );

      const reorderedListFiltered = reorder(
        cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories,
        source.index,
        destination.index
      );

      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories = [
        ...reorderedListFiltered
      ];
      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories = [
        ...reorderedList
      ];

      this.reassignCategories({
        tournament_id: tournamentData.id,
        tatami_id:
          cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
            ?.tatami_id,
        categories:
          cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
            ?.categories
      });
    } else {
      //dragging a category to the tab that is active - dropableTabActive
      if (
        destination.droppableId === tatamiDroppableId ||
        destination.droppableId === blockDroppableId
      )
        return;
      else {
        const result = move(
          cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories,
          cpyBlocks[destinationBlockIdx].tatamis[destinationTatamiIdx]
            .filteredCategories,
          source,
          destination
        );

        // remove from droppables moved categories based on sourceFrom/ destinationTo
        const movedItem =
          cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories[
            source.index
          ];
        const findIdx = cpyBlocks[activeBlockIdx].tatamis[
          activeTatamiIdx
        ].categories.findIndex(
          (it) => +it.category_id === +movedItem.category_id
        );

        cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
          findIdx,
          1
        );
        cpyBlocks[destinationBlockIdx].tatamis[
          destinationTatamiIdx
        ].categories.unshift(movedItem);

        cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories =
          result.droppable_active;
        cpyBlocks[destinationBlockIdx].tatamis[
          destinationTatamiIdx
        ].filteredCategories = result[destination.droppableId];

        this.reassignCategories({
          tournament_id: tournamentData.id,
          tatami_id:
            cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
              ?.tatami_id,
          categories:
            cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
              ?.categories
        });
      }
    }

    this.setState({
      blocks: cpyBlocks
    });
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes, blocks } = this.state;
    const checkedValues = key?.id
      ? selectedCheckboxes.some((item) => item.id === key.id)
        ? selectedCheckboxes.filter((it) => it.id !== key.id)
        : [...selectedCheckboxes, key]
      : [];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      const blockLen = blocks.length;
      let allBlocks = [];

      let genderArray = [];
      let ageArray = [];
      let typeArray = [];

      checkedValues.map((it) => {
        if (it.gender) genderArray = [...genderArray, it.gender];
        if (it.age) ageArray = [...ageArray, it.age];
        if (it.type) typeArray = [...typeArray, it.id];
        return true;
      });

      for (let y = 0; y < blockLen; y++) {
        const len = blocks[y].tatamis.length;
        let filteredCategoriesPerTatami = [];

        for (let i = 0; i < len; i++) {
          let categories = [];
          const categoriesPerTatamiLen = blocks[y].tatamis[i].categories.length;

          for (let j = 0; j < categoriesPerTatamiLen; j++) {
            const category = blocks[y].tatamis[i].categories[j];

            const filterByGenderList =
              genderArray.length > 0
                ? genderArray.some((el) => category.category_gender === el)
                : category;

            const filterByAgeList =
              ageArray.length > 0
                ? ageArray.some((el) =>
                    category.category_age_to
                      ? +category.category_age_from >= el[0] &&
                        +category.category_age_to <= el[1]
                      : +category.category_age_from >= el[0] &&
                        +category.category_age_from <= el[1]
                  )
                : category;

            const filterByTypeList =
              typeArray.length > 0
                ? typeArray.some((el) => +category.category_type === +el)
                : category;

            if (filterByGenderList && filterByAgeList && filterByTypeList) {
              categories = [...categories, category];
            }
          }

          filteredCategoriesPerTatami = [
            ...filteredCategoriesPerTatami,
            { ...blocks[y].tatamis[i], filteredCategories: [...categories] }
          ];
        }

        allBlocks = [
          ...allBlocks,
          { ...blocks[y], tatamis: filteredCategoriesPerTatami }
        ];
      }

      allBlocks = this.onTxtSearch(this.state.searchBar, allBlocks);

      this.setState({ blocks: allBlocks });
    });
  };

  onCategoryTatamiSort = () => {
    const { blocks, activeBlockIdx, activeTatamiIdx, selCheckbox } = this.state;
    let cpyBlocks = [...blocks];
    const genderOrder = { M: 0, F: 1 };

    const sortCategories_FMFM = (a, b, genderOrder) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_gender !== b.category_gender) {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      } else if (a.origin_category_order !== b.origin_category_order) {
        return sortByOriginCategoryOrder(a, b);
      }
    };

    const sortByOriginCategoryOrder = (a, b) => {
      if (a.origin_category_order > b.origin_category_order) {
        return 1;
      } else if (a.origin_category_order < b.origin_category_order) {
        return -1;
      }
    };

    const sortCategories_MFMF_FMFM = (a, b, genderOrder, type) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_weight > b.category_weight) {
        return 1;
      } else if (a.category_weight < b.category_weight) {
        return -1;
      } else if (a.category_gender !== b.category_gender && type === 'MFMF') {
        return genderOrder[a.category_gender] - genderOrder[b.category_gender];
      } else if (a.category_gender !== b.category_gender && type === 'FMFM') {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      }
    };

    const customSort = (a, b, selCheckbox, genderOrder) => {
      switch (selCheckbox) {
        case 'MFMF':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'MFMF');
        case 'MMFF':
          return sortByOriginCategoryOrder(a, b);
        case 'FFMM':
          return sortCategories_FMFM(a, b, genderOrder);
        case 'FMFM':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'FMFM');
        default:
          return 0; // Default value if selCheckbox doesn't match any case
      }
    };

    const newOrder1 = cpyBlocks[activeBlockIdx].tatamis[
      activeTatamiIdx
    ].categories.sort((a, b) => customSort(a, b, selCheckbox, genderOrder));
    const newOrder2 = cpyBlocks[activeBlockIdx].tatamis[
      activeTatamiIdx
    ].filteredCategories.sort((a, b) =>
      customSort(a, b, selCheckbox, genderOrder)
    );

    cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories = newOrder1;
    cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories =
      newOrder2;

    this.setState({
      blocks: cpyBlocks
    });

    this.handleCloseModal();
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox());
  };

  onTxtSearch = (value, data) => {
    const blockLen = data.length;
    let allBlocks = [];

    for (let y = 0; y < blockLen; y++) {
      const len = data[y]?.tatamis?.length;
      let filteredCategoriesPerTatami = [];

      for (let i = 0; i < len; i++) {
        let categories = [];

        const categoriesPerTatamiLen =
          data[y].tatamis[i].filteredCategories.length;

        for (let j = 0; j < categoriesPerTatamiLen; j++) {
          const category = data[y].tatamis[i].filteredCategories[j];

          if (
            category.category_name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          ) {
            categories = [...categories, category];
          }
        }

        filteredCategoriesPerTatami = [
          ...filteredCategoriesPerTatami,
          { ...data[y].tatamis[i], filteredCategories: [...categories] }
        ];
      }

      allBlocks = [
        ...allBlocks,
        { ...data[y], tatamis: filteredCategoriesPerTatami }
      ];
    }

    return allBlocks;
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox());
  };

  onClearFilters = () => {
    this.setState({ selectedCheckboxes: [] }, () => this.onSelectCheckbox());
  };

  handleOpenModal = (id) => {
    this.setState({ openModal: true, openModalId: id });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, openModalId: null });
  };

  modalOnClickToggle = (openModalId, id) => {
    switch (openModalId) {
      case 'resetCategories':
        return this.resetCategories;
      case 'generateCategories':
        return (evt) => this.generateCategoriesAndCloseModal(evt, id);
      default:
        return null;
    }
  };

  selectCheckbox = (evt) => {
    const { value } = evt.target;

    this.setState({ selCheckbox: value }, () => this.onCategoryTatamiSort());
  };

  onToggleFilter = () => {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  render() {
    const {
      loading,
      success,
      showModal,
      modalInfo,
      selectedCheckboxes,
      tournamentData,
      categoryTypes,
      blocks,
      searchBar,
      activeBlockIdx,
      activeTatamiIdx,
      tatamisTheme,
      openModal,
      openModalId,
      isFilterOpen,
      selCheckbox
    } = this.state;
    const { t, classes, shouldDisableEditing, viewportWidth } = this.props;

    const INTERACTIVE_HEADER = [
      {
        label: (
          <>
            <span style={{ flexGrow: 1 }}>{t('filter')}</span>
            {isFilterOpen ? (
              <ArrowDropUpIcon style={{ color: '#fff' }} />
            ) : (
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            )}
          </>
        ),
        onClick: () => this.onToggleFilter(),
        className: [isFilterOpen && classes.activeState, classes.filter]
      },
      // So far they have asked to remove this button
      // {
      //   label: t('resetAll'),
      //   onClick: () => this.handleOpenModal('resetCategories'),
      //   id: 'resetCategories'
      // },
      {
        label: t('saveList'),
        onClick: () => this.exportTournamentTatamiReport(tournamentData.id),
        className: classes.saveList
      },
      {
        label: t('generate'),
        onClick: () => this.handleOpenModal('generateCategories'),
        id: 'generateCategories',
        className: classes.generate
      },
      { search: true, isInput: true }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : blocks?.length > 0 ? (
          <>
            {!shouldDisableEditing && (
              <div
                className={clsx(
                  classes.interactiveHeaderWrapper,
                  classes.marginTopBottom1
                )}>
                {INTERACTIVE_HEADER.map((el, index) => (
                  <Fragment key={index}>
                    {!el.isInput ? (
                      <Button
                        onClick={el.onClick}
                        label={el.label}
                        isSaveBtn
                        className={el.className}
                      />
                    ) : (
                      <SearchInput
                        className={clsx(classes.margin0, classes.search)}
                        onChange={this.onSearch}
                        clearSearch={this.onClearSearch}
                        value={searchBar}
                        isSearchInactive={!searchBar}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            <FilterCheckboxes
              {...{
                categoryTypes,
                selectedCheckboxes,
                isFilterOpen
              }}
              onSelectCheckbox={this.onSelectCheckbox}
              cancelFilter={this.onClearFilters}
            />
            {isFilterOpen && (
              <Radio
                value={selCheckbox ?? ''}
                tooltipTitleParam="tooltip"
                options={REASSIGN_CATEGORIES_SORT_OPTIONS}
                onClick={this.selectCheckbox}
                item="id"
                label="label"
                className={classes.radioLabel}
                radioClass={classes.radio}
              />
            )}
            <Modal
              open={openModal}
              onClick={this.modalOnClickToggle(openModalId, tournamentData.id)}
              close={this.handleCloseModal}
              buttonPurpose={t('yes')}
              dialogContent={t('provideThisAction')}
              closeButtonlabel={t('no')}
            />
            <DragDropContext onDragEnd={this.onDragEnd}>
              <DroppableContent
                {...{ blocks }}
                windowWidth={viewportWidth}
                {...{ categoryTypes }}
                onChangeTatami={this.onChangeTatami}
                onChangeBlock={this.onChangeBlock}
                {...{ activeBlockIdx, activeTatamiIdx }}
                {...{ tatamisTheme, shouldDisableEditing }}
              />
            </DragDropContext>
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(DragDrop));
