import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr'
    },
    gap: '1rem',
    overflow: 'auto'
  },

  canvasContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },

  canvas: {
    display: 'block',
    width: '100%',
    height: '100%'
  },

  fieldsWrapper: {
    height: 'max-content',
    display: 'grid',
    gap: '1rem'
  },

  field: {
    display: 'flex',
    padding: '0.5rem',
    background: '#E9ECF6',
    border: '1px solid #A2AAAB',
    borderRadius: '0.3rem',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  fieldName: {
    '@media (max-width:767px)': {
      gridArea: 'name'
    }
  },

  fields: {
    display: 'grid',
    gap: '1rem',
    '@media (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr 5em repeat(2, 4em)'
    },
    '@media (max-width:767px)': {
      gridTemplateAreas: "'name  name ''. .' '. .'",
      marginBottom: '1rem'
    }
  },

  saveBtn: {
    marginLeft: 'auto',
    marginTop: '1rem',
    '@media (min-width:1024px)': {
      width: '18.215em'
    },
    '@media (max-width:1023px) and @media (min-width:768px)': {
      width: '11.072em'
    }
  },

  outlined: {
    background: '#E9ECF6',
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '0',
        height: '11px'
      }
    }
  },

  textField: {
    '& .MuiOutlinedInput-input': {
      padding: 0
    }
  },

  textFieldCanvas: {
    '& .MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center'
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      width: '100%'
    }
  },

  draggable: {
    position: 'absolute',
    cursor: 'grab',
    zIndex: 1
  },

  resizeHandle: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '10px',
    height: '10px',
    background: '#000',
    cursor: 'se-resize'
  }
}));
