import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as Clock } from '../../assets/img/clock.svg';

import { TextField, Tooltip } from '@material-ui/core';

import { serverDefaultPath, DEFAULT_TOURNAMENT } from '../../helpers/constants';
import {
  finishedTournament,
  isMobileView,
  organizerName
} from '../../helpers/selectors';
import Button from '../../components/Buttons/ActionButtons';

import EmptyState from '../EmptyState/EmptyState';

import { useStyles } from './Styles';

const TournamentsList = (props) => {
  const {
    pastTournaments,
    upcomingTournaments,
    loading,
    nextTourn,
    prevTourn,
    currentTournamnets,
    countCards,
    currentDate,
    viewportWidth,
    handleTxtChange,
    getTournByPin,
    pin,
    role,
    changeButton,
    toggleButton
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const currentRole = localStorage.getItem('role');

  const mobileScreen = isMobileView(viewportWidth);

  const moveTo = () =>
    navigate('/event/add', {
      state: { prevUrl: location.pathname }
    });

  const moveToEvent = (item) => {
    navigate(`/event/${item.id}`, {
      state: { prevUrl: location.pathname }
    });
  };

  const go2QuickRegistration = (item) => {
    localStorage.setItem('invitation_token', item.token);
    localStorage.setItem('invitation_tournId', item.id);

    navigate(`/register_to_tournament`, {
      state: { prevUrl: location.pathname }
    });

    // When multiple tabs are open, to refresh all tabs
    localStorage.setItem(
      'navigated_2_register_2_tournament',
      `navigate_${Math.random()}`
    );
  };

  const got2Participants = (item) => {
    navigate(`/event/${item?.id}/participants`, {
      state: { prevUrl: location.pathname }
    });
  };

  const generateLabelWithValue = (tournInfo, idxTournInfo, classes) => (
    <span className={clsx(classes.flex, classes.wrap, tournInfo.className)}>
      <span className={clsx(classes.marginRight05)}>
        {tournInfo.label}
        {idxTournInfo !== 0 && ':'}
      </span>
      {tournInfo.value}
    </span>
  );

  const calculateRemainingTime = (item, currentDate, t, classes) => {
    const startDay = new Date(`${item.start_date}T${item.start_time}:00`);
    const newDate = startDay - currentDate;
    const remainingTime = Math.max(newDate, 0);
    const seconds = Math.floor(remainingTime / 1000);
    const sec = seconds % 60;
    const min = Math.floor(seconds / 60) % 60;
    const hour = Math.floor(seconds / 3600) % 24;
    const day = Math.floor(seconds / (3600 * 24));
    const times = [
      {
        val: day < 10 ? `0${day}` : day,
        label: day === 1 ? t('day') : t('days'),
        className: classes.day
      },
      {
        val: hour < 10 ? `0${hour}` : hour,
        label: hour === 1 ? t('hour') : t('hours'),
        className: classes.hour
      },
      {
        val: min < 10 ? `0${min}` : min,
        label: min === 1 ? t('minute') : t('minutes'),
        className: classes.min
      },
      {
        val: sec < 10 ? `0${sec}` : sec,
        label: sec === 1 ? t('second') : t('seconds'),
        className: classes.sec
      }
    ];

    return times.map((digit, idxDigit) => (
      <span
        key={idxDigit}
        className={clsx(
          classes.flex,
          classes.column,
          classes.timerDigits,
          digit.className
        )}>
        <span className={classes.numbers}>
          {digit.val.toString().padStart(2, '0')}
        </span>
        <span className={classes.letters}>{digit.label}</span>
      </span>
    ));
  };

  const data = [
    {
      value: [
        ...(role === 'coach' ? [{ isInfoCard: true }] : []),
        ...upcomingTournaments
      ],
      label: t('planUpcomingEvents'),
      children: (item) => {
        if (
          new Date() >= new Date(`${item.start_date}T${item.start_time}:00`)
        ) {
          return (
            <span
              className={clsx(
                classes.tournInfoWrapper,
                classes.fullWidth,
                !mobileScreen && classes.marginLeft10
              )}>
              {finishedTournContent(item).map((tournInfo, idxTournInfo) => (
                <span key={idxTournInfo}>
                  {generateLabelWithValue(tournInfo, idxTournInfo, classes)}
                </span>
              ))}
            </span>
          );
        } else {
          return (
            <span
              className={clsx(
                classes.flex,
                classes.column,
                classes.txtAlignCenter,
                classes.verticallyCenter,
                classes.clockWrapper,
                classes.fullWidth,
                classes.auto
              )}>
              <span className={clsx(classes.flex, classes.wrapper)}>
                <Clock className={classes.clockSvg} />
                {calculateRemainingTime(item, new Date(), t, classes)}
              </span>
            </span>
          );
        }
      }
    },
    ...pastTournaments.map((finishedTourn) => ({
      value: finishedTourn.data,
      label: [t('pastEvents'), finishedTourn.year].join(' '),
      children: (item) => (
        <span className={!mobileScreen && classes.marginLeft10}>
          {finishedTournContent(item).map((tournInfo, idxTournInfo) => (
            <span key={idxTournInfo}>
              {generateLabelWithValue(tournInfo, idxTournInfo, classes)}
            </span>
          ))}
        </span>
      )
    }))
  ];

  const arrows = [
    { className: classes.prevArrow, onClick: prevTourn },
    { className: classes.nextArrow, onClick: nextTourn }
  ];

  const tournContent = (item) => {
    const organizer = {
      isNotOwner: role === 'master' && !+item.is_master,
      label: t('organizer'),
      value: [
        organizerName(item, true)?.firstN,
        organizerName(item, true)?.lastN
      ].join(' ')
    };

    const type = {
      label: t('eventType'),
      value:
        +item.type === 3
          ? t('trainingSchool')
          : [+item.type === 1 ? t('local') : t('international')].join(' ')
    };
    const address = (className) => ({
      className,
      icon: <LocationOnIcon className={classes.icon} />,
      label: t('address'),
      value: item.address
    });
    return [
      [
        {
          label: t('startDay'),
          value: item?.start_date?.split('-').reverse().join('.')
        },
        finishedTournament(item)
          ? type
          : {
              label: t('registrationDate'),
              value: item?.registration_date?.split('-').reverse().join('.')
            }
      ],
      [organizer, address()]
    ];
  };
  const finishedTournContent = (item) => [
    {
      label: t('statistics'),
      className: [classes.marginBottomForStatistics, classes.bold]
    },
    {
      label: t('countries'),
      value: item.countries_count,
      className: classes.marginBottomForStatistics
    },
    {
      label: t('coaches'),
      value: item.coaches_count,
      className: classes.marginBottomForStatistics
    },
    {
      label: t('categories'),
      value: item.categories_count,
      className: classes.marginBottomForStatistics
    },
    {
      label: t('athletes'),
      value: item.participants_count,
      className: classes.marginBottomForStatistics
    },
    {
      label: t('participants'),
      value: item.participation_count,
      className: classes.marginBottomForStatistics
    },
    ...(item.judges_count !== null
      ? [
          {
            label: t('judges'),
            value: item.judges_count
          }
        ]
      : [])
  ];

  return (
    <>
      {data.map(({ value, label, children }, idx) => {
        const tournLen = value.length;
        const shouldDisableArrow = (eventIdx, arrowIdx) =>
          (arrowIdx === 0 && currentTournamnets[eventIdx] === 0) ||
          (arrowIdx === 1 &&
            (currentTournamnets[eventIdx] === tournLen - 1 ||
              currentTournamnets[eventIdx] + countCards >= tournLen));

        return (
          <span
            key={idx}
            className={clsx(classes.flex, classes.fullWidth, classes.column)}>
            <span
              className={clsx(
                classes.flex,
                classes.bold,
                classes.padding10,
                classes.fullWidth,
                classes.tournamentTypeTitle,
                classes.spaceBetween
              )}>
              {label}
              <span>
                {!mobileScreen &&
                  arrows.map((it, arrowIdx) => (
                    <ArrowBackIcon
                      key={arrowIdx}
                      className={clsx(
                        classes.arrow,
                        arrowIdx === 1 && classes.marginLeft1_5,
                        it.className,
                        shouldDisableArrow(idx, arrowIdx)
                          ? classes.disableIcon
                          : classes.activeArrow
                      )}
                      onClick={
                        shouldDisableArrow(idx, arrowIdx)
                          ? undefined
                          : () => it.onClick(idx)
                      }
                    />
                  ))}
              </span>
            </span>
            <span
              id="slider"
              className={clsx(
                classes.flex,
                classes.wrapper,
                classes.verticallyCenter
              )}>
              <span
                className={clsx(
                  classes.flex,
                  classes.wrapper,
                  classes.verticallyCenter
                )}></span>
              <span className={clsx(classes.flex)}>
                <span
                  className={classes.flex}
                  style={
                    !mobileScreen
                      ? {
                          transform: `translateX(-${
                            currentTournamnets[idx] *
                            (100 / (idx === 0 ? tournLen + 1 : tournLen))
                          }%)`
                        }
                      : { flexDirection: 'column' }
                  }>
                  <span
                    className={clsx(classes.flex, classes.cardsWrapperOnly)}>
                    {idx === 0 && currentRole !== 'coach' && (
                      <span className={classes.paper}>
                        <EmptyState
                          setNewComponent={
                            <Button
                              className={classes.marginTop30}
                              label={t('createEvent')}
                              isSaveBtn={true}
                              onClick={
                                currentRole !== 'coach' &&
                                currentRole !== 'recorder' &&
                                moveTo
                              }
                            />
                          }
                          emptyStateText={
                            tournLen !== 0
                              ? t('createRecordsMsg')
                              : t('noEvents')
                          }
                          emptyStateSubText={
                            tournLen === 0 && t('butCreateRecordsMsg')
                          }
                          specialTxt={
                            currentRole === 'recorder' && t('recorderNoEvents')
                          }
                          purpose={t('letsDoIt')}
                          buttonText={t('createEvent')}
                          onClick={
                            currentRole !== 'coach' &&
                            currentRole !== 'recorder' &&
                            moveTo
                          }
                        />
                      </span>
                    )}
                  </span>
                  {Array.isArray(value) &&
                    value.map((tournament, idxTourn) => (
                      <span
                        key={idxTourn}
                        className={clsx(
                          classes.paper,
                          role === 'master' &&
                            !+tournament?.is_master &&
                            classes.noOwnerBorder,
                          classes.column
                        )}>
                        {!tournament?.isInfoCard ? (
                          <>
                            <span
                              className={clsx(
                                classes.bold,
                                classes.marginBottom1,
                                classes.hiddenText,
                                classes.whiteSpace,
                                !mobileScreen && classes.height36
                              )}>
                              {tournament.tournament_name}
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.marginBottom1
                              )}>
                              <div
                                className={mobileScreen && classes.fullWidth}>
                                <img
                                  alt={t('poster')}
                                  onClick={() =>
                                    role === 'coach'
                                      ? go2QuickRegistration(tournament)
                                      : role === 'recorder'
                                      ? got2Participants(tournament)
                                      : moveToEvent(tournament)
                                  }
                                  className={clsx(
                                    classes.img,
                                    classes.marginRight1
                                  )}
                                  src={
                                    tournament.poster
                                      ? serverDefaultPath + tournament.poster
                                      : DEFAULT_TOURNAMENT
                                  }
                                />
                              </div>
                              {children(tournament)}
                            </span>
                            {tournContent(tournament).map(
                              (rowDetails, idxRowDetails) =>
                                rowDetails && (
                                  <span
                                    className={clsx(
                                      idxRowDetails === 0 &&
                                        classes.marginBottom1,
                                      classes.flex
                                    )}
                                    key={idxRowDetails}>
                                    {rowDetails.map(
                                      (tournInfo, idxTournInfo) => (
                                        <span
                                          className={clsx(
                                            classes.flex,
                                            classes.fullWidth,
                                            tournInfo.className
                                          )}
                                          key={idxTournInfo}>
                                          <span
                                            className={clsx(
                                              classes.flex,
                                              classes.fullWidth
                                            )}>
                                            <span
                                              className={clsx(
                                                classes.flex,
                                                classes.column,
                                                classes.icTxt
                                              )}>
                                              <span
                                                className={clsx(
                                                  classes.flex,
                                                  classes.bold,
                                                  !mobileScreen &&
                                                    classes.nowrap
                                                )}>
                                                {tournInfo.label}
                                                {':'}
                                              </span>
                                              <span
                                                className={clsx(
                                                  classes.flex,
                                                  classes.hiddenText,
                                                  classes.whiteSpace
                                                )}>
                                                {tournInfo.value}
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      )
                                    )}
                                  </span>
                                )
                            )}
                          </>
                        ) : (
                          idx === 0 &&
                          role === 'coach' && (
                            <>
                              <EmptyState
                                emptyStateText={t(
                                  'coachRecorderUseQuickRegLinkInfo'
                                )}
                                setNewComponent={
                                  <>
                                    <span
                                      className={
                                        classes.coachRecorderUseQuickRegLinkInfo
                                      }></span>
                                    <span
                                      className={clsx(
                                        classes.flex,
                                        classes.marginTop30,
                                        classes.center
                                      )}>
                                      {changeButton ? (
                                        <Button
                                          className={classes.btn}
                                          label={t('pin')}
                                          isSaveBtn={true}
                                          onClick={toggleButton}
                                        />
                                      ) : (
                                        <>
                                          <TextField
                                            className={classes.txtField}
                                            label={t('pin')}
                                            name="pin"
                                            value={pin || ''}
                                            onChange={handleTxtChange}
                                            variant="outlined"
                                          />
                                          <Button
                                            onClick={() =>
                                              pin
                                                ? getTournByPin((val) =>
                                                    go2QuickRegistration(val)
                                                  )
                                                : undefined
                                            }
                                            isSaveBtn={true}
                                            label={t('OK')}
                                            className={clsx(classes.btnOk)}
                                          />
                                        </>
                                      )}
                                      {/* <Tooltip
                                            arrow
                                            title={t('link2QuickRegistration')}> */}

                                      {/* </Tooltip> */}
                                    </span>
                                  </>
                                }
                              />
                            </>
                          )
                        )}
                      </span>
                    ))}
                </span>
              </span>
            </span>
          </span>
        );
      })}
    </>
  );
};

export default TournamentsList;
