import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    border: '1px solid #8996AC',
    borderRadius: '4px',
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(44,52,57,0.5)',
      opacity: 0.6,
      borderRadius: '5px',
      '& $imgShadow': {
        opacity: 1
      }
    }
  },
  image: {
    cursor: 'pointer',
    transition: '.5s ease',
    borderRadius: '4px'
  },
  cover: {
    objectFit: 'cover'
  },
  coverHeader: {
    '@media (max-width:1300px)': {
      objectFit: 'cover'
    }
  },

  fullSize: {
    height: '100%',
    width: '100%'
  },

  camera: {
    width: 'auto',
    marginTop: '5px',
    fontSize: '32px'
  },
  imgShadow: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    cursor: 'pointer'
  },
  uploadedImgExist: {
    color: '#fff'
  },
  input: {
    display: 'none'
  },
  text: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
});
