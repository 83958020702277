import { Component, Fragment } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { ReactComponent as Transfer } from 'assets/img/transfer.svg';
import { ReactComponent as Copy } from 'assets/img/copy.svg';
import { ReactComponent as DropdownUp } from 'assets/img/new_dropdown_grey.svg';
import { ReactComponent as DropdownGreyBg } from 'assets/img/dropdown_grey_bg.svg';
import { ReactComponent as TimerIcon } from '../../assets/img/timer.svg';
import { ReactComponent as CategoryModeIc } from '../../assets/img/categoryMode.svg';
import { ReactComponent as ParticipantModeIc } from '../../assets/img/participantMode.svg';
import { ReactComponent as InfoIc } from '../../assets/img/info_btn_grey_bg&border.svg';
import { ReactComponent as RestoreIc } from '../../assets/img/restore-white.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as EditGreyBgBorder } from 'assets/img/edit_grey_bg&border.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as AddIcon } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as AddGreyBgBorder } from 'assets/img/plus_grey_bg&border.svg';
import { ReactComponent as ClearIcon } from 'assets/img/cancel_btn_white_bg&border.svg';
import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import { Tooltip, TextField } from '@material-ui/core';

import { changeTitle } from 'helpers/actions';
import {
  serverDefaultPath,
  DELETE_ICON,
  ADD_ICON,
  CLEAR_ICON,
  ID,
  CATEGORY_ID,
  NAME,
  NOT_SET,
  TEST_ID_COPY,
  TEST_ID_INFO_BTN,
  FILTERS_CHECKBOXES,
  KEEP_EDITING,
  CLOSE_DISCARD
} from '../../helpers/constants';
import {
  fetchCoaches,
  fetchTournRegistrar,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchTournaments,
  selectTournament,
  selectOption,
  selectCategory,
  setCategory,
  fetchTournamentList,
  fetchParticipants,
  fetchParticipantsByTournamentV2,
  distributeParticipantByCategory,
  clearSearch,
  handleTextChange,
  deleteParticipant,
  fetchCategories,
  changeModal,
  validateForm,
  fetchQdan,
  selectCheckboxSchool,
  setParticipantToSchool,
  changeHead,
  fetchAllCategoryTypes,
  addNewCategory,
  removeCategory,
  selectMultipleCategories,
  selectMultipleCategoryType,
  selectMultipleTeams,
  validateMultipleCategoriesAssignment,
  updateParticipant,
  fetchParticipantsByTournamentCategories,
  applyCategories,
  selectCheckbox,
  downloadFile,
  exportCategories,
  exportParticipants,
  restoreParticipant,
  fetchUsers,
  fetchReference
} from '../../helpers/util';
import {
  compareValueForSorting,
  selectedValue,
  findSortDirection,
  finishedTournament,
  categoryTypesPresentOnTournament,
  onChangeText,
  DDMMYYYY,
  isSchool
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import AddParticipant from '../AddParticipant/AddParticipant';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import Modal from '../../components/Modal/ModalNewDesign';
import AssignCategories from '../../components/QuickTournRegistration/AssignCategories';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Pagination from '../../components/TablePagination/TablePagination';
import Table from '../../components/Table/CardsList';
import EmptyState from '../../components/EmptyState/EmptyState';
import Assign2School from '../../components/School/Assign2School';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';
import NewUsersToolbar from 'components/Table/Toolbar/NewToolbar';
import Filter from 'components/Filter/Filter';
import ListItems from 'components/Table/ListItems';
import Button from 'components/Buttons/ActionButtons';

import styles from './Styles';

const schoolInfo = {
  training: true,
  nextQdan: false,
  next_qdan_id: ''
};
const initialStateCategory = {
  type: '',
  id: '',
  team_id: '',
  filteredCategories: []
};
const TEST_ID_PARTICIPANT = 'TEST_ID_PARTICIPANT';
const INITIAL_EDITABLE_TABLE_CELL = {
  weight: null,
  values: {},
  errors: {}
};

const participantIndividualData = (item) => ({
  ...item,
  id: item.participant_id,
  first_name: item.participant_first_name,
  last_name: item.participant_last_name,
  patronymic: item.participant_patronymic,
  history: item.history === NOT_SET ? '' : item.history,
  iko: item.iko === NOT_SET ? '' : item.iko,
  club: item.club !== NOT_SET ? item.club : '',
  country_id: item.participant_country_id,
  city: item.participant_city,
  height: item.height !== '-1' ? item.height : '',
  region: item.participant_region
});

const CURRENT_TABS = [1, 2];

class UserList extends Component {
  constructor(props) {
    super(props);
    this.currentTab = +new URLSearchParams(props.location.search)?.get('tab');
    this.state = {
      participants: [],
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      processing: true,
      searchBar: '',
      success: false,
      showModal: false,
      page: 1,
      rowsPerPage:
        (localStorage.getItem('rowsPerPage') &&
          +localStorage.getItem('rowsPerPage')) ||
        10,
      participantsCount: 0,
      filteredRecordsForView2Count: 0,
      searchedValue: '',
      searchByOption: { id: '0' },
      filteredParticipants: [],
      editableTableCell: INITIAL_EDITABLE_TABLE_CELL,
      shouldUpdateTable: false,
      open: false,
      categoryTypes: [],
      tournamentsList: [],
      allRecordsForView2: [],
      filteredRecordsForView2: [],
      filteredCategories: [],
      isFilterChecked: true,
      categories: [],
      selectedCheckboxes: {
        view_1: [],
        view_2: []
      },
      tournamentApplications: [],
      collapsed: {},
      copyPasteData: {},
      statistics: {},
      filters: {
        switchedView: CURRENT_TABS.includes(this.currentTab)
          ? this.currentTab
          : 1
      },
      isSearching: false,
      tournamentData: {},
      sortDirection: [],
      selectedCategories: [initialStateCategory],
      selectedCategoriesErrors: [],
      qdanList: [],
      collapsedForView2: [],
      intermidiateSchoolInfo: schoolInfo,
      isFilterOpen: false
    };

    this.role = localStorage.getItem('role');
    this.tournamentIdParams = props.match.params?.id;

    this.fetchCoaches = fetchCoaches.bind(this);
    this.fetchTournRegistrar = fetchTournRegistrar.bind(this);
    this.pageChangeHandler = pageChangeHandler.bind(this);
    this.rowsPerPageHandler = rowsPerPageHandler.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.selectTournament = selectTournament.bind(this);
    this.selectOption = selectOption.bind(this);
    this.selectCategory = selectCategory.bind(this);
    this.setCategory = setCategory.bind(this);
    this.fetchTournamentList = fetchTournamentList.bind(this);
    this.fetchParticipantsByTournamentV2 =
      fetchParticipantsByTournamentV2.bind(this);
    this.fetchParticipants = fetchParticipants.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.handleTextChange = handleTextChange.bind(this);
    this.deleteParticipant = deleteParticipant.bind(this);
    this.fetchCategories = fetchCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.validateForm = validateForm.bind(this);
    this.fetchQdan = fetchQdan.bind(this);
    this.selectCheckboxSchool = selectCheckboxSchool.bind(this);
    this.setParticipantToSchool = setParticipantToSchool.bind(this);
    this.changeHead = changeHead.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.distributeParticipantByCategory =
      distributeParticipantByCategory.bind(this);
    this.addNewCategory = addNewCategory.bind(this);
    this.selectMultipleCategories = selectMultipleCategories.bind(this);
    this.selectMultipleCategoryType = selectMultipleCategoryType.bind(this);
    this.fetchParticipantsByTournamentCategories =
      fetchParticipantsByTournamentCategories.bind(this);
    this.selectMultipleTeams = selectMultipleTeams.bind(this);
    this.removeCategory = removeCategory.bind(this);
    this.validateMultipleCategoriesAssignment =
      validateMultipleCategoriesAssignment.bind(this);
    this.updateParticipant = updateParticipant.bind(this);
    this.applyCategories = applyCategories.bind(this);
    this.selectCheckbox = selectCheckbox.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportCategories = exportCategories.bind(this);
    this.exportParticipants = exportParticipants.bind(this);
    this.restoreParticipant = restoreParticipant.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const {
      shouldUpdateTable,
      page,
      rowsPerPage,
      searchBar,
      isEditing,
      langOnLoad,
      isFilterChecked,
      selectedCategories,
      collapsedForView2,
      filters,
      open,
      intermidiateSchoolInfo
    } = this.state;
    const { t, match, location } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');
    this.tournamentIdParams = match.params?.id;

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(page, rowsPerPage, searchBar, () => {
        if (filters?.switchedView === 2) {
          const { allRecordsForView2 } = this.state;
          const copyCollapsed = [...collapsedForView2];

          this.onFilterBySelection();

          if (filters?.currentParentID) {
            const currentCollapsedIdx = copyCollapsed.findIndex(
              (it) => +it.id === +filters.currentParentID
            );

            if (currentCollapsedIdx !== -1) {
              //after saving the changes, update the collapsible element
              const findRecord = allRecordsForView2.find(
                (it) => +it.id === +filters.currentParentID
              );

              copyCollapsed[currentCollapsedIdx].participants =
                findRecord?.participants;

              this.setState({ collapsedForView2: copyCollapsed });
            }
          } else {
            this.onCollapseForView2();
          }
        } else {
          const { filteredParticipants, modalData } = this.state;
          const findElemIdx = filteredParticipants?.findIndex(
            (it) =>
              +it?.[this.tournamentIdParams ? 'participant_id' : 'id'] ===
              +modalData?.id
          );

          if (findElemIdx === -1) {
            this.hideModal();
          }
        }
      });
    } else if (location.pathname !== prevProps.location.pathname) {
      this.searchHandler(() => {
        if (open) this.hideModal();

        if (!this.tournamentIdParams) {
          if (this.role !== 'coach') {
            this.fetchCoaches();
          }
        } else {
          this.fetchTournRegistrar(this.tournamentIdParams, 1, (data) => {
            this.setState({ coachesList: data });
          });
        }
      });
      this.onFetchCategoriesTypes((types) =>
        this.setState({ categoryTypes: types })
      );

      const currentTab = +new URLSearchParams(location.search)?.get('tab');

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          switchedView: CURRENT_TABS.includes(currentTab) ? currentTab : 1
        },
        tournamentData: {},
        shouldDisableEditing: false
      }));

      if (this.tournamentIdParams) {
        this.fetchTournaments(this.tournamentIdParams, null, null, () => {
          const { tournamentData } = this.state;
          const shouldDisableEditing =
            finishedTournament(tournamentData) || !!+tournamentData?.finished;

          this.setState({ shouldDisableEditing });

          changeHead(tournamentData, t('participants'));
        });
      } else {
        changeHead(null, t('myTeam'));
      }
    } else if (searchBar !== prevState.searchBar) {
      this.setState({ isSearching: true });
    }

    if (langOnLoad !== currentLang) {
      if (this.tournamentIdParams) {
        changeHead(this.state.tournamentData, t('participants'));
      } else {
        changeHead(null, t('myTeam'));
      }

      this.setState({ langOnLoad: currentLang });
    }

    if (
      isEditing &&
      ((selectedCategories &&
        selectedCategories !== prevState.selectedCategories) ||
        isFilterChecked !== prevState.isFilterChecked ||
        (intermidiateSchoolInfo &&
          intermidiateSchoolInfo !== prevState.intermidiateSchoolInfo))
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }
  }

  componentDidMount() {
    const { t } = this.props;
    const { page, rowsPerPage, langOnLoad } = this.state;
    const { role } = this.context.authState;

    this.fetchQdan();

    changeTitle(t('myTeam'));

    if (this.tournamentIdParams) {
      this.fetchTournaments(this.tournamentIdParams, null, null, () => {
        const { tournamentData } = this.state;
        const shouldDisableEditing =
          finishedTournament(tournamentData) || !!+tournamentData?.finished;

        this.setState({ shouldDisableEditing });

        changeHead(tournamentData, t('participants'));

        const data1 = new Promise((resolve) => {
          this.onFetchCategoriesTypes((types) => {
            this.setState({ categoryTypes: types });
            resolve(types);
          });
        });
        const data2 = new Promise((resolve) => {
          this.fetchTournRegistrar(this.tournamentIdParams, 1, (data) => {
            this.setState({ coachesList: data });
            resolve(data);
          });
        });

        Promise.all([data1, data2]).then(() => {
          this.fetchData(page, rowsPerPage, '', () => {
            if (role !== 'recorder') {
              this.fetchParticipantsByTournamentCategories(
                this.tournamentIdParams,
                {},
                (data) => {
                  this.setState((prevState) => ({
                    filters: {
                      ...prevState.filters,
                      filteredCategories: data,
                      categories: data
                    }
                  }));
                }
              );
            }
          });
        });
      });
    } else {
      new Promise((resolve) => {
        // Retrieve details from coachesList, which is utilized to fill in the form with coach information.
        // Different endpoints are employed depending on the role used to log in.
        if (role !== 'coach') {
          this.fetchCoaches(null, (data) => {
            return resolve(data);
          });
        } else {
          this.fetchUsers(null, null, null, null, (data) => {
            this.setState({ coachesList: data });
            return resolve(data);
          });
        }
      }).then(() => {
        this.fetchData(page, rowsPerPage, '', () => {});
      });
    }

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'participants'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  onFetchCategoriesTypes = (cb) => {
    this.fetchAllCategoryTypes((allSystemTypes) => {
      const types = categoryTypesPresentOnTournament(
        allSystemTypes || [],
        this.state.tournamentData?.category_types
      );

      cb && cb(types, allSystemTypes);
    });
  };

  fetchData = (page, rowsPerPage, search, cb) => {
    const { searchByOption, filters, sortDirection, tournamentData } =
      this.state;
    const { match } = this.props;

    this.tournamentIdParams = match.params?.id;

    if (filters.switchedView === 2) {
      this.fetchParticipantsByTournamentCategories(
        this.tournamentIdParams,
        { search },
        (categories) => {
          const count = categories?.length ?? 0;
          const statistics = {
            totalCount: count,
            athleteCount: tournamentData?.participants_count ?? 0
          };

          this.setState(
            {
              statistics,
              loading: false,
              shouldUpdateTable: false,
              allRecordsForView2: categories,
              filteredRecordsForView2: categories,
              filteredRecordsForView2Count: count
            },
            () => cb && cb()
          );
        },
        searchByOption
      );
    } else {
      const { coachesList } = this.state;
      const findCurrentUser = coachesList?.find(
        (it) => +it.id === +tournamentData?.organizer_id
      );

      const data = (participants) => {
        const statistics = {
          athleteCount: tournamentData?.participants_count,
          participantionCount: tournamentData?.participation_count,
          registeredParticipation: findCurrentUser?.registered_count ?? 0,
          notRegistered:
            findCurrentUser?.registered_count &&
            tournamentData?.participation_count
              ? tournamentData.participation_count -
                findCurrentUser.registered_count
              : 0
        };

        this.setState(
          {
            statistics,
            loading: false,
            isSearching: false,
            shouldUpdateTable: false,
            participants: participants?.data,
            participantsCount:
              participants?.count > 0 ? participants?.count : 0,
            filteredParticipants: participants?.data,
            collapsed: {}
          },
          (result_req, status_req) => {
            if (status_req !== 404) {
              cb && cb();
            }
          }
        );
      };

      if (!this.tournamentIdParams) {
        this.fetchParticipants(
          page,
          rowsPerPage,
          search,
          null,
          data,
          null,
          sortDirection?.[0],
          sortDirection?.[2],
          null,
          null,
          searchByOption,
          filters
        );
      } else {
        this.fetchParticipantsByTournamentV2(
          page,
          rowsPerPage,
          search,
          null,
          this.tournamentIdParams,
          this.state.isInactiveParticipantsChecked,
          data,
          filters.filteredByCategoryId,
          sortDirection?.[0],
          sortDirection?.[2],
          searchByOption,
          filters
        );
      }
    }
  };

  triggerTableUpdate = (shouldLeaveModalOpen, msg, status) => {
    const { open } = this.state;

    this.setState({ shouldUpdateTable: true });

    if (msg && status === 200) {
      this.changeModal(msg, status);
    }

    if (!shouldLeaveModalOpen && open) {
      this.hideModal();
    }
  };

  searchHandler = (cb) => {
    const { filters, searchBar, rowsPerPage } = this.state;

    this.setState({ searchedValue: searchBar, page: 1 }, () => {
      this.fetchData(this.state.page, rowsPerPage, searchBar, () => {
        cb && cb();
        if (filters.switchedView === 2) {
          this.onCollapseForView2();
          this.onFilterBySelection();
        }
      });
    });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  showModalParticipant = (evt, item, idx, parent, parentIdx) => {
    evt && evt.preventDefault();

    const { tournamentData, filters } = this.state;
    const isTraining = isSchool(tournamentData);
    const id = item?.participant_id ?? item?.id;
    let personalAssignedCategories = [];

    if (!isTraining && item && Array.isArray(item.categories_info)) {
      personalAssignedCategories = item.categories_info.map((cat) => ({
        type: cat.category_type_id,
        id: cat.category_id,
        team_id: cat.team_id,
        name: cat.category_name,
        issue: cat.issues
      }));
    }

    this.setState((prevState) => ({
      pressedIcon: TEST_ID_PARTICIPANT,
      open: true,
      ...(filters.switchedView === 2 && parent?.id
        ? { filters: { ...prevState.filters, currentParentID: parent.id } }
        : {}),
      participantId: id,
      ...(!isTraining ? { personalAssignedCategories } : {}),
      selectedRow: id,
      ...(id
        ? {
            modalData: {
              ...item,
              id,
              currentRecordIdx: idx,
              currentRecordParentIdxForV2: parentIdx,
              ...(this.tournamentIdParams
                ? {
                    selectedTournamentInfo: tournamentData,
                    patronymic_required: true
                  }
                : {}),
              birthdayInputDate: item.birthday,
              ...(item.photo
                ? { imagePreview: serverDefaultPath + item.photo }
                : {}),
              ...(item.participant_first_name
                ? { first_name: item.participant_first_name }
                : {}),
              ...(item.participant_last_name
                ? { last_name: item.participant_last_name }
                : {}),
              ...(isTraining
                ? {
                    next_qdan_id: item.next_qdan_id,
                    training: Boolean(item.training),
                    nextQdan: Boolean(item.next_qdan_id)
                  }
                : {}),
              ...(this.tournamentIdParams
                ? { patronymic: item?.participant_patronymic ?? '' }
                : {}),
              history: item.history === NOT_SET ? '' : item.history,
              iko: item.iko === NOT_SET ? '' : item.iko,
              club: item.club !== NOT_SET ? item.club : '',
              country_id: item.participant_country_id,
              city: item.participant_city,
              height: item.height !== '-1' ? item.height : '',
              region: item.participant_region
            }
          }
        : {
            modalData: {
              ...(isTraining ? schoolInfo : {}),
              ...(this.tournamentIdParams
                ? {
                    selectedTournamentInfo: tournamentData,
                    patronymic_required: true
                  }
                : {})
            }
          })
    }));
  };

  showModal = (event, currentTarget, rowData, tournamentId, catId) => {
    const { tournamentData } = this.state;

    const isTraining = isSchool(tournamentData);
    const partID = rowData?.id ?? rowData?.participant_id;
    const iconID = currentTarget ?? event.target.id;

    this.setState(
      (prevState) => ({
        pressedIcon: iconID,
        ...(rowData.currentParentID
          ? {
              filters: {
                ...prevState.filters,
                currentParentID: rowData.currentParentID
              }
            }
          : {}),
        modalData: {
          ...rowData,
          id: partID,
          ...(rowData.participant_first_name
            ? { first_name: rowData.participant_first_name }
            : {}),
          ...(rowData.participant_last_name
            ? { last_name: rowData.participant_last_name }
            : {}),
          ...(this.tournamentIdParams
            ? { patronymic: rowData.participant_patronymic }
            : {}),
          history: rowData.history === NOT_SET ? '' : rowData.history,
          iko: rowData.iko === NOT_SET ? '' : rowData.iko,
          club: rowData.club !== NOT_SET ? rowData.club : '',
          country_id: rowData.participant_country_id,
          city: rowData.participant_city,
          height: rowData.height !== '-1' ? rowData.height : '',
          region: rowData.participant_region,
          ...(iconID === CLEAR_ICON
            ? {
                current_category_id: catId,
                current_tournament_id: this.tournamentIdParams
              }
            : {})
        }
      }),
      () => {
        const isAddIconSelected = this.state.pressedIcon === ADD_ICON;
        const partID = rowData.id || rowData.participant_id;

        if (isAddIconSelected && !this.tournamentIdParams) {
          this.fetchTournaments(
            null,
            this.role !== 'administrator' && 1,
            '',
            null,
            true
          );
        }

        if (!isTraining && isAddIconSelected) {
          const { tournamentData } = this.state;
          const { category_types } = tournamentData;
          const onFilterCategoriesList = (allCategoriesTypes) => {
            this.onCategoriesFiltering(tournamentId, () => {
              const { filteredCategories } = this.state;

              const personalAssignedCategories = rowData?.categories_info?.map(
                (cat) => ({
                  type: cat.category_type_id,
                  id: cat.category_id,
                  team_id: cat.team_id,
                  name: cat.category_name,
                  issue: cat.issues
                })
              );

              const selectedCategories = personalAssignedCategories?.map(
                (it) => {
                  return {
                    ...it,
                    filteredCategories:
                      Array.isArray(filteredCategories) &&
                      (!it.type
                        ? filteredCategories
                        : filteredCategories.filter(
                            (cat) => `${cat.category_type_id}` === `${it.type}`
                          ))
                  };
                }
              );

              this.setState({
                ...(allCategoriesTypes ? allCategoriesTypes : {}),
                open: true,
                categoriesFitsParticipant: filteredCategories,
                personalAssignedCategories,
                selectedCategories:
                  selectedCategories?.length > 0
                    ? selectedCategories
                    : [{ ...initialStateCategory, filteredCategories }]
              });
            });
          };

          if (this.tournamentIdParams) {
            onFilterCategoriesList();
          } else {
            this.onFetchCategoriesTypes((types, allSystemTypes) =>
              onFilterCategoriesList({ types, allSystemTypes })
            );
          }
        }

        this.setState({
          participantId: partID,

          ...(isTraining
            ? {
                intermidiateSchoolInfo:
                  this.state.pressedIcon !== DELETE_ICON
                    ? {
                        training: Boolean(rowData.training),
                        next_qdan_id: rowData.next_qdan_id,
                        nextQdan: Boolean(rowData.next_qdan_id)
                      }
                    : { ...schoolInfo }
              }
            : {}),
          ...(!isAddIconSelected && !isTraining ? { open: true } : {})
        });
      }
    );
  };

  hideModal = () => {
    const { categories } = this.state;

    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        isFilterChecked: true,
        tournamentWeight: null,
        filteredCategories: categories,
        pressedIcon: null,
        shouldShowDiscardChanges: false,
        isEditing: false,
        selectedRow: null,
        selectedCategoriesErrors: [],
        ...(!this.tournamentIdParams ? { tournamentData: {} } : {})
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      // clear the fields to be able to close the modal window
      this.setState({ isAttemptingToEditModalFields: false }, () => {
        this.hideModal();
      });
    }
  };

  itemToggle = (key) => {
    this.setState((prevState) => ({
      collapsed: { ...prevState.collapsed, [key]: !this.state.collapsed[key] }
    }));
  };

  onCategoriesFiltering = (tournId, cb) => {
    const { modalData } = this.state;

    this.fetchCategories(
      (list) => this.setState({ filteredCategories: list }, () => cb && cb()),
      tournId,
      null,
      null,
      null,
      this.state.isFilterChecked,
      { ...modalData }
    );
  };

  onSort = (sortField, idxCell) => {
    const { rowsPerPage, searchedValue, sortDirection } = this.state;
    const direction = findSortDirection(sortDirection, sortField);

    this.setState({ sortDirection: [direction, idxCell, sortField] }, () => {
      this.fetchData(1, rowsPerPage, searchedValue);
    });
  };

  onPageChange = (e, next) => {
    const { isSearching, rowsPerPage, searchBar } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData(this.state.page, rowsPerPage, searchBar);
      }
    });
  };

  switchTournament = (evt, value) => {
    const isTraining = isSchool(value);

    if (value !== null) {
      this.setState({ tournamentData: value }, () => {
        const { tournamentData, allSystemTypes, modalData } = this.state;
        const categoryTypes = categoryTypesPresentOnTournament(
          allSystemTypes || [],
          tournamentData?.category_types
        );

        this.onCategoriesFiltering(tournamentData?.id, () => {
          const { filteredCategories } = this.state;
          let newSelectedCategories = [initialStateCategory];

          this.fetchTournamentList(
            modalData?.id,
            tournamentData?.id,
            (data) => {
              const tournamentCategories = data?.tournaments?.[0]?.categories;
              const assignedCategories = tournamentCategories?.map((cat) => ({
                type: cat.category_type_id,
                id: cat.category_id,
                team_id: cat.team_id,
                name: cat.category_name,
                issue: cat.issues
              }));

              if (assignedCategories?.length > 0) {
                newSelectedCategories = assignedCategories?.map((it) => {
                  return {
                    ...it,
                    filteredCategories:
                      Array.isArray(filteredCategories) &&
                      (!it.type
                        ? filteredCategories
                        : filteredCategories.filter(
                            (cat) => +cat?.category_type_id === +it?.type
                          ))
                  };
                });
              }

              this.setState({ selectedCategories: newSelectedCategories });
            }
          );
        });

        this.setState({ categoryTypes, isEditing: true });

        if (isTraining) {
          this.fetchQdan();
        }
      });
    }
  };

  saveParticipant = (evt) => {
    const { modalData, tournamentData } = this.state;

    this.applyCategories(modalData, (personalAssignedCategories) => {
      const assignCategories = personalAssignedCategories?.map((key) => {
        return {
          id: key.id,
          ...(key.team_id ? { team_id: key.team_id } : {})
        };
      });

      this.updateParticipant(
        evt,
        modalData,
        () => {
          this.triggerTableUpdate(true);
        },
        tournamentData,
        assignCategories
      );
    });
  };

  recorderToggleRegisteredStatus = (evt, item, parent) => {
    const { role } = this.context.authState;
    const { tournamentData } = this.state;
    const { checked } = evt.target;
    const form = {
      id: item.participant_id,
      registered: checked ? 1 : 0
    };

    this.updateParticipant(
      evt,
      form,
      () => {
        this.triggerTableUpdate();
      },
      tournamentData,
      null,
      null,
      role,
      true
    );
  };

  setParticipantToTraining = (evt) => {
    const { intermidiateSchoolInfo, tournamentData, participantId } =
      this.state;

    this.setParticipantToSchool(
      evt,
      intermidiateSchoolInfo,
      tournamentData,
      participantId,
      () => {
        //when the update form is opened and we use the icon on the right to assign the same participant, update the form with last changes
        this.triggerTableUpdate();
      }
    );
  };

  selectRetiredParticipants = (check) => {
    const { filters } = this.state;
    const cpy = { ...filters };
    cpy.isRetiredParticipantsChecked = check;

    this.setState({ page: 1, filters: cpy }, () => {
      const { page, rowsPerPage, searchBar } = this.state;

      this.fetchData(page, rowsPerPage, searchBar);
    });
  };

  selectArchive = (evt) => {
    const { checked } = evt.target;

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, archive: checked }
      }),
      () => {
        const { page, rowsPerPage, searchBar } = this.state;

        this.fetchData(page, rowsPerPage, searchBar);
      }
    );
  };

  selectIssues = (check) => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, issues: check }
      }),
      () => {
        const { page, rowsPerPage, searchBar } = this.state;

        this.fetchData(page, rowsPerPage, searchBar);
      }
    );
  };

  selectIssuesForView2 = (check) => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, category_issue: check }
      }),
      () => {
        this.onFilterBySelection();
      }
    );
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsedForView2, allRecordsForView2 } = this.state;
    const allCollapsedTables = [...collapsedForView2];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it.id === +item.id
      );

      if (findIndex === -1) {
        let record = allRecordsForView2.find((it) => +it.id === +item.id);

        this.setState((prevState) => ({
          collapsedForView2: [
            ...prevState.collapsedForView2,
            {
              id: item.id,
              participants: record?.participants,
              sortDirection: []
            }
          ]
        }));
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsedForView2: allCollapsedTables });
      }
    }
  };

  onSwitchView = (key) => {
    const { page, rowsPerPage, searchBar } = this.state;
    const { navigate, location } = this.props;
    const searchParams = new URLSearchParams(location?.search);

    searchParams.set('tab', key);
    navigate({ search: searchParams.toString() });

    this.setState(
      (prevState) => ({
        loading: true,
        page: 1,
        filters: {
          ...prevState.filters,
          collapseAll: key === 2,
          switchedView: key
        }
      }),
      () => {
        const { filters } = this.state;

        if (filters.switchedView === 2) {
          this.fetchData(page, rowsPerPage, searchBar, () => {
            this.onCollapseForView2();
            this.onFilterBySelection();
          });
        } else {
          this.onFilterBySelection(() => {
            this.fetchData(page, rowsPerPage, searchBar);
          }, true);
        }
      }
    );
  };

  onCollapseForView2 = () => {
    const { filters, allRecordsForView2 } = this.state;

    if (filters.switchedView === 2) {
      const len = allRecordsForView2?.length;
      let newCollapsed = [];

      for (let i = 0; i < len; i++) {
        const record = allRecordsForView2[i];
        newCollapsed = [
          ...newCollapsed,
          {
            id: record.id,
            participants: record?.participants,
            sortDirection: []
          }
        ];
      }

      this.setState({ collapsedForView2: newCollapsed });
    }
  };

  onSwitchToggleForView2 = (evt, checked) => {
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, collapseAll: checked }
      }),
      () => {
        if (checked) {
          this.onCollapseForView2();
        } else {
          this.setState({ collapsedForView2: [] });
        }
      }
    );
  };

  onSortCollapsibleTable = (sortField, idxCell, parent) => {
    const { allRecordsForView2, collapsedForView2 } = this.state;
    const cloneRecords = [...allRecordsForView2];
    const cloneCollapsed = [...collapsedForView2];
    const findIdxInArray = cloneCollapsed.findIndex(
      (it) => +it.id === +parent?.id
    );

    const direction = findSortDirection(
      cloneCollapsed[findIdxInArray].sortDirection,
      sortField
    );
    const sortedData = compareValueForSorting(
      cloneCollapsed[findIdxInArray].participants,
      sortField,
      direction
    );

    const findRecordIdx = cloneRecords.findIndex(
      (it) => +it.id === +parent?.id
    );

    cloneRecords[findRecordIdx].participants = sortedData;
    cloneCollapsed[findIdxInArray].sortDirection = [
      direction,
      idxCell,
      sortField
    ];
    cloneCollapsed[findIdxInArray].filteredParticipants = sortedData;

    this.setState({
      collapsed: cloneCollapsed,
      allRecordsForView2: cloneRecords
    });
  };

  onClickEditRecordForView2 = (item, action) => {
    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      this.tournamentIdParams,
      null,
      (response) => {
        action && action(response);
      },
      null,
      null,
      null,
      null,
      { participant_id: item.participant_id }
    );
  };

  onClickPasteIndicatorHeader = (item, actionBtnClicked) => {
    const { copyPasteData } = this.state;
    const clone = { ...copyPasteData };

    clone.paste = { id: item.id, type: item.type };

    this.setState({ actionBtnClicked, copyPasteData: clone }, () => {
      this.onSaveCopyPasteForView2();
    });
  };

  onClickCopyIndicatorBody = (evt, item, parent) => {
    const clone = {
      ...this.state.copyPasteData
    };

    if (!clone.copy) {
      clone.copy = [
        {
          category_id: parent.id,
          participants: [
            {
              participant_id: item.id,
              ...(item.team_id ? { team_id: `${item.team_id}` } : {})
            }
          ]
        }
      ];
    } else {
      const findCategoryIdx = clone.copy.findIndex(
        (it) => +it.category_id === +parent.id
      );

      if (findCategoryIdx !== -1) {
        const findPartIdx = clone.copy[findCategoryIdx].participants.findIndex(
          (it) => +it.participant_id === +item.id
        );

        if (findPartIdx !== -1) {
          clone.copy[findCategoryIdx].participants.splice(findPartIdx, 1);
        } else {
          clone.copy[findCategoryIdx].participants = [
            ...clone.copy[findCategoryIdx].participants,
            {
              participant_id: item.id,
              ...(item.team_id ? { team_id: `${item.team_id}` } : {})
            }
          ];
        }
      } else {
        clone.copy = [
          ...clone.copy,
          {
            category_id: parent.id,
            participants: [
              {
                participant_id: item.id,
                ...(item.team_id ? { team_id: `${item.team_id}` } : {})
              }
            ]
          }
        ];
      }
    }

    this.setState({ copyPasteData: clone });
  };

  onSaveCopyPasteForView2 = () => {
    const { copyPasteData, tournamentData, actionBtnClicked } = this.state;
    const { t } = this.props;

    if (copyPasteData.paste && copyPasteData.copy) {
      Promise.all([
        ...copyPasteData.copy.map((category) => {
          if (+copyPasteData?.paste?.type !== 3) {
            const participantsIds = category.participants.map(
              (it) => it.participant_id
            );

            if (participantsIds?.length > 0) {
              return new Promise((resolve) => {
                this.distributeParticipantByCategory(
                  tournamentData.id,
                  participantsIds,
                  copyPasteData.paste?.id,
                  copyPasteData.paste?.type,
                  actionBtnClicked !== TEST_ID_COPY && category.category_id,
                  (result) => {
                    resolve(result);
                  }
                );
              });
            }
          } else {
            return new Promise((resolve) => {
              Promise.all(
                category.participants.map((val) => {
                  return new Promise((resolve) =>
                    this.distributeParticipantByCategory(
                      tournamentData.id,
                      val,
                      copyPasteData.paste?.id,
                      copyPasteData.paste?.type,
                      actionBtnClicked !== TEST_ID_COPY && category.category_id,
                      (result) => resolve(result)
                    )
                  );
                })
              ).then((res) => {
                return resolve(res);
              });
            });
          }
        })
      ]).then(() => {
        this.triggerTableUpdate();
      });

      this.setState({ copyPasteData: {} });
    } else {
      this.changeModal(t('distributeParticipantsByCategoriesError'), 400);
    }
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes, filters, page, rowsPerPage, searchBar } =
      this.state;
    const selectedCheckOnCurrentView =
      selectedCheckboxes[`view_${filters.switchedView}`];

    const IDexists = selectedCheckOnCurrentView.some(
      (item) => item.id === key.id
    );
    const checkedValues = IDexists
      ? selectedCheckOnCurrentView.filter((it) => it.id !== key.id)
      : [...selectedCheckOnCurrentView, key];

    const newSelectedCheckboxes = { ...selectedCheckboxes };
    newSelectedCheckboxes[`view_${filters.switchedView}`] = checkedValues;

    this.setState(
      {
        selectedCheckboxes: newSelectedCheckboxes,
        page: 1
      },
      () => {
        switch (key.id) {
          case 'RED':
            filters.switchedView === 1
              ? this.selectIssues(!IDexists)
              : this.selectIssuesForView2(!IDexists);
            break;
          case 'RETIRED':
            this.selectRetiredParticipants(!IDexists);
            break;
          default:
            if (filters.switchedView === 2) {
              this.onFilterBySelection(null, true);
            } else {
              this.onFilterBySelection(() => {
                this.fetchData(page, rowsPerPage, searchBar);
              }, true);
            }
            break;
        }
      }
    );
  };

  cancelFilters = () => {
    const { selectedCheckboxes, filters } = this.state;

    const selectedCheckOnCurrentView = { ...selectedCheckboxes };
    selectedCheckOnCurrentView[`view_${filters.switchedView}`] = [];

    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, issues: false, category_issue: false },
        selectedCheckboxes: selectedCheckOnCurrentView
      }),
      () => {
        const { page, rowsPerPage, searchBar } = this.state;

        if (filters.switchedView === 2) {
          this.onFilterBySelection(null, true);
        } else {
          this.onFilterBySelection(() => {
            this.fetchData(page, rowsPerPage, searchBar);
          }, true);
        }
      }
    );
  };

  onEditableTableCell = (
    evt,
    item,
    key,
    toggleEditableField,
    activateEditableField
  ) => {
    const { role } = this.context.authState;
    const { editableTableCell, tournamentData } = this.state;
    let cpy = { ...editableTableCell };

    if (toggleEditableField) {
      if (activateEditableField) {
        cpy = {
          ...cpy,
          [key]: item.participant_id,
          values: {
            ...cpy.values,
            [key]: item[key]
          }
        };
      } else {
        if (Object.values(cpy.errors).length === 0) {
          this.updateParticipant(
            evt,
            {
              ...participantIndividualData(item),
              [key]: cpy.values[key]
            },
            () => {
              this.triggerTableUpdate();
            },
            tournamentData,
            null,
            null,
            role
          );
        }

        cpy = { ...INITIAL_EDITABLE_TABLE_CELL };
      }
    } else {
      const result = onChangeText(evt, cpy.values, cpy.errors);
      cpy.values[key] = result[key];
      cpy.errors = result.errors;
    }

    this.setState({ editableTableCell: cpy });
  };

  onTableCellCheckbox = (evt, item, key) => {
    const { checked } = evt.target;
    const { tournamentData } = this.state;
    let cpy = {
      ...participantIndividualData(item),
      [key]: checked ? 1 : 0
    };

    this.updateParticipant(
      evt,
      cpy,
      () => {
        this.triggerTableUpdate();
      },
      tournamentData,
      null,
      null,
      this.role,
      true
    );
  };

  onFilterBySelection = (cb, shouldFilterCategoriesList) => {
    const { allRecordsForView2, selectedCheckboxes, filters } = this.state;
    const selectedCheckOnCurrentView =
      selectedCheckboxes[`view_${filters.switchedView}`];

    let newData = [];
    let genderArray = [];
    let ageArray = [];
    let ageArrayForView2 = [];
    let typeArray = [];
    let finalCategories = [];

    selectedCheckOnCurrentView?.map((it) => {
      if (it.gender) genderArray = [...genderArray, it.gender];
      if (it.age) {
        ageArray = [...ageArray, it.ageInterval];
        ageArrayForView2 = [...ageArrayForView2, it.age];
      }
      if (it.type) typeArray = [...typeArray, it.id];

      return true;
    });

    if (shouldFilterCategoriesList) {
      //show only the categories that match the table filter
      const { categories } = filters;
      const categoriesLen = categories?.length;

      for (let i = 0; i < categoriesLen; i++) {
        const category = categories[i];
        const filterByGenderList =
          genderArray.length > 0
            ? genderArray.some((el) => category.gender === el)
            : category;
        const filterByAgeList =
          ageArrayForView2.length > 0
            ? ageArrayForView2.some((el) =>
                category.age_to
                  ? +category.age_from >= el[0] && +category.age_to <= el[1]
                  : +category.age_from >= el[0] && +category.age_from <= el[1]
              )
            : category;
        const filterByTypeList =
          typeArray.length > 0
            ? typeArray.some((el) => +category.type === +el)
            : category;

        if (filterByGenderList && filterByAgeList && filterByTypeList) {
          finalCategories = [...finalCategories, category];
        }
      }
    }

    if (filters.switchedView === 2) {
      if (
        selectedCheckOnCurrentView?.length > 0 ||
        filters?.filteredByCategoryId ||
        filters?.category_issue
      ) {
        let categoryID = filters?.filteredByCategoryId;
        let categoryIssue = filters?.category_issue;

        const len = allRecordsForView2?.length;

        for (let i = 0; i < len; i++) {
          const category = allRecordsForView2[i];

          const filterByGenderList =
            genderArray.length > 0
              ? genderArray.some((el) => category.gender === el)
              : category;

          const filterByAgeList =
            ageArrayForView2.length > 0
              ? ageArrayForView2.some((el) =>
                  category.age_to
                    ? +category.age_from >= el[0] && +category.age_to <= el[1]
                    : +category.age_from >= el[0] && +category.age_from <= el[1]
                )
              : category;

          const filterByTypeList =
            typeArray.length > 0
              ? typeArray.some((el) => +category.type === +el)
              : category;

          const filterByCategoyID = categoryID
            ? +category.id === +categoryID
            : category;

          const filterByCategoryIssue = categoryIssue
            ? category.issue
            : category;

          if (
            filterByGenderList &&
            filterByAgeList &&
            filterByTypeList &&
            filterByCategoyID &&
            filterByCategoryIssue
          ) {
            newData = [...newData, category];
          }
        }
      } else {
        newData = [...allRecordsForView2];
      }

      const count = newData?.length ?? 0;

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          genderArray,
          ageArray,
          typeArray,
          ...(shouldFilterCategoriesList
            ? { filteredCategories: finalCategories }
            : {})
        },
        filteredRecordsForView2: newData,
        filteredRecordsForView2Count: count
      }));
    } else {
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            genderArray,
            ageArray,
            typeArray,
            ...(shouldFilterCategoriesList
              ? { filteredCategories: finalCategories }
              : {})
          }
        }),
        () => cb && cb()
      );
    }
  };

  onFilterRecordsBySelectedCategory = (evt, val) => {
    this.selectOption(
      evt,
      val,
      'filters',
      'filteredByCategoryId',
      ID,
      null,
      () => {
        this.setState({ page: 1 }, () => {
          const { filters, page, rowsPerPage, searchBar } = this.state;

          this.fetchData(page, rowsPerPage, searchBar, () => {
            if (filters.switchedView === 2) {
              this.onFilterBySelection();
            }
          });
        });
      }
    );
  };

  onFilterParticipantsBySelectedCategory = (item, id) => {
    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      this.tournamentIdParams,
      null,
      (data) => {
        this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            ...item,
            participants: data.data
          },
          open: true,
          pressedIcon: TEST_ID_INFO_BTN
        }));
      },
      id,
      1,
      1
    );
  };

  applyOnFilterByCategoryList = () => {
    const { modalData, rowsPerPage, filters } = this.state;
    const newFilters = {
      ...filters,
      filteredByCategoryId: modalData.category_id
    };

    this.setState({ filters: { ...newFilters }, searchBar: null }, () => {
      this.fetchData(1, rowsPerPage, null, () => this.hideModal());
    });
  };

  onToggleFilter = () => {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      success,
      showModal,
      modalInfo,
      rowsPerPage,
      page,
      participantsCount,
      participants,
      filteredRecordsForView2Count,
      filteredRecordsForView2,
      open,
      pressedIcon,
      categoryTypes,
      tournamentsList,
      collapsed,
      collapsedForView2,
      tournamentApplications,
      processing,
      searchBar,
      searchedValue,
      selectedRow,
      copyPasteData,
      categories,
      isFilterChecked,
      shouldDisableEditing,
      filteredParticipants,
      tournamentData,
      selectedCheckboxes,
      sortDirection,
      statistics,
      shouldShowDiscardChanges,
      selectedCategories,
      selectedCategoriesErrors,
      qdanList,
      intermidiateSchoolInfo,
      filters,
      modalData,
      searchByOption,
      editableTableCell,
      isFilterOpen
    } = this.state;

    const { t } = this.props;
    const { authState, viewportWidth } = this.context;
    const { role } = authState;
    const isTraining = isSchool(tournamentData);
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
    const tournamentStartDate = new Date(tournamentData?.start_date);
    const tournamentFinalDate = tournamentStartDate?.setDate(
      tournamentStartDate.getDate() + (+tournamentData?.duration_days ?? 0) - 1
    );
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const isRegistrarRegistrationActive = tournamentFinalDate >= currentDate;
    const selectedCheckOnCurrentView =
      selectedCheckboxes[`view_${filters.switchedView}`];

    const CategoryCheckbox = () => (
      <CheckboxBtn
        label={t('autoSelectionByAgeSex')}
        disabled={
          !this.tournamentIdParams &&
          pressedIcon === ADD_ICON &&
          !tournamentData?.id
        }
        classControlLabel={classes.marginRight0}
        checked={isFilterChecked}
        onChange={(evt) =>
          this.selectCheckbox(evt, modalData, tournamentData?.id, true)
        }
      />
    );

    const MODAL_FIRST_NAME = (item) =>
      this.tournamentIdParams
        ? !+tournamentData?.last_name_first
          ? item.participant_first_name
          : item.participant_last_name
        : !isRuLang
        ? item.first_name
        : item.last_name;

    const MODAL_LAST_NAME = (item) =>
      this.tournamentIdParams
        ? !+tournamentData?.last_name_first
          ? item.participant_last_name
          : item.participant_first_name
        : !isRuLang
        ? item.last_name
        : item.first_name;

    const MODAL_COACH_FIRST_NAME = (item) =>
      (this.tournamentIdParams && !+tournamentData?.last_name_first) ||
      (!this.tournamentIdParams && !isRuLang)
        ? item.coach_first_name
        : item.coach_last_name;

    const MODAL_COACH_LAST_NAME = (item) =>
      (this.tournamentIdParams && !+tournamentData?.last_name_first) ||
      (!this.tournamentIdParams && !isRuLang)
        ? item.coach_last_name
        : item.coach_first_name;

    const FIRST_NAME = this.tournamentIdParams
      ? !+tournamentData?.last_name_first
        ? 'participant_first_name'
        : 'participant_last_name'
      : !isRuLang
      ? 'first_name'
      : 'last_name';

    const LAST_NAME = this.tournamentIdParams
      ? !+tournamentData?.last_name_first
        ? 'participant_last_name'
        : 'participant_first_name'
      : !isRuLang
      ? 'last_name'
      : 'first_name';

    let dialogContent,
      dialogTitle,
      onClick,
      modalContent,
      subHeader,
      onSwitchBetween;
    let buttonPurpose = t('save');

    if (pressedIcon === DELETE_ICON) {
      dialogTitle = !this.tournamentIdParams
        ? t('deleteRecord', { name: t('athlete') })
        : t('removeParticipantTourn');
      dialogContent = !this.tournamentIdParams
        ? t('deleteAthleteMsg')
        : t('removeParticipantTournMsg');
      buttonPurpose = !this.tournamentIdParams
        ? t('deleteRecord', { name: '' })
        : t('remove');
    } else if (pressedIcon === CLEAR_ICON) {
      if (modalData?.current_category_id) {
        if (this.tournamentIdParams) {
          const category = modalData?.categories_info?.find(
            (it) => +it.category_id === +modalData?.current_category_id
          );
          dialogTitle = t('removeParticipantCateory', {
            name: category?.category_name
          });
          dialogContent = t('removeParticipantCateoryMsg', {
            name: category?.category_name
          });
        }
      } else {
        dialogTitle = t('removeParticipantTournament');
        dialogContent = t('removeParticipantTournamenMsg');
      }

      buttonPurpose = t('remove');
    } else if (pressedIcon === ADD_ICON) {
      dialogTitle = isTraining
        ? t('assignParticipantToTraining')
        : t('assignParticipant');
      onClick = (evt) => {
        isTraining
          ? this.setParticipantToTraining(evt)
          : this.validateMultipleCategoriesAssignment(() => {
              this.saveParticipant(evt);
            });
      };
      dialogContent = !isTraining && (
        <span
          className={clsx(
            classes.flex,
            classes.marginBottom1,
            classes.labelCheckbox
          )}>
          <CategoryCheckbox />
        </span>
      );
      modalContent = !isTraining ? (
        <AssignCategories
          {...{ tournamentsList }}
          switchTournament={this.switchTournament}
          {...{ categoryTypes }}
          {...{ selectedCategories }}
          {...{ selectedCategoriesErrors }}
          addNewCategory={() => this.addNewCategory(initialStateCategory)}
          removeCategory={this.removeCategory}
          selectCategories={this.selectMultipleCategories}
          selectCategoryType={this.selectMultipleCategoryType}
          selectTeam={this.selectMultipleTeams}
          tournamentIdParams={this.tournamentIdParams}
          shouldDisableInput={!this.tournamentIdParams && !tournamentData?.id}
          selectedTournament={tournamentData}
        />
      ) : (
        <Assign2School
          {...{ intermidiateSchoolInfo }}
          {...{ qdanList }}
          selectOption={this.selectOption}
          selectCheckboxSchool={this.selectCheckboxSchool}
        />
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      onClick = this.applyOnFilterByCategoryList;
      modalContent = modalData?.participants?.map((it, idx) => (
        <span
          className={clsx(classes.flex, classes.marginBottom05)}
          key={it.participant_id}>
          {idx + 1}. {it.participant_name}
          {'   '}(
          {[
            it.gender,
            it.age,
            it.weight && `${it.weight}kg`,
            it.qdan_name
          ].join(', ')}
          )
        </span>
      ));
      dialogTitle = t('categoryOpponents');
      buttonPurpose = t('proceed');
    } else if (pressedIcon === TEST_ID_PARTICIPANT) {
      modalContent = (
        <AddParticipant
          {...{ viewportWidth }}
          state={this.state}
          triggerTableUpdate={this.triggerTableUpdate}
          selectCheckboxSchool={this.selectCheckboxSchool}
        />
      );
      dialogTitle = modalData?.id
        ? this.tournamentIdParams
          ? t('updateRecord', { name: t('participant') })
          : t('updateRecord', { name: t('athlete') })
        : this.tournamentIdParams
        ? t('addRecord', { name: t('participant') })
        : t('addRecord', { name: t('athlete') });

      const onFormOpen = (item, nextItemIdx, parent, parentIdx) => {
        if (filters.switchedView === 2) {
          this.onClickEditRecordForView2(item, (response) => {
            this.showModalParticipant(
              null,
              response?.data?.[0],
              nextItemIdx,
              parent,
              parentIdx
            );
          });
        } else {
          this.showModalParticipant(null, item, nextItemIdx);
        }
      };

      onSwitchBetween = modalData?.id && {
        prev: () => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const parentForV2 =
              filters.switchedView === 2 &&
              filteredRecordsForView2[modalData.currentRecordParentIdxForV2];
            const item =
              filters.switchedView !== 2
                ? filteredParticipants[nextItemIdx]
                : parentForV2?.participants?.[nextItemIdx];

            onFormOpen(
              item,
              nextItemIdx,
              parentForV2,
              modalData.currentRecordParentIdxForV2
            );
          }
        },
        next: () => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const parentForV2 =
            filters.switchedView === 2 &&
            filteredRecordsForView2?.[modalData.currentRecordParentIdxForV2];
          const len =
            filters.switchedView !== 2
              ? filteredParticipants?.length - 1
              : parentForV2?.participants?.length - 1;

          if (len >= nextItemIdx) {
            const item =
              filters.switchedView !== 2
                ? filteredParticipants[nextItemIdx]
                : parentForV2?.participants?.[nextItemIdx];

            onFormOpen(
              item,
              nextItemIdx,
              parentForV2,
              modalData.currentRecordParentIdxForV2
            );
          }
        }
      };
    }

    if (pressedIcon === DELETE_ICON || pressedIcon === CLEAR_ICON) {
      onClick = () => {
        this.deleteParticipant(
          () => {
            this.triggerTableUpdate();
          },
          modalData?.current_tournament_id,
          null,
          pressedIcon === DELETE_ICON,
          pressedIcon === CLEAR_ICON && modalData?.current_category_id,
          isTraining
        );
      };
    }
    if (
      pressedIcon === ADD_ICON ||
      pressedIcon === DELETE_ICON ||
      pressedIcon === CLEAR_ICON
    ) {
      subHeader = (
        <span className={clsx(classes.flex, classes.column)}>
          {modalData && (
            <>
              <span
                className={clsx(
                  classes.flex,
                  classes.marginBottom05,
                  classes.marginTop1
                )}>
                {[
                  MODAL_FIRST_NAME(modalData),
                  MODAL_LAST_NAME(modalData),
                  [
                    modalData.age,
                    modalData.gender,
                    `${modalData.weight}${t('kg')}`,
                    (!!+tournamentData?.height_needed ||
                      !this.tournamentIdParams) &&
                      modalData.height &&
                      `${modalData.height}${t('cm')}`,
                    (!!+tournamentData?.show_reg_qdan ||
                      !this.tournamentIdParams) &&
                      modalData.qdan_name &&
                      modalData.qdan_name
                  ]
                    .filter(Boolean)
                    .join(', ')
                ].join(' ')}
              </span>
              {modalData.current_parent_category_name && (
                <span className={clsx(classes.flex, classes.marginBottom05)}>
                  {t('category')}: {modalData.current_parent_category_name}
                </span>
              )}
              {t('coach')}:{' '}
              {[
                MODAL_COACH_FIRST_NAME(modalData),
                MODAL_COACH_LAST_NAME(modalData)
              ].join(' ')}
            </>
          )}
        </span>
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      subHeader = modalData && (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>
            {[
              t('block', { count: modalData.category_tatami_block_id }),
              modalData.category_tatami_block
                ? `: ${modalData.category_tatami_block}`
                : ''
            ].join('')}
          </span>
          <span>{[t('tatami'), modalData.category_tatami_name].join(' ')}</span>
          <span>{modalData.category_name}</span>
        </span>
      );
    }

    const showParticipantsCheckboxes = [
      ...(!this.tournamentIdParams
        ? [
            {
              checked: filters?.archive,
              onChange: this.selectArchive,
              label: t('archive')
            }
          ]
        : [])
    ];

    const filter = [
      ...(!isTraining && this.tournamentIdParams && role !== 'recorder'
        ? [
            {
              options: filters.filteredCategories ?? [],
              label: t('category'),
              item: NAME,
              value:
                selectedValue(
                  filters.categories,
                  ID,
                  filters.filteredByCategoryId,
                  true
                ) || '',
              onChange: (evt, val) =>
                this.onFilterRecordsBySelectedCategory(evt, val)
            }
          ]
        : [])
    ];

    const isAdminAndNoTourn =
      !this.tournamentIdParams && role === 'administrator';

    const recordDataParams = (item, idx) => {
      const updateDate = item?.update_date?.split(' ');

      return {
        main: {
          label: t('name'),
          hasSort: true,
          sort: 1,
          style: {
            wrapper: classes.marginTop1,
            cardMainPart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1
          },
          name: [
            item?.[FIRST_NAME],
            item?.[LAST_NAME],
            ...(!this.tournamentIdParams ||
            !!+tournamentData?.show_reg_patronymic
              ? [
                  this.tournamentIdParams
                    ? item?.participant_patronymic
                    : item?.patronymic
                ]
              : [])
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          ...(role === 'administrator'
            ? [
                {
                  value:
                    item?.[this.tournamentIdParams ? 'participant_id' : 'id'],
                  name: 'ID'
                }
              ]
            : []),
          ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
            ? [
                {
                  sort: 2,
                  value: item?.participant_number,
                  name: t('number')
                }
              ]
            : []),
          { sort: 4, value: item?.gender, name: t('gender') },
          { sort: 5, value: item?.age, name: t('age') },
          {
            value:
              role === 'recorder' &&
              isRegistrarRegistrationActive &&
              editableTableCell
                ? editableTableCellComponent(item, 'weight')
                : item.weight,
            key: 'weight',
            name: t('weight')
          },
          ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
            ? [{ value: item?.height, name: t('height') }]
            : []),
          ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
            ? [{ sort: 12, value: item.qdan_name, name: t('kyuDan') }]
            : []),
          ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
            ? [{ value: item.sport_rank_short, name: t('sportsCategory') }]
            : []),
          ...(!isTraining && this.tournamentIdParams
            ? [
                {
                  value: (
                    <ListItems
                      {...{ collapsed }}
                      elem={item}
                      elemParamN="categories_info"
                      itemParamN="category_name"
                      subElemParamN="category_id"
                      paramElemName="participant_id"
                      shouldAllowEditing={true}
                      styles={{ gridDropdown: classes.gridDropdownList }}
                      actionIconsList={(elem, category, currentClasses) => (
                        <>
                          <InfoIc
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onFilterParticipantsBySelectedCategory(
                                elem,
                                category
                              );
                            }}
                            className={clsx(currentClasses)}
                          />
                          {!shouldDisableEditing && (
                            <Clear
                              id={CLEAR_ICON}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.showModal(
                                  e,
                                  CLEAR_ICON,
                                  item,
                                  null,
                                  category
                                );
                              }}
                              className={clsx(
                                currentClasses,
                                classes.marginLeft0dot5
                              )}
                            />
                          )}
                        </>
                      )}
                    />
                  ),
                  list: true,
                  name: (
                    <>
                      {t('categories')}
                      {item?.categories_info?.length > 2 && (
                        <span
                          className={clsx(
                            classes.marginLeft0dot4,
                            classes.numOfCategories
                          )}>
                          {item?.categories_info?.length}
                        </span>
                      )}
                      {item?.categories_info?.length > 2 && (
                        <DropdownUp
                          className={clsx(
                            classes.flex,
                            classes.onHover,
                            classes.marginLeft0dot4,
                            !collapsed[item.participant_id] && classes.rotate180
                          )}
                          onClick={() => {
                            this.itemToggle(item.participant_id);
                          }}
                        />
                      )}
                    </>
                  )
                }
              ]
            : []),
          ...(!+tournamentData?.no_country || isAdminAndNoTourn
            ? [{ sort: 7, value: item.participant_country, name: t('country') }]
            : []),
          ...(isAdminAndNoTourn
            ? [{ value: item.birthday, name: t('birthday') }]
            : []),
          ...(isTraining
            ? [{ value: item.next_qdan_id, name: t('nextKyuDan') }]
            : []),
          ...(isAdminAndNoTourn ? [{ value: item.club, name: t('club') }] : []),
          ...(isAdminAndNoTourn
            ? [
                {
                  value: item.history === null ? t('no') : t('yes'),
                  name: 'History'
                }
              ]
            : []),
          ...(isAdminAndNoTourn
            ? [{ value: item.participant_city, name: t('city') }]
            : []),
          ...(isAdminAndNoTourn
            ? [{ value: item.participant_region, name: t('region') }]
            : []),
          ...(isAdminAndNoTourn
            ? [{ value: item.active, name: <TimerIcon /> }]
            : []),
          ...(isAdminAndNoTourn
            ? [
                {
                  value:
                    updateDate && `${DDMMYYYY(updateDate[0])} ${updateDate[1]}`,
                  name: <AutorenewIcon />
                }
              ]
            : []),
          {
            sort: 3,
            value: item.coach_name,
            name: t('coach')
          },
          ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
            ? [
                {
                  value: hasAccessCheckbox(item, 'has_docs'),
                  name: t('admisDocs')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
            ? [
                {
                  value: hasAccessCheckbox(item, 'has_weighted'),
                  name: t('admisWeighs')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
            ? [
                {
                  value: hasAccessCheckbox(item, 'has_equipment'),
                  name: t('admisEquipment')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online && this.role === 'recorder'
            ? [
                {
                  value: hasAccessCheckbox(item, 'has_medic'),
                  name: t('admisMedic')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online
            ? [
                {
                  sort: 10,
                  value: !!+item?.registered ? t('yes') : t('no'),
                  name: t('registered')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online
            ? [{ value: item?.registered_weight, name: t('declaredWeight') }]
            : [])
        ]
      };
    };

    const recordDataParams2 = (item, idx) => {
      return {
        main: {
          style: {
            wrapper: classes.marginTop1,
            cardMainPart: classes.cardValuePart,
            cardValuePart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1,
            class: classes.rowOrder
          },
          name: (
            <span
              className={clsx(classes.flex, classes.centerVertically, {
                [classes.redText]: item?.participants_count === 1
              })}>
              <span style={{ display: 'grid', marginRight: '0.5rem' }}>
                <Copy
                  id={TEST_ID_COPY}
                  className={classes.onHover}
                  onClick={() =>
                    this.onClickPasteIndicatorHeader(item, TEST_ID_COPY)
                  }
                />
                <Transfer
                  className={classes.onHover}
                  onClick={() => this.onClickPasteIndicatorHeader(item)}
                />
              </span>
              {item.name}
            </span>
          )
        },
        info: [
          {
            value: (
              <span
                className={clsx({
                  [classes.redText]: item?.participants_count === 1
                })}>
                {item?.age_from}
              </span>
            ),
            name: `${t('age')} ${t('from').toLowerCase()}`
          },
          {
            value: (
              <span
                className={clsx({
                  [classes.redText]: item?.participants_count === 1
                })}>
                {item?.age_to}
              </span>
            ),
            name: `${t('age')} ${t('to').toLowerCase()}`
          },
          {
            value: (
              <span
                className={clsx({
                  [classes.redText]: item?.participants_count === 1
                })}>
                {categoryTypes?.find((it) => +it.id === +item?.type)?.name}
              </span>
            ),
            name: t('type')
          },
          {
            value: (
              <span
                className={clsx({
                  [classes.redText]: item?.participants_count === 1
                })}>
                {item?.gender}
              </span>
            ),
            name: t('gender')
          },
          {
            value: (
              <span
                className={clsx(classes.flex, classes.centerVertically, {
                  [classes.redText]: item?.participants_count === 1
                })}>
                {item?.participants_count}
                {+item?.participants_count > 0 && (
                  <DropdownGreyBg
                    className={clsx(
                      classes.flex,
                      classes.onHover,
                      classes.marginLeft0dot4,
                      !!collapsedForView2?.find(
                        (it) => +it?.id === +item?.id
                      ) && classes.rotate180
                    )}
                    onClick={(evt) => {
                      this.expandTableRow(evt, item);
                    }}
                  />
                )}
              </span>
            ),
            name: t('participants')
          }
        ]
      };
    };

    const collapsedRecordDataParams = (item, idx, parent) => {
      const findCopyPasteItem = copyPasteData?.copy
        ?.find((it) => +parent?.id === +it.category_id)
        ?.participants?.find(
          (it) => +it.participant_id === +item.participant_id
        );

      return {
        main: {
          hasSort: true,
          label: t('name'),
          sort: !+tournamentData?.last_name_first
            ? 'participant_first_name'
            : 'participant_last_name',
          style: { cardMainPart: classes.bgWhite },
          rowOrder: {
            value: !shouldDisableEditing && (
              <CheckboxBtn
                formControlClassName={classes.margin0}
                checked={!!findCopyPasteItem}
                onChange={(e) => {
                  this.onClickCopyIndicatorBody(e, item, parent);
                }}
              />
            )
          },
          name: [
            item?.[FIRST_NAME],
            item?.[LAST_NAME],
            ...(!this.tournamentIdParams ||
            !!+tournamentData?.show_reg_patronymic
              ? [
                  this.tournamentIdParams
                    ? item?.participant_patronymic
                    : item?.patronymic
                ]
              : [])
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          ...(role === 'administrator'
            ? [
                {
                  value:
                    item?.[this.tournamentIdParams ? 'participant_id' : 'id'],
                  name: 'ID'
                }
              ]
            : []),
          ...(this.tournamentIdParams && !+tournamentData?.no_participant_number
            ? [
                {
                  sort: 'participant_number',
                  value: item?.participant_number,
                  name: t('number')
                }
              ]
            : []),
          { sort: 'gender', value: item?.gender, name: t('gender') },
          { sort: 'age', value: item?.age, name: t('age') },
          { sort: 'weight', value: item.weight, name: t('weight') },
          ...(!!+tournamentData?.height_needed || isAdminAndNoTourn
            ? [{ value: item?.height, name: t('height') }]
            : []),
          ...(!!+tournamentData?.show_reg_qdan || isAdminAndNoTourn
            ? [{ sort: 'qdan_name', value: item.qdan_name, name: t('kyuDan') }]
            : []),
          ...(!!+tournamentData?.sport_rank_needed || isAdminAndNoTourn
            ? [{ value: item.sport_rank_short, name: t('sportsCategory') }]
            : []),
          ...(!+tournamentData?.no_country || isAdminAndNoTourn
            ? [
                {
                  sort: 'country_iso3',
                  value: item.country_iso3,
                  name: t('country')
                }
              ]
            : []),
          { sort: 'coach_name', value: item.coach_name, name: t('coach') },
          ...(!!+tournamentData?.use_reg_online
            ? [
                {
                  sort: 'registered',
                  value: !!+item?.registered ? t('yes') : t('no'),
                  name: t('registered')
                }
              ]
            : []),
          ...(!!+tournamentData?.use_reg_online
            ? [{ value: item?.registered_weight, name: t('declaredWeight') }]
            : [])
        ]
      };
    };

    const editableTableCellComponent = (item, arg) => {
      const focusedInput = item['participant_id'] === editableTableCell[arg];

      return (
        <TextField
          style={{ width: '80px' }}
          value={
            (!focusedInput ? item[arg] : editableTableCell?.values?.[arg]) || ''
          }
          name={arg}
          onChange={(evt) => {
            this.onEditableTableCell(evt, item, arg);
          }}
          onBlur={(evt) => {
            this.onEditableTableCell(evt, item, arg, true);
          }}
          error={focusedInput && Boolean(editableTableCell?.errors?.[arg])}
          helperText={focusedInput && editableTableCell?.errors?.[arg]}
          fullWidth
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              onEditableTableCell(evt, item, arg, true);
              evt.target.blur();
            }
          }}
          variant="outlined"
        />
      );
    };

    const hasAccessCheckbox = (item, paramN) => (
      <CheckboxBtn
        formControlClassName={classes.margin0}
        checked={!!+item?.[paramN]}
        onChange={(evt) =>
          isRegistrarRegistrationActive &&
          this.onTableCellCheckbox(evt, item, paramN)
        }
      />
    );
    const SEARCH_BY_OPTION = [
      {
        id: '0',
        label: this.tournamentIdParams ? t('participants') : t('athletes')
      },
      { id: '1', label: t('coaches') }
    ];

    let tableActionIcons = (item, idx, className, parent) => {
      const CurrentEdit =
        filters.switchedView !== 2 ? EditIcon : EditGreyBgBorder;
      const CurrentAdd = filters.switchedView !== 2 ? AddIcon : AddGreyBgBorder;
      const CurrentClear = filters.switchedView !== 2 ? ClearIcon : Clear;

      return (
        !shouldDisableEditing &&
        (!filters?.archive ? (
          <>
            <Tooltip
              arrow
              dialogTitle={t('removeParticipantCateory')}
              title={t('updateRecord', {
                name: this.tournamentIdParams ? t('participant') : t('athlete')
              })}>
              <CurrentEdit
                className={[...(className ?? [])]}
                onClick={(evt) => {
                  if (filters.switchedView === 2) {
                    this.onClickEditRecordForView2(item, (response) =>
                      this.showModalParticipant(
                        evt,
                        response?.data?.[0],
                        idx,
                        parent
                      )
                    );
                  } else {
                    this.showModalParticipant(evt, item, idx);
                  }
                }}
              />
            </Tooltip>
            {role !== 'recorder' ? (
              <>
                {role !== 'coach' && (
                  <Tooltip arrow title={t('assignParticipant')}>
                    <CurrentAdd
                      id={ADD_ICON}
                      className={[...(className ?? []), classes.marginLeft1]}
                      onClick={(e) => {
                        if (filters.switchedView === 2) {
                          this.onClickEditRecordForView2(item, (response) => {
                            this.showModal(
                              e,
                              ADD_ICON,
                              response?.data?.[0],
                              this.tournamentIdParams
                            );
                          });
                        } else {
                          this.showModal(
                            e,
                            ADD_ICON,
                            item,
                            this.tournamentIdParams
                          );
                        }
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip
                  arrow
                  title={
                    !this.tournamentIdParams
                      ? t('deleteRecord', { name: t('athlete') })
                      : filters.switchedView !== 2
                      ? t('removeParticipantTourn')
                      : t('removeParticipantCateory')
                  }>
                  {!this.tournamentIdParams ? (
                    <DeleteIcon
                      id={DELETE_ICON}
                      className={[...(className ?? []), classes.marginLeft1]}
                      onClick={(evt) => this.showModal(evt, DELETE_ICON, item)}
                    />
                  ) : (
                    <CurrentClear
                      id={CLEAR_ICON}
                      className={[...(className ?? []), classes.marginLeft1]}
                      onClick={(evt) => {
                        const name = item?.coach_name?.split(' ');

                        this.showModal(
                          evt,
                          CLEAR_ICON,
                          {
                            ...item,
                            ...(filters.switchedView === 2
                              ? {
                                  coach_first_name: name[0],
                                  coach_last_name: name[1],
                                  current_parent_category_name: parent?.name,
                                  currentParentID: parent?.id
                                }
                              : {})
                          },
                          this.tournamentIdParams,
                          filters.switchedView !== 2
                            ? item.category_id || null
                            : parent?.id
                        );
                      }}
                    />
                  )}
                </Tooltip>
              </>
            ) : (
              isRegistrarRegistrationActive && (
                <Tooltip
                  arrow
                  title={t(
                    !!+item.registered ? 'disableRegistration' : 'register'
                  )}>
                  <span>
                    <CheckboxBtn
                      formControlClassName={clsx(classes.margin0)}
                      styles={{
                        icon: clsx(classes.checkboxSvg, classes.marginLeft065),
                        checked: classes.checkedActionIcon
                      }}
                      className={clsx(classes.padding0)}
                      checked={!!+item.registered}
                      onChange={(evt) =>
                        this.recorderToggleRegisteredStatus(evt, item, parent)
                      }
                      disabled={shouldDisableEditing}
                    />
                  </span>
                </Tooltip>
              )
            )}
          </>
        ) : (
          <Tooltip arrow title={t('restore')}>
            <RestoreIc
              className={[...(className ?? []), classes.marginLeft1]}
              onClick={() =>
                this.restoreParticipant(item, () =>
                  this.fetchData(page, rowsPerPage, searchBar)
                )
              }
            />
          </Tooltip>
        ))
      );
    };

    const pageHeaderStatistics = {
      title: t('participantsStatisctics'),
      info: [
        {
          label:
            filters.switchedView === 1
              ? [t('total'), t('participantsTotal').toLowerCase()].join(' ')
              : t('categoriesCount'),
          name:
            filters.switchedView === 1
              ? statistics?.participantionCount
              : statistics?.totalCount
        },
        {
          label: [t('total'), t('athleteForm').toLowerCase()].join(' '),
          name: statistics?.athleteCount
        },
        ...(filters.switchedView === 1 && !!+tournamentData?.use_reg_online
          ? [
              {
                label: t('passedRegistration'),
                name: statistics?.registeredParticipation
              },
              {
                label: t('notRegistered'),
                name: statistics?.notRegistered
              }
            ]
          : [])
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            {this.tournamentIdParams && (
              <HeaderTournInfo
                {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
                shouldShowBtn
              />
            )}
            {this.tournamentIdParams && (
              <div
                className={clsx(
                  classes.centerVertically,
                  classes.marginBottom20,
                  classes.flex
                )}>
                {filters?.switchedView === 2 ? (
                  <>
                    <Tooltip arrow title={t('participantMode')}>
                      <ParticipantModeIc
                        onClick={() => this.onSwitchView(1)}
                        className={classes.icon}
                      />
                    </Tooltip>
                    <span
                      className={clsx(classes.marginLeft1, classes.labelView)}>
                      {t('switchToParticipantMode')}
                    </span>
                  </>
                ) : (
                  <>
                    <Tooltip arrow title={t('categoryMode')}>
                      <CategoryModeIc
                        onClick={() => this.onSwitchView(2)}
                        className={classes.icon}
                      />
                    </Tooltip>
                    <span
                      className={clsx(classes.marginLeft1, classes.labelView)}>
                      {t('switchToCategoryMode')}
                    </span>
                  </>
                )}
                <div className={clsx(classes.marginLeft1)}>
                  {this.tournamentIdParams && filters.switchedView === 2 && (
                    <CheckboxBtn
                      checked={filters.collapseAll ?? false}
                      onChange={(evt, checked) => {
                        this.onSwitchToggleForView2(evt, checked);
                      }}
                      label={t('expandList')}
                      classForLabel={classes.expandLabel}
                    />
                  )}
                </div>
              </div>
            )}
            <NewUsersToolbar
              search={searchBar}
              clearSearch={() =>
                this.clearSearch(searchedValue, () => this.searchHandler())
              }
              textChange={this.handleTextChange}
              onClick={(evt) => this.showModalParticipant(evt)}
              pressEnter={this.pressEnterHandler}
              showButton={!shouldDisableEditing && role !== 'recorder'}
              {...{ role }}
              specificDetails={{
                childrenElemEnd: (
                  <Filter
                    value={
                      selectedValue(SEARCH_BY_OPTION, ID, searchByOption.id) ||
                      ''
                    }
                    className={classes.styleFilter}
                    options={SEARCH_BY_OPTION}
                    item="label"
                    onChange={(e, val) =>
                      this.selectOption(e, val, 'searchByOption', ID, ID)
                    }
                    specificDetails={{ InputProps: { disableUnderline: true } }}
                  />
                )
              }}
              filter={this.tournamentIdParams && filter}
              filterButton={true}
              {...{ isFilterOpen }}
              filterSwitch={this.onToggleFilter}
              content={
                <>
                  {this.tournamentIdParams ? (
                    <Button
                      className={clsx(
                        filters.switchedView === 2 && classes.marginRight0dot5
                      )}
                      onClick={() =>
                        filters.switchedView !== 2
                          ? this.exportParticipants(
                              tournamentData.id,
                              {
                                searchedValue,
                                filteredByCategoryId:
                                  filters.filteredByCategoryId,
                                searchByOption,
                                ...filters
                              },
                              true
                            )
                          : this.exportCategories(tournamentData.id, true)
                      }
                      label={t('saveList')}
                      isSaveBtn={true}
                    />
                  ) : (
                    // SEARCH_BY_OPTION In Athletes page
                    // <Filter
                    //   value={
                    //     selectedValue(SEARCH_BY_OPTION, ID, searchByOption.id) || ''
                    //   }
                    //   className={classes.styleFilter}
                    //   options={SEARCH_BY_OPTION}
                    //   item="label"
                    //   onChange={(e, val) =>
                    //     this.selectOption(e, val, 'searchByOption', ID, ID)
                    //   }
                    // />

                    showParticipantsCheckboxes.map(
                      (checkboxData, checkboxIdx) => (
                        <div key={checkboxIdx} className={classes.center}>
                          <CheckboxBtn
                            checked={checkboxData?.checked ?? false}
                            onChange={checkboxData.onChange}
                            label={checkboxData.label}
                          />
                        </div>
                      )
                    )
                  )}
                </>
              }
            />
            <span
              className={clsx(
                classes.flex,
                classes.wrap,
                classes.marginBottom1
              )}>
              <FilterCheckboxes
                {...{
                  categoryTypes,
                  isFilterOpen,
                  role
                }}
                selectedCheckboxes={selectedCheckOnCurrentView}
                filterDetails={{
                  ownerCategories: false,
                  tounamentPresence: false,
                  noUnisex: false,
                  reds: this.tournamentIdParams,
                  dropouts:
                    filters?.switchedView === 1 ? this.tournamentIdParams : null
                }}
                onSelectCheckbox={this.onSelectCheckbox}
                cancelFilter={this.cancelFilters}
              />
            </span>
            <Modal
              {...{ open }}
              close={this.hideModal}
              {...{ onClick, dialogTitle }}
              {...{ dialogContent }}
              {...{ buttonPurpose, subHeader, onSwitchBetween }}
              discardOrKeepEditing={this.discardOrKeepEditing}
              {...{ shouldShowDiscardChanges }}
              specificDetails={{ className: { actionBtn: classes.isSaveBtn } }}>
              {modalContent}
            </Modal>
            <Table
              {...{ tournamentData }}
              onColumnClick={(e, item, key) => {
                if (
                  editableTableCell &&
                  Object.keys(editableTableCell).includes(key)
                ) {
                  e.stopPropagation();

                  this.onEditableTableCell(e, item, key, true, true);
                }
              }}
              recordDataParams={
                filters.switchedView !== 2
                  ? recordDataParams
                  : recordDataParams2
              }
              {...{ collapsedRecordDataParams }}
              data={
                filters.switchedView !== 2
                  ? role === 'coach'
                    ? filteredParticipants
                    : participants
                  : filteredRecordsForView2.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
              }
              onClickSubtableRow={(evt, item, itemIdx, parent, parentIdx) => {
                if (filters.switchedView === 2) {
                  this.onClickEditRecordForView2(item, (response) => {
                    this.showModalParticipant(
                      evt,
                      response?.data?.[0],
                      itemIdx,
                      parent,
                      parentIdx
                    );
                  });
                }
              }}
              {...{ role, filter }}
              selected={
                filters.switchedView !== 2
                  ? (item) => {
                      const id = item.id || item.participant_id;
                      return selectedRow === id;
                    }
                  : undefined
              }
              actionIcons={(item, idx, className) => {
                return (
                  filters.switchedView !== 2 &&
                  tableActionIcons(item, idx, className)
                );
              }}
              subActionsIcons={(item, idx, className, parent) => {
                return tableActionIcons(item, idx, className, parent);
              }}
              selectedSubTable={(item) => {
                return +selectedRow === +item.id;
              }}
              onSortCollapsible={this.onSortCollapsibleTable}
              onSort={this.onSort}
              {...{ sortDirection }}
              {...{ categoryTypes }}
              {...{ copyPasteData }}
              // mainTableRowColor={
              //   filters.switchedView === 2 && classes.mainTableRowColor
              // }
              // onClickPasteIndicatorHeader={this.onClickPasteIndicatorHeader}
              subTableDataKey={filters.switchedView === 2 && 'participants'}
              id="id"
              // showMainTbClassNameIssue={(item, param) => {
              //   return (
              //     (filters.switchedView === 2 &&
              //       param === 'name' &&
              //       item.issue) ||
              //     (filters.switchedView === 1 &&
              //       typeof param === 'object' &&
              //       param.firstName &&
              //       !!+item.duplicate)
              //   );
              // }}
              collapsed={collapsedForView2}
              //Categories List records
              // shouldShowNoncollapsibleList={this.tournamentIdParams}
              // tournamentIdParams={this.tournamentIdParams}
              // {...{ tournamentApplications }}
              // {...{ processing }}
              // listCollapsed={collapsed}
              // {...{ qdanList }}
              // openModal={(evt, item, tournID, catID, currentTarget) =>
              //   this.showModal(evt, currentTarget, item, tournID, catID)
              // }
              // shouldDisableEditing={role === 'recorder' || shouldDisableEditing}
            />
            {filters.switchedView !== 2
              ? Array.isArray(participants) &&
                participants.length > 0 && (
                  <Pagination
                    count={participantsCount}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={(evt) =>
                      this.rowsPerPageHandler(evt, this.searchHandler)
                    }
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )
              : filteredRecordsForView2Count > 0 && (
                  <Pagination
                    count={filteredRecordsForView2Count}
                    onPageChange={this.pageChangeHandler}
                    onRowsPerPageChange={this.rowsPerPageHandler}
                    {...{ page }}
                    {...{ rowsPerPage }}
                  />
                )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(UserList)));
