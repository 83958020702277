import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
    fixed: {
        position: 'fixed',
        right: '24px'
    },

    pointer: {
        cursor: 'pointer'
    },

    iframe: {
        zIndex: 10,
        maxWidth: '500px',
        maxHeight: '500px',
        width: '100%',
        height: '100%'
    },

    width: {
        width: '100%'
    },

    backgroundColor: {
        borderWidth: '2px',
        borderRadius: '10px',
        backgroundColor: '#005FEE'
    },

    txt: {
        fontSize: '20px',
        fontWeight: 500
    },

    image: {
        marginTop: '20px',
        width: '100%'
    }, 

    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    
    main: {
        maxWidth: '500px',
        maxHeight: '520px',
        width: '100%',
        height: '100%',
        zIndex: 10
    },

    border: {
        boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
    },

    backColorWhite: {
        backgroundColor: 'white'
    },

    white: {
        color: 'white',
        display: 'flex',
        marginLeft: 'auto'
    },
    
    radiusIc: {
        borderRadius: '.2rem',
        border: '1px solid #98A5BA'
      },
})