import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  blue: {
    color: '#2D63EE',
  },
  dropdownIcon: {
    '& .MuiAutocomplete-hasPopupIcon, & .MuiAutocomplete-inputRoot': {
      paddingRight: '1em'
    }
  },
  fontW700: {
    fontWeight: 700
  },
  width: {
    width: '100%'
  },
  root: {
    marginRight: 0,
    marginLeft: 0,
    padding: '32px 45px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#ffffff',
    zIndex: '10',
    height: '105px',
    '@media (max-width:504px)': {
      height: '60px',
      padding: '16px 20px',
    }
  },
  toolbar: {
    maxWidth: '1050px',
    paddingRight: '0px',
    paddingLeft: '0px',
    display: 'block'
  },
  fontSize: {
  '@media(min-width: 1001px)': {
    fontSize: '16px'
  },
    fontSize: '28px'
  },
  logoname: {
    float: 'left',
    textDecoration: 'none',
    color: '#333333',
    '@media (max-width:504px)': {
      fontSize: '20px'
    }
  },
  menu: {
    display: 'flex',
    color: '#262441',
    lineHeight: '24px',
    float: 'right',
  },
  panel: {
    '@media(min-width: 1001px)': {
      marginRight: '24px',
      padding: 0,
      display: 'inline-block',
      verticalAlign: 'top',
      textDecoration: 'none',
      textAlign: 'left',
    },
    '@media(max-width: 1000px)': {
      padding: '30px'
    },
    '&:hover': {
      color: '#00BB92',
    }
  },
  black: {
    color: '#222222'
  },
  chosen: {
    '@media(min-width: 1001px)': {
      borderBottom: '2px solid #009a78'
    },
    fontWeight: 700,
    color: '#00BB92'
  },
  exitBtn: {
    cursor: 'pointer',
    '&:hover': {
      color: '#005FEE',
      backgroundColor: '#e0e0e0'
    },
    alignItems: 'center',
    padding: '3px 5px 3px 0px',
    borderRadius: '2px',
    borderBottom: '0.5px solid black',
    fontSize: '16px'
  },
  sizeBtn: {
    width: '100%',
    height: 'auto',
    color: 'black',
    background: 'black',
    fill: 'red',
    stroke: 'blue'
  },
  mobileBtn: {
    display: 'none',
    '@media(max-width: 1000px)': {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '20px',
      cursor: 'pointer',
      zIndex: 10,
      width: '21px',
      height: '25px'
    }
  },
  mobileMenu: {
    '@media(max-width: 1000px)': {
      paddingTop: '100px',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      right: '-300px',
      top: 0,
      width: '300px',
      height: '100%',
      backgroundColor: '#FFFFFF',
      zIndex: 4,
      transition: 'all 1s',
      textAlign: 'center'
    }
  },
  active: {
    right: 0
  },
  z10: {
    zIndex: 10
  },
  absolute: {
    position: 'fixed'
  },
  paperShadow: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    '@media (min-width:901px)': {
      borderRadius: '4px 0 0 4px'
    }
  },
  fontSize18: {
    fontSize: '18px'
  }
});
