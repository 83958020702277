import { Fragment } from 'react';
import { ro, ru, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { TextField } from '@material-ui/core';

import { ReactComponent as DateIcon } from '../../assets/img/date.svg';

import {
  ID,
  NAME,
  QDAN,
  COUNTRY_ID,
  DATE_PLACEHOLDER,
  GENDER,
  DAYS,
  GENDER_LABEL
} from 'helpers/constants';
import { selectedValue, countryLabel } from 'helpers/selectors';

import UploadImage from '../UploadImage/UploadImage';
import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';

import { useStyles, bgColor } from './Styles';

const StaffForm = (props) => {
  const {
    selectDays,
    countries,
    allRegions,
    fetchRegionBySelectedCountry,
    changeTxt,
    values,
    selectOption,
    errors,
    param,
    qdanList,
    changeDate,
    saveForm,
    tournamentData,
    changeImage,
    openRegistration,
    all_qual_iko,
    all_qual_rf,
    allPositions,
    allRegistrar,
    autoFillRegistrarData,
    shouldRenderTorunRegistrarList
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const inputsData = [
    {
      name: !+tournamentData?.last_name_first ? 'first_name' : 'last_name',
      label: !+tournamentData?.last_name_first ? t('firstname') : t('lastname')
    },
    {
      name: !+tournamentData?.last_name_first ? 'last_name' : 'first_name',
      label: !+tournamentData?.last_name_first ? t('lastname') : t('firstname')
    },
    ...(!!+tournamentData?.show_reg_patronymic
      ? [
          {
            name: 'patronymic',
            label: t('patronymic')
          }
        ]
      : []),
    ...(+tournamentData?.type === 2
      ? [
          {
            name: !+tournamentData?.last_name_first
              ? 'first_name_national'
              : 'last_name_national',
            label: !+tournamentData?.last_name_first
              ? t('firstNameNational')
              : t('lastNameNational')
          },
          {
            name: !+tournamentData?.last_name_first
              ? 'last_name_national'
              : 'first_name_national',
            label: !+tournamentData?.last_name_first
              ? t('lastNameNational')
              : t('firstNameNational')
          }
        ]
      : []),
    {
      gender: true,
      isFilter: true
    },
    {
      birthday: true,
      isDate: true
    },
    {
      kdan: true,
      isFilter: true
    },
    ...(!+tournamentData?.no_country
      ? [
          {
            country: true,
            isFilter: true
          }
        ]
      : []),
    ...(!!+tournamentData?.show_reg_city
      ? [
          {
            name: 'city',
            label: t('city')
          }
        ]
      : []),
    ...(!!+tournamentData?.show_reg_region
      ? [
          allRegions?.length > 0
            ? {
                region: true,
                isFilter: true
              }
            : {
                name: 'region',
                label: t('district')
              }
        ]
      : []),
    ...(!!+tournamentData?.show_reg_club
      ? [
          {
            name: 'club',
            label: t('cluborFederationName')
          }
        ]
      : []),
    {
      positions: true,
      isFilter: true
    },
    {
      name: 'phone',
      label: t('phone')
    },
    {
      name: 'email',
      label: t('email')
    },
    ...(+tournamentData?.duration_days > 1
      ? [{ days: true, isFilter: true }]
      : []),
    {
      name: 'experience',
      label: t('experience'),
      int: true
    },
    {
      qualificationRfRid: true,
      isFilter: true
    },
    {
      qualificationIkoRid: true,
      isFilter: true
    },
    {
      name: 'history',
      label: t('history'),
      rows: true,
      className: classes.historyInput
    },
    { file: true },
    { save: true }
  ];

  const filter = (
    label,
    options,
    attribute,
    value,
    key,
    item,
    className,
    error,
    helperText,
    disabled,
    cb,
    multiline,
    additionalItem,
    intVal,
    optional
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      value,
      intVal
    );

    return (
      <Filter
        className={clsx(classes.maxHeightAutocomplete, className)}
        {...{ options }}
        {...{ label }}
        value={currentVal || ''}
        onChange={(evt, val) =>
          selectOption(evt, val, param, attribute, key, errors, cb)
        }
        {...{ item }}
        variant="outlined"
        {...{ error }}
        {...{ helperText }}
        {...{ disabled }}
        id={attribute}
        {...{ optional, multiline }}
      />
    );
  };

  const renderTorunRegistrarList = () => {
    return (
      allRegistrar &&
      filter(
        t('teamRepresentative'),
        allRegistrar,
        'registrar_id',
        values.registrar_id,
        ID,
        {
          firstName: 'first_name',
          lastName: 'last_name'
        },
        null,
        !!errors?.registrar_id,
        errors?.registrar_id,
        !+values?.tournament_id || !!+values?.id,
        (val) => autoFillRegistrarData && autoFillRegistrarData(val),
        true,
        null,
        true,
        'email'
      )
    );
  };

  return (
    <div className={clsx(classes.paper, classes.grid, classes.marginTop1)}>
      <div className={classes.formAvatarWrapper}>
        <div
          className={clsx(classes.fullWidth, classes.formAvatarInnerWrapper)}>
          <UploadImage
            className={classes.formAvatar}
            id="upload-file"
            img={values.imagePreview}
            onChange={changeImage}
          />
          {shouldRenderTorunRegistrarList && renderTorunRegistrarList()}
        </div>
      </div>
      {inputsData.map((it, idx) => (
        <Fragment key={idx}>
          {!it.isFilter && !it.isDate && !it.file && !it.save && (
            <TextField
              value={values[it.name] || ''}
              name={it.name}
              label={it.label}
              className={clsx(classes.maxHeightTxtInput, it.className)}
              variant="outlined"
              onChange={(evt) =>
                changeTxt(
                  evt,
                  param,
                  errors,
                  it.int,
                  null,
                  null,
                  tournamentData,
                  null,
                  null,
                  values,
                  values?.id
                )
              }
              error={errors && Boolean(errors[it.name])}
              helperText={errors?.[it.name]}
            />
          )}
          {it.isFilter && (
            <>
              {it.country &&
                filter(
                  t('country'),
                  countries,
                  COUNTRY_ID,
                  values.country_id,
                  ID,
                  {
                    firstName: 'iso3',
                    lastName: countryLabel(localStorage.getItem('i18nextLng')),
                    parathesis: true
                  },
                  it.className,
                  !!errors?.country_id,
                  errors?.country_id,
                  null,
                  (val) =>
                    fetchRegionBySelectedCountry &&
                    fetchRegionBySelectedCountry(val),
                  null,
                  null,
                  true
                )}
              {it.region &&
                filter(
                  t('district'),
                  allRegions,
                  'region',
                  values.region,
                  NAME,
                  NAME,
                  it.className,
                  Boolean(errors?.region),
                  errors?.region,
                  null,
                  null,
                  null,
                  { name: values.region }
                )}
              {it.positions &&
                allPositions &&
                filter(
                  t('positions'),
                  allPositions,
                  'positionId',
                  values.positionId,
                  ID,
                  {
                    firstName: 'shortName',
                    lastName: 'name',
                    parathesis: true
                  },
                  it.className,
                  Boolean(errors?.positionId),
                  errors?.positionId,
                  null,
                  null,
                  null,
                  null,
                  true
                )}
              {!!+tournamentData?.show_reg_qdan &&
                it.kdan &&
                filter(
                  t('kyuDan'),
                  qdanList,
                  QDAN,
                  values.qdan,
                  ID,
                  NAME,
                  it.className,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  true
                )}
              {it.gender &&
                filter(
                  t('gender'),
                  GENDER,
                  GENDER_LABEL,
                  values.gender,
                  NAME,
                  NAME,
                  it.className,
                  Boolean(errors?.gender),
                  errors?.gender
                )}
              {!!+tournamentData?.show_reg_iko &&
                it.qualificationIkoRid &&
                filter(
                  t('ikoQual'),
                  all_qual_iko,
                  'qual_iko',
                  values.qual_iko,
                  ID,
                  {
                    firstName: 'shortName',
                    lastName: NAME,
                    parathesis: true
                  },
                  it.className
                )}
              {it.qualificationRfRid &&
                filter(
                  t('rfQual'),
                  all_qual_rf,
                  'qual_rf',
                  values.qual_rf,
                  ID,
                  {
                    firstName: 'shortName',
                    lastName: NAME,
                    parathesis: true
                  },
                  it.className
                )}
            </>
          )}
          {it.isDate && (
            <>
              {it.birthday && (
                <MuiPickersUtilsProvider
                  locale={[ru, ro, de].find(
                    (it) => it.code === localStorage.getItem('i18nextLng')
                  )}
                  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={clsx(
                      classes.maxHeightTxtInput,
                      classes.endAdornment,
                      it.className
                    )}
                    keyboardIcon={<DateIcon />}
                    cancelLabel={t('cancel')}
                    okLabel={t('apply')}
                    value={values.birthday || null}
                    onChange={changeDate}
                    placeholder={DATE_PLACEHOLDER}
                    format="dd/MM/yyyy"
                    label={t('birthday')}
                    inputVariant="outlined"
                    error={Boolean(errors.birthday)}
                    helperText={errors.birthday}
                  />
                </MuiPickersUtilsProvider>
              )}
            </>
          )}
          {it.days && (
            <Filter
              className={clsx(classes.maxHeightAutocomplete)}
              value={values.days || []}
              onChange={(e, value) => selectDays(e, value)}
              options={DAYS}
              multiple
              label={t('days')}
              item="id"
              variant="outlined"
            />
          )}
          {it.file && (
            <span
              className={clsx(
                classes.uploadFile,
                classes.onHover,
                classes.formFileLg
              )}>
              <input
                accept="image/*"
                className={clsx(classes.inputFile, classes.noEvent)}
                id="JUDGE_UPLOAD_FILE"
                type="file"
                onChange={(evt) =>
                  changeImage(evt, param, 'file', 'imagePreview')
                }
              />
              <label htmlFor="JUDGE_UPLOAD_FILE">
                <span className={clsx(classes.uploadFileHover)}>
                  <span className={clsx(classes.text, classes.ellipsis)}>
                    {values.file
                      ? values.file?.name
                      : t('judicialQualifications')}
                  </span>
                </span>
              </label>
            </span>
          )}
          {it.save && (
            <Button
              label={t('save')}
              onClick={saveForm}
              className={clsx(classes.formSave)}
              isSaveBtn
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};
export default StaffForm;
