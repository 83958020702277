const styles = () => ({
  flex: {
    display: 'flex'
  },

  fullWidth: {
    width: '100%'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  }
});

export default styles;
