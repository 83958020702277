import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as AddIcon } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as DropdownUp } from 'assets/img/dropdown_black&white_bg.svg';
import { ReactComponent as ClearGreyIcon } from 'assets/img/cancel_btn_grey_bg&border.svg';

import { Tooltip } from '@material-ui/core';

import {
  ID,
  ADD_ICON,
  DELETE_ICON,
  KEEP_EDITING,
  CLOSE_DISCARD,
  FORM,
  CLEAR_ICON_PERMISSION_MODAL
} from '../../helpers/constants';
import {
  textChangeHandler,
  changeModal,
  changeImage,
  fetchRegistrarsCoaches,
  deleteRegistrarsCoaches,
  selectOption,
  newRegistrarCoach,
  updateRegistarCoach,
  fetchPermissionRegistrarCoach,
  setPermissionRegistrarCoach,
  fetchTournaments,
  resetPermissionRegistrarCoach,
  onBlur,
  validateForm,
  setPermissions
} from '../../helpers/util';
import {
  selectedValue,
  compareValueForSorting,
  findSortDirection
} from '../../helpers/selectors';
import { changeTitle } from '../../helpers/actions';

import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/ModalNewDesign';
import LoadingState from '../../components/LoadingState/LoadingState';
import Form from './Form';
import Permissions from './Permissions';
import Table from '../../components/Table/CardsList';
import Filter from '../../components/Filter/Filter';
import Button from '../../components/Buttons/ActionButtons';
import { SearchInput } from 'components';

import styles from './StylesRegisterCoaches';

const initialState = {
  first_name: '',
  last_name: '',
  username: '',
  role_id: ''
};
const defaultPermission = { name: '', value: '' };
const CLEAR_ICON_SUBTABLE = 'CLEAR_ICON_SUBTABLE';

class RegistrarsCoaches extends Component {
  state = {
    form: {},
    errors: {},
    users: [],
    allPermissions: [],
    setPermissionForm: {},
    setPermissionErrors: {},
    showError: {},
    filters: {},
    tournamentsList: [],
    collapsed: [],
    sortDirection: [],
    showModal: false,
    success: false,
    open: false,
    isPasswordVisible: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    shouldShowPermissionsModal: false,
    selectedCheckboxes: []
  };

  textChangeHandler = textChangeHandler.bind(this);
  changeModal = changeModal.bind(this);
  changeImage = changeImage.bind(this);
  fetchRegistrarsCoaches = fetchRegistrarsCoaches.bind(this);
  deleteRegistrarsCoaches = deleteRegistrarsCoaches.bind(this);
  selectOption = selectOption.bind(this);
  newRegistrarCoach = newRegistrarCoach.bind(this);
  updateRegistarCoach = updateRegistarCoach.bind(this);
  fetchPermissionRegistrarCoach = fetchPermissionRegistrarCoach.bind(this);
  setPermissionRegistrarCoach = setPermissionRegistrarCoach.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  resetPermissionRegistrarCoach = resetPermissionRegistrarCoach.bind(this);
  onBlur = onBlur.bind(this);
  validateForm = validateForm.bind(this);
  setPermissions = setPermissions.bind(this);

  componentDidMount() {
    changeTitle(this.props.t('users'));

    this.fetchRegistrarsCoaches();
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, setPermissionForm, isEditing, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      ((form && form !== prevState.form) ||
        (setPermissionForm &&
          setPermissionForm !== prevState.setPermissionForm))
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('users'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  onSearch = (event) => {
    const query = event.currentTarget.value;

    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: query } }),
      () => this.onFilterRecords()
    );
  };

  selectCheckbox = (tournament) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some(
      (item) => item.id === tournament.id
    )
      ? selectedCheckboxes.filter((it) => it.id !== tournament.id)
      : [...selectedCheckboxes, tournament];

    this.setState({ selectedCheckboxes: checkedValues });
  };

  onFilterRecords = () => {
    const { users, filters } = this.state;
    const len = users?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = users[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byFilterRole = !!+filters.filteredRole
        ? +el?.role_id === +filters.filteredRole
        : true;

      if (bySearch && byFilterRole) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }

    this.setState({ filteredUsers: newFilteredRecords });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  closeSnackbar = () => this.setState({ showModal: false });

  showModal = (evt, item, parentValue, currentTargetID) => {
    const targetId = currentTargetID ?? evt.target.id;
    if (targetId === ADD_ICON) {
      this.fetchTournaments(
        null,
        1,
        null,
        () => {
          const { tournamentsList, allPermissions } = this.state;
          let selectedCheckboxes = [];

          tournamentsList.map((el) => {
            if (allPermissions.some((it) => +it.value === +el.id)) {
              selectedCheckboxes = [...selectedCheckboxes, el];
            }
          });

          this.setState({ selectedCheckboxes });
        },
        null,
        1,
        { current_now: 1 }
      );
    }

    this.setState({ pressedBtn: targetId }, () => {
      const { pressedBtn } = this.state;

      if (pressedBtn === ADD_ICON) {
        this.setState({ shouldShowPermissionsModal: false });
        this.fetchPermissionRegistrarCoach(item.id, true);
        this.setState({
          tournamentsList: [],
          setPermissionForm: {}
        });
      } else if (!pressedBtn) {
        if (item) {
          this.setState({ form: { ...item } });
        }
      }
    });

    this.setState({
      open: true,
      modalData: item,
      userId:
        targetId !== CLEAR_ICON_SUBTABLE
          ? item
            ? item.id
            : null
          : parentValue?.id
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        errors: {},
        form: {},
        setPermissionForm: {},
        setPermissionErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt, currentID) => {
    if (currentID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (currentID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  selectRole = (_, value) => {
    const { sortDirection } = this.state;

    if (value === null) {
      this.setState(
        (prevState) => ({
          filters: { ...prevState.filters, filteredRole: value }
        }),
        () => {
          const { filters } = this.state;

          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            } else {
              this.onFilterRecords();
            }
          });
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          filters: { ...prevState.filters, filteredRole: value.id }
        }),
        () => {
          const { filters } = this.state;

          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            } else {
              this.onFilterRecords();
            }
          });
        }
      );
    }
  };

  toggleShow = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  onSetPermission = (evt) => {
    evt.preventDefault();

    const { userId, selectedCheckboxes, filters, sortDirection, collapsed } =
      this.state;
    const permissions = selectedCheckboxes.map((el) => ({
      name: 'tournament',
      value: el.id
    }));

    this.setPermissions(evt, userId, permissions, () => {
      this.fetchPermissionRegistrarCoach(userId, true, (body) => {
        let copyCollapsed = [...collapsed];
        const findIdx = copyCollapsed.findIndex((it) => +it.id === +userId);

        if (findIdx !== -1) {
          copyCollapsed[findIdx].tournaments = [...body];
        }
      });
      this.fetchRegistrarsCoaches(filters.filteredRole, () => {
        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });

      this.setState(() => {
        this.hideModal();
      });
    });
  };

  saveUser = (evt) => {
    const { form, filters, userId, sortDirection } = this.state;

    const onResponse = () => {
      this.fetchRegistrarsCoaches(filters.filteredRole, () => {
        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }

        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      });
    };

    this.validateForm(
      evt,
      userId ? initialState : { ...initialState, password: '' },
      form,
      'errors',
      () => {
        userId
          ? this.updateRegistarCoach(evt, form, onResponse)
          : this.newRegistrarCoach(
              evt,
              {
                ...form,
                username: `${form.username}@${localStorage.getItem('user_id')}`
              },
              onResponse
            );
      },
      () => {
        this.setState({
          showError: {
            first_name: true,
            last_name: true,
            username: true,
            password: true,
            role_id: true
          }
        });
      }
    );
  };

  onTxtEdit = (evt) => {
    const { form, errors } = this.state;
    this.textChangeHandler(
      evt,
      FORM,
      errors,
      null,
      form,
      true,
      null,
      null,
      () => {
        this.setState({ isEditing: true });
      }
    );
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it?.id === +item?.id
      );

      if (findIndex === -1) {
        this.fetchPermissionRegistrarCoach(item.id, null, (body) => {
          const rowSubTable = {
            id: item.id,
            tournaments: body,
            sortDirection: []
          };

          this.setState((prevState) => ({
            collapsed: [...prevState.collapsed, rowSubTable]
          }));
        });
      } else {
        allCollapsedTables.splice(findIndex, 1);
        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onResetPermissionInsidePermissionsModal = (evt, item) => {
    const targetId = evt.target.id;

    this.setState((prevState) => ({
      modalData: {
        ...prevState.modalData,
        tournament_name: item.tournament_name,
        permision_id: item.id
      }
    }));
  };

  goBackPermissionsModal = () => {
    const { modalData } = this.state;
    const copyModalData = { ...modalData };
    delete copyModalData.tournament_name;
    delete copyModalData.permision_id;

    this.setState((prevState) => ({
      pressedBtn: ADD_ICON,
      modalData: copyModalData
    }));
  };

  onResetPermissionSubtable = (evt, permissionID, cb) => {
    const { collapsed, filters, userId, sortDirection } = this.state;

    this.resetPermissionRegistrarCoach(evt, permissionID, () => {
      this.fetchRegistrarsCoaches(filters.filteredRole, () => {
        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });

      this.fetchPermissionRegistrarCoach(userId, true, (body) => {
        let copyCollapsed = [...collapsed];
        const findIdx = copyCollapsed?.findIndex((it) => +it.id === +userId);

        if (findIdx !== -1) {
          copyCollapsed[findIdx].tournaments = [...body];
        }

        this.setState({ collapsed: copyCollapsed });
      });

      cb && cb();
    });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { users, sortDirection } = this.state;
    const clone = [...users];
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    let field;
    switch (sortField) {
      case 'name':
        field = !isRuLang ? 'first_name' : 'last_name';
        break;
      case 'username':
        field = 'username';
        break;
      case 'role':
        field = 'role_id';
        break;
      case 'status':
        field = 'status_id';
        break;
    }

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, field, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        users: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      form,
      errors,
      success,
      modalInfo,
      showModal,
      loading,
      filteredUsers,
      collapsed,
      open,
      userId,
      filters,
      pressedBtn,
      tournamentsList,
      allPermissions,
      showError,
      isPasswordVisible,
      shouldShowPermissionsModal,
      setPermissionErrors,
      shouldShowDiscardChanges,
      setPermissionForm,
      sortDirection,
      modalData,
      selectedCheckboxes
    } = this.state;
    const { t, classes } = this.props;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
    const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
    const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

    const REGISTRAR_COACH = [
      { id: '4', label: t('teamRepresentative') },
      { id: '7', label: t('operator') }
    ];

    const recordDataParams = (item, idx) => {
      return {
        main: {
          label: t('name'),
          hasSort: true,
          sort: !isRuLang ? 'first_name' : 'last_name',
          style: {
            cardMainPart: classes.cardValuePart
          },
          rowOrder: {
            value: idx + 1
          },
          name: [item?.[FIRST_NAME], item?.[LAST_NAME]].join(' ')
        },
        info: [
          { sort: 'username', value: item?.username, name: t('username') },
          {
            sort: 'role',
            value:
              item.role === 'operator'
                ? t('operator')
                : t('teamRepresentative'),
            name: t('role')
          },
          {
            sort: 'status',
            value: t(item?.status === 'active' ? 'active' : 'inactive'),
            name: t('status')
          },
          {
            value: (
              <span
                onClick={(evt) => {
                  this.expandTableRow(evt, item);
                }}
                className={clsx(
                  classes.flex,
                  classes.onHover,
                  classes.centerVertically
                )}>
                {item?.permissions}
                {+item?.permissions > 0 && (
                  <DropdownUp
                    className={clsx(
                      classes.flex,
                      classes.onHover,
                      classes.marginLeft0dot4,
                      !!collapsed?.find((it) => +it?.id === +item?.user_id) &&
                        classes.rotate180
                    )}
                  />
                )}
              </span>
            ),
            name: t('permissions')
          }
        ]
      };
    };

    const collapsedRecordDataParams = (item, idx, parent) => {
      return {
        main: {
          name: item.tournament_name,
          style: {
            cardMainPart: classes.bgWhite
          },
          rowOrder: {
            value: idx + 1
          }
        },
        info: [
          { value: item?.tournament_type, name: t('eventType') },
          { value: item?.start_date, name: t('eventStartDate') },
          { value: item.participants_count, name: t('participants') },
          {
            value: (
              <Tooltip title={t('deleteRecord', { name: t('tournament') })}>
                <ClearGreyIcon
                  id={CLEAR_ICON_SUBTABLE}
                  className={clsx(classes.onHover)}
                  onClick={(evt) =>
                    this.showModal(evt, item, parent, CLEAR_ICON_SUBTABLE)
                  }
                />
              </Tooltip>
            ),
            className: classes.center
          }
        ]
      };
    };

    let onClick, dialogTitle, dialogContent, subHeader, children;

    if (pressedBtn === DELETE_ICON) {
      onClick = (evt) =>
        this.deleteRegistrarsCoaches(evt, userId, () => {
          this.fetchRegistrarsCoaches(filters.filteredRole, () => {
            this.onFilterRecords();
            this.hideModal();
          });
        });
      dialogTitle = t('deleteUser');
      dialogContent = t('deleteUserMsg');
    } else if (pressedBtn === ADD_ICON) {
      dialogTitle = t('permissions');
      onClick = (evt) => this.onSetPermission(evt);
      children = (
        <div className={clsx(classes.maxHeight)}>
          <Permissions
            {...{ selectedCheckboxes }}
            selectCheckboxes={this.selectCheckbox}
            selectOption={this.selectOption}
            {...{ allPermissions }}
            {...{ tournamentsList }}
            fetchTournaments={this.fetchTournaments}
            resetPermission={this.resetPermission}
            errors={setPermissionErrors}
            {...{ setPermissionForm }}
            specificDetails={{
              className: {
                actionBtn: [classes.isSaveBtnPermissions]
              }
            }}
          />
        </div>
      );
    } else if (
      pressedBtn === CLEAR_ICON_SUBTABLE ||
      pressedBtn === CLEAR_ICON_PERMISSION_MODAL
    ) {
      dialogTitle = t('deleteRecord', { name: t('event') });
      dialogContent = t('deleteEventMsg');
      subHeader = modalData?.tournament_name;
      onClick = (evt) =>
        pressedBtn === CLEAR_ICON_SUBTABLE
          ? this.onResetPermissionSubtable(evt, modalData.id, this.hideModal)
          : this.onResetPermissionSubtable(
              evt,
              modalData.permision_id,
              this.goBackPermissionsModal
            );
    } else {
      dialogTitle = userId
        ? t('updateRecord', { name: t('user') })
        : t('addRecord', { name: t('user') });
      children = (
        <Form
          {...{ errors }}
          {...{ form }}
          registrarCoachOptions={REGISTRAR_COACH}
          selectOption={this.selectOption}
          onChange={this.onTxtEdit}
          onBlur={this.onBlur}
          {...{ showError }}
          toggleShow={this.toggleShow}
          {...{ isPasswordVisible }}
        />
      );
      onClick = (evt) => this.saveUser(evt);
    }

    return (
      <>
        <SideModal
          closeModal={this.closeSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          open={
            pressedBtn === ADD_ICON ? shouldShowPermissionsModal && open : open
          }
          close={
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? this.goBackPermissionsModal
              : this.hideModal
          }
          {...{ onClick }}
          {...{ dialogTitle }}
          {...{ dialogContent }}
          buttonPurpose={
            pressedBtn === DELETE_ICON ||
            pressedBtn === CLEAR_ICON_SUBTABLE ||
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? t('deleteRecord', { name: '' })
              : t('save')
          }
          subHeader={
            pressedBtn === CLEAR_ICON_SUBTABLE ||
            pressedBtn === CLEAR_ICON_PERMISSION_MODAL
              ? subHeader
              : modalData?.id && (
                  <span className={clsx(classes.flex, classes.marginTop1)}>
                    {[modalData[FIRST_NAME], modalData[LAST_NAME]].join(' ')}
                    {', '}
                    {modalData.username}
                  </span>
                )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          specificDetails={{ className: { actionBtn: classes.isSaveBtn } }}>
          {children}
        </Modal>
        <div className={classes.grid}>
          <SearchInput
            className={classes.gridSearchArea}
            onChange={this.onSearch}
            id="search-input"
            isSearchInactive={!filters.searchBar}
            value={filters.searchBar || ''}
            clearSearch={this.clearSearch}
          />
          <Button
            className={classes.gridButtonArea}
            id="add_new_button"
            onClick={this.showModal}
            isSaveBtn={true}
            label={
              <>
                <span className={classes.plus}>&#x002B;</span>
                {t('addNew')}
              </>
            }
          />
          <Filter
            classes={{
              root: classes.outlined
            }}
            className={classes.gridFilterArea}
            disableClearable
            options={REGISTRAR_COACH}
            onChange={this.selectRole}
            label={t('role')}
            variant={'outlined'}
            item={'label'}
            value={selectedValue(REGISTRAR_COACH, ID, filters.filteredRole)}
          />
        </div>
        {!loading ? (
          <Table
            {...{ recordDataParams, collapsedRecordDataParams, collapsed }}
            data={filteredUsers}
            subTableDataKey="tournaments"
            onClick={this.showModal}
            onSort={this.onSort}
            {...{ sortDirection }}
            id="id"
            // shouldAllowEditing={() => true}
            actionIcons={(item, _, className) => (
              <>
                <Tooltip arrow title={t('updateRecord', { name: t('user') })}>
                  <EditIcon
                    className={clsx(className)}
                    onClick={(evt) => this.showModal(evt, item)}
                  />
                </Tooltip>
                <Tooltip arrow title={t('permissions')}>
                  <AddIcon
                    id={ADD_ICON}
                    className={clsx(className, classes.marginLeft1)}
                    onClick={(evt) => this.showModal(evt, item, null, ADD_ICON)}
                  />
                </Tooltip>
                <Tooltip arrow title={t('deleteRecord', { name: t('user') })}>
                  <DeleteIcon
                    id={DELETE_ICON}
                    className={clsx(className, classes.marginLeft1)}
                    onClick={(evt) =>
                      this.showModal(evt, item, null, DELETE_ICON)
                    }
                  />
                </Tooltip>
              </>
            )}
            // subActionsIcons={(item, _, className, parentValue) => (
            //   <Tooltip title={t('deleteRecord', { name: t('tournament') })}>
            //     <ClearGreyIcon
            //       id={CLEAR_ICON_SUBTABLE}
            //       className={clsx(className)}
            //       onClick={(evt) => this.showModal(evt, item, parentValue)}
            //     />
            //   </Tooltip>
            // )}
            // selected={(item) => {
            //   const id = open && item?.id;

            //   return userId === id;
            // }}
            // selectedSubTable={(item) => {
            //   const id = open && item?.id;

            //   return modalData?.id === id;
            // }}
          />
        ) : (
          <LoadingState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(RegistrarsCoaches));
