import { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GroupIcon } from 'assets/img/participantMode.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Collapse } from '@material-ui/core';

import { isDesktopView, isMobileView } from 'helpers/selectors';

import Radio from '../RadioBtn/RadioBtn';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import Button from '../Buttons/ActionButtons';
import { Clear } from '@material-ui/icons';

import { useStyles } from './Styles';

const DistributeParticipantsByCategoriesComponentComponent = (props) => {
  const {
    filteredData,
    onExpandRow,
    shouldDisableEditing,
    onRadioBtnSelection,
    onSelectPartCheckbox,
    onSelectAllPartCheckboxes,
    selectedParticiantsCategory,
    collapsed,
    onPanelSelection,
    tournamentData,
    activeFiltersPerPanel,
    viewportWidth
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'participant_first_name'
    : 'participant_last_name';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'participant_last_name'
    : 'participant_first_name';

  const mobileScreen = isMobileView(viewportWidth);

  const desktopScreen = isDesktopView(viewportWidth);

  return (
    <div className={clsx(classes.flex, classes.centerHorizontally)}>
      <div className={clsx(classes.flex, classes.column, classes.fullWidth)}>
        <div className={clsx(classes.flex, mobileScreen && classes.column)}>
          {[1, 2].map((panel, panelIdx) => {
            const param_agr = `panel_${panelIdx}`;
            const filtersByPanel = activeFiltersPerPanel[param_agr];

            return (
              <span
                className={clsx(
                  classes.flex,
                  classes.column,
                  classes.spaceBetween,
                  classes.fullWidth,
                  panelIdx === 1 && !mobileScreen && classes.marginLeft1,
                  mobileScreen && classes.marginBottom10
                )}
                key={panel}>
                <span
                  className={clsx(
                    classes.flex,
                    classes.fontSize08,
                    classes.fullWidth,
                    classes.centerVertically,
                    classes.pointer
                  )}>
                  <Button
                    specificallyStyledBtn={clsx(
                      classes.filterBtn,
                      classes.fullWidth
                    )}
                    onClick={() => onPanelSelection(panelIdx)}
                    label={
                      <label
                        className={clsx(
                          classes.flex,
                          classes.fullWidth,
                          classes.centerVertically
                        )}>
                        <span
                          className={clsx(
                            classes.flex,
                            classes.filterSummary,
                            classes.pointer,
                            classes.flexGrow1
                          )}>
                          <span className={classes.marginRight05}>
                            {t(filtersByPanel ? 'filter' : 'applyFilter')}
                            {filtersByPanel && ':'}
                          </span>
                          {Array.isArray(filtersByPanel) &&
                            filtersByPanel.map((filter, idx) => (
                              <span className={classes.marginRight05} key={idx}>
                                {filter.name}
                                {idx !== filtersByPanel.length - 1 ? ',' : ''}
                              </span>
                            ))}
                        </span>
                        {filtersByPanel && (
                          <Clear
                            className={clsx(
                              classes.clearIcon,
                              classes.btnHover
                            )}
                          />
                        )}
                      </label>
                    }
                  />
                </span>

                <span
                  className={clsx(
                    classes.flex,
                    classes.fullHeight,
                    classes.minHeight
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.categoriesPanel,
                      classes.fullWidth
                    )}>
                    {filteredData[param_agr]?.map((category, categoryIdx) => {
                      const findCategoryIdx =
                        selectedParticiantsCategory?.oldData?.findIndex(
                          (it) => +it?.category_id === +category?.id
                        );
                      const findCategoryParts =
                        selectedParticiantsCategory?.oldData?.[findCategoryIdx]
                          ?.participants;

                      const participantsData =
                        collapsed?.[param_agr]?.[category.id];

                      return (
                        <Fragment key={categoryIdx}>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.marginBottom05,
                              classes.centerVertically,
                              classes.categoryWrapper,
                              panelIdx === 1 && classes.paddingLeft05
                            )}>
                            {panelIdx === 1 && (
                              <Radio
                                value={
                                  selectedParticiantsCategory.newCategory || ''
                                }
                                item={`${category.id}`}
                                onClick={(evt) =>
                                  onRadioBtnSelection(evt, category)
                                }
                              />
                            )}
                            <span
                              className={clsx(
                                classes.flex,
                                classes.btnHover,
                                classes.fullHeight,
                                classes.fullWidth
                              )}
                              onClick={(evt) =>
                                onExpandRow(evt, panelIdx, category)
                              }>
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.fullWidth,
                                  classes.centerVertically,
                                  classes.padding065,
                                  classes.categoryFont
                                )}>
                                <span>{category.name}</span>
                                <span
                                  className={clsx(
                                    classes.flex,
                                    classes.alignLeft
                                  )}>
                                  <span
                                    className={clsx(
                                      classes.flex,
                                      classes.marginLeft02,
                                      classes.categoryFont
                                    )}>
                                    {category.participants_count}
                                  </span>

                                  <GroupIcon
                                    className={clsx(
                                      classes.marginLeft02,
                                      classes.grey
                                    )}
                                  />
                                </span>
                              </span>
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.padding05,
                                  classes.centerVertically,
                                  classes.backGroundGrey,
                                  classes.arrowContainer
                                )}>
                                <ArrowDropDownIcon
                                  className={clsx(
                                    collapsed?.[param_agr]?.[category.id]
                                      ?.length > 0
                                      ? classes.rotate180
                                      : undefined
                                  )}
                                />
                              </span>
                            </span>
                          </span>
                          <Collapse
                            className={classes.collaps}
                            in={
                              collapsed?.[param_agr]?.[category.id]?.length > 0
                            }
                            timeout="auto"
                            unmountOnExit>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.marginBottom05,
                                panelIdx === 0
                                  ? classes.marginLeft1
                                  : classes.marginLeft15
                              )}>
                              {panelIdx === 0 && !shouldDisableEditing && (
                                <CheckboxBtn
                                  label={t('all')}
                                  classControlLabel={clsx(
                                    classes.selectAllCheckbox
                                  )}
                                  indeterminate={
                                    findCategoryParts?.length > 0 &&
                                    findCategoryParts?.length <
                                      category?.participants?.length
                                  }
                                  formControlClassName={classes.paper}
                                  checked={
                                    findCategoryParts?.length ===
                                    category?.participants?.length
                                  }
                                  onChange={() =>
                                    onSelectAllPartCheckboxes(category)
                                  }
                                />
                              )}
                              {Array.isArray(participantsData) &&
                                participantsData.map((item, idx) => {
                                  return (
                                    <div
                                      className={clsx(
                                        classes.participant_wrapper,
                                        idx + 1 !== participantsData.length &&
                                          classes.marginBottom5
                                      )}
                                      key={idx}>
                                      <span
                                        className={clsx(
                                          classes.flex,
                                          classes.justifyContent,
                                          classes.fullWidth,
                                          !desktopScreen && classes.column
                                        )}>
                                        <span
                                          className={clsx(
                                            classes.participant_name,
                                            !desktopScreen &&
                                              classes.marginBottom10
                                          )}>
                                          {[
                                            item[FIRST_NAME],
                                            item[LAST_NAME]
                                          ].join(' ')}
                                        </span>
                                        <span
                                          className={clsx(
                                            classes.flex,
                                            classes.spaceBetween,
                                            classes.justifyContent,
                                            classes.fullWidth
                                          )}>
                                          <span>{item.age}</span>
                                          <span>{item.gender}</span>
                                          <span>{item.weight}</span>
                                          <span>{item.qdan_name}</span>
                                        </span>
                                      </span>
                                      <span
                                        className={
                                          panelIdx === 0
                                            ? classes.marginLeft50
                                            : classes.marginLeft80
                                        }>
                                        {panelIdx === 0 &&
                                        !shouldDisableEditing ? (
                                          <CheckboxBtn
                                            classControlLabel={clsx(
                                              classes.alignLeft
                                            )}
                                            className={clsx(
                                              classes.padding0,
                                              classes.marginLeft1
                                            )}
                                            formControlClassName={classes.paper}
                                            checked={
                                              Array.isArray(findCategoryParts)
                                                ? findCategoryParts.some(
                                                    (it) =>
                                                      it.participant_id ===
                                                      item.participant_id
                                                  )
                                                : false
                                            }
                                            onChange={() =>
                                              onSelectPartCheckbox(
                                                item,
                                                category
                                              )
                                            }
                                          />
                                        ) : (
                                          false
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                            </span>
                          </Collapse>
                        </Fragment>
                      );
                    })}
                  </span>
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DistributeParticipantsByCategoriesComponentComponent;
