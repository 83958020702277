import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// import { ReCaptcha } from 'react-recaptcha-google';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { TextField } from '@material-ui/core';

import { ID, EMAIL, NAME, COUNTRY_ID } from '../../helpers/constants';
import { selectedValue, countryLabel } from '../../helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';

import useStyles from './StylesSignUpFormP2';

const SignUp = (props) => {
  const {
    handleChange,
    values,
    countries,
    allRegions,
    fetchRegionBySelectedCountry,
    errors,
    // onLoadRecaptcha,
    // verifyCallback,
    // captchaDemo,
    toggleShow,
    isPasswordVisible,
    selectOption,
    onBlur,
    showError,
    submitForm
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

  const goToSignIn = () =>
    navigate('/sign-in', {
      state: { prevUrl: location.pathname }
    });

  const txtInput = (
    label,
    name,
    onBlur,
    type,
    helperText,
    error,
    required,
    shouldShowIcon,
    className
  ) => (
    <TextField
      className={clsx(classes.asterisk, classes.grey)}
      classes={{
        root: clsx(classes.txtField, classes.fullWidth, classes.grey)
      }}
      {...{ label }}
      {...{ name }}
      onChange={handleChange}
      {...{ onBlur }}
      {...{ type }}
      variant="outlined"
      {...{ error }}
      {...{ helperText }}
      {...{ required }}
      value={values[name] || ''}
      InputProps={
        shouldShowIcon && {
          className: classes.endAdornment,
          endAdornment: (
            <div
              id={name}
              className={clsx(
                classes.grey,
                classes.flex,
                classes.iconWrapper,
                isPasswordVisible[name] && classes.icon
              )}
              onClick={toggleShow}>
              <VisibilityOutlinedIcon className={classes.outlinedIcon} />
            </div>
          )
        }
      }
    />
  );
  const buttonData = [
    {
      label: t('cancel'),
      onClick: goToSignIn,
      className: clsx(classes.marginTop20, classes.appacity)
    },
    {
      label: t('save'),
      onClick: (evt) => submitForm(evt, goToSignIn),
      isSaveBtn: true,
      className: clsx(classes.marginTop20)
    }
  ];
  const buttonComponent = (label, onClick, isSaveBtn, className) => (
    <Button
      {...{ className }}
      {...{ isSaveBtn }}
      {...{ onClick }}
      {...{ label }}
    />
  );

  const inputs = [
    {
      label: !isRuLang ? t('yourFirstName') : t('yourLastName'),
      name: !isRuLang ? 'first_name' : 'last_name',
      error: Boolean(errors.first_name),
      helperText: errors.first_name,
      required: true
    },
    {
      label: !isRuLang ? t('yourLastName') : t('yourFirstName'),
      name: !isRuLang ? 'last_name' : 'first_name',
      error: Boolean(errors.last_name),
      helperText: errors.last_name,
      required: true
    },
    {
      label: EMAIL,
      name: 'email',
      onBlur: onBlur,
      error: showError.email && Boolean(errors.email),
      helperText: showError.email && errors.email,
      required: true
    },
    { country: true },
    allRegions?.length > 0
      ? { region: true }
      : {
          name: 'region',
          label: t('district'),
          error: Boolean(errors.region),
          helperText: errors.region,
          required: true
        },
    {
      label: t('city'),
      name: 'city',
      error: Boolean(errors?.city),
      helperText: errors?.city,
      required: true
    },
    {
      label: t('phone'),
      name: 'phone',
      error: Boolean(errors?.phone),
      helperText: errors?.phone
    },
    {
      label: !isRuLang ? t('coachFirstName') : t('coachLastName'),
      name: !isRuLang ? 'coach_first_name' : 'coach_last_name',
      error: Boolean(errors.coach_first_name),
      helperText: errors.coach_first_name
    },
    {
      label: !isRuLang ? t('coachLastName') : t('coachFirstName'),
      name: !isRuLang ? 'coach_last_name' : 'coach_first_name',
      error: Boolean(errors.coach_last_name),
      helperText: errors.coach_last_name
    },
    {
      label: t('cluborFederationName'),
      name: 'club_federation',
      error: Boolean(errors.club_federation),
      helperText: errors.club_federation,
      required: true
    },
    {
      label: t('nameBranchChief'),
      name: 'branch_chief',
      error: Boolean(errors.branch_chief),
      helperText: errors.branch_chief,
      required: true
    },
    {
      label: t('password'),
      name: 'password',
      onBlur: onBlur,
      type: isPasswordVisible.password ? 'text' : 'password',
      error: showError.password && Boolean(errors.password),
      helperText: showError.password && errors.password,
      required: true,
      shouldShowIcon: true
    },
    {
      label: t('confirmPassword'),
      name: 'confirm_password',
      onBlur: onBlur,
      type: isPasswordVisible.confirm_password ? 'text' : 'password',
      error: showError.confirm_password && Boolean(errors.confirm_password),
      helperText: showError.confirm_password && errors.confirm_password,
      required: true,
      shouldShowIcon: true
    }
  ];

  const filter = (
    label,
    options,
    attribute,
    value,
    key,
    item,
    className,
    error,
    helperText,
    required,
    cb,
    freeSolo,
    additionalItem
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      value,
      attribute === COUNTRY_ID
    );

    return (
      <Filter
        className={clsx(
          classes.txtField,
          className,
          required && classes.asterisk
        )}
        {...{ options }}
        {...{ label }}
        value={currentVal || ''}
        onChange={(evt, val) =>
          selectOption(evt, val, 'singUpForm', attribute, key, errors, cb)
        }
        {...{ item }}
        variant="outlined"
        {...{ required }}
        {...{ error }}
        {...{ helperText }}
        id={attribute}
      />
    );
  };

  return (
    <div className={classes.background}>
      <span className={classes.fontSize20}>{t('createAccount')}</span>
      <div className={classes.inputContainer}>
        {inputs.map(
          (
            {
              label,
              name,
              onBlur,
              type,
              helperText,
              error,
              required,
              shouldShowIcon,
              className,
              region,
              country
            },
            idx
          ) => (
            <Fragment key={idx}>
              {!country &&
                !region &&
                txtInput(
                  label,
                  name,
                  onBlur,
                  type,
                  helperText,
                  error,
                  required,
                  shouldShowIcon,
                  className
                )}
              {country &&
                filter(
                  t('country'),
                  countries,
                  COUNTRY_ID,
                  values.country_id,
                  ID,
                  {
                    firstName: 'iso3',
                    lastName: countryLabel(localStorage.getItem('i18nextLng')),
                    parathesis: true
                  },
                  className,
                  Boolean(errors.country_id),
                  errors.country_id,
                  true,
                  (val) =>
                    fetchRegionBySelectedCountry &&
                    fetchRegionBySelectedCountry(val)
                )}
              {region &&
                filter(
                  t('district'),
                  allRegions,
                  'region',
                  values.region,
                  NAME,
                  NAME,
                  className,
                  Boolean(errors?.region),
                  errors?.region,
                  true,
                  null,
                  true,
                  { name: values.region }
                )}
            </Fragment>
          )
        )}
        {buttonData.map((it, idx) => (
          <Fragment key={idx}>
            {buttonComponent(it.label, it.onClick, it.isSaveBtn, it.className)}
          </Fragment>
        ))}
        {/* <ReCaptcha
            ref={captchaDemo}
            size="normal"
            render="explicit"
            sitekey="6Lc2Y9IUAAAAAFXPO7T7QIK-XXvYm3-SRTJnjK0r"
            onloadCallback={onLoadRecaptcha}
            verifyCallback={verifyCallback}
          /> */}
      </div>
    </div>
  );
};

export default SignUp;
