import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Breadcrumbs, Link, Typography } from '@material-ui/core';

import {
  DISTRIBUTE_PARTICIPANTS_TAB,
  REASSIGN_CATEGORIES_TAB,
  CATEGORIES_TAB,
  TATAMIS_TAB
} from 'helpers/constants';

const routes = (t, role) => [
  { path: '/', breadcrumb: t('home') },
  { path: '/account', breadcrumb: t('profile') },
  { path: '/more-apps', breadcrumb: t('moreApps') },
  {
    path: '/participants',
    breadcrumb: role === 'administrator' ? t('athletes') : t('myTeam')
  },
  { path: '/events', breadcrumb: t('events') },
  {
    path: '/event/:id/participants',
    breadcrumb: t('participants')
  },
  {
    path: '/event/:id/registration',
    breadcrumb: t('registrationN')
  },
  {
    path: '/event/:id/select-attachment',
    breadcrumb: t('documents')
  },
  {
    path: '/event/:id/coaches_requests',
    breadcrumb: t('approveRegistration')
  },
  {
    path: '/event/:id/judges_requests',
    breadcrumb: t('approveJudges')
  },
  {
    path: '/event/:id/staff',
    breadcrumb: t('judges')
  },
  {
    path: '/event/:id/edit',
    breadcrumb: t('edit'),
    isEditEvent: true
  },
  {
    path: '/event/add',
    breadcrumb: t('event')
  },
  {
    path: '/event/:id/reports',
    breadcrumb: t('reports')
  },
  {
    path: '/event/:id/winners',
    breadcrumb: t('winners')
  },
  { path: '/categories', breadcrumb: t('categories') },
  { path: '/event/:id', breadcrumb: t('event') },
  {
    path: '/event/:id/event-setting'
  },
  { path: '/users', breadcrumb: t('users') },
  { path: '/organisation', breadcrumb: t('myOrganisation') },
  { path: 'participant/:id/payments', breadcrumb: t('payments') },
  { path: '/reference', breadcrumb: t('reference') },
  { path: '/logs', breadcrumb: t('logs') },
  { path: '/register_to_tournament', breadcrumb: t('registerToTourn') }
];

const matchPath = (pathname, routePath) => {
  const routeSegments = routePath.split('/').filter(Boolean);
  const pathSegments = pathname.split('/').filter(Boolean);

  if (routeSegments.length !== pathSegments.length) return false;

  return routeSegments.every((segment, idx) => {
    return segment.startsWith(':') || segment === pathSegments[idx];
  });
};

const findBreadcrumb = (path, t, role) => {
  return routes(t, role).find((route) => matchPath(path, route.path));
};

const Breadcrumb = ({ specificDetails, role }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathnames = location.pathname.split('/').filter((x) => x);

  const goBack = () => {
    localStorage.removeItem('invitation_tournId');
    localStorage.removeItem('invitation_token');
  };

  return (
    <Breadcrumbs
      className={specificDetails?.styles?.wrapper}
      separator={
        !specificDetails?.hasMobileView && <NavigateNextIcon fontSize="small" />
      }
      aria-label="breadcrumb">
      {!specificDetails?.hasMobileView && (
        <Link
          component={RouterLink}
          onClick={
            location.pathname.includes('/register_to_tournament') ? goBack : undefined
          }
          to="/"
          style={{ color: '#8996AC' }}>
          {t('home')}
        </Link>
      )}
      {pathnames.map((_, index) => {
        const url = `/${pathnames.slice(0, index + 1).join('/')}`;
        const breadcrumb = findBreadcrumb(url, t, role, index);
        if (breadcrumb) {
          const isLast = index === pathnames.length - 1;
          let breadcrumbName = breadcrumb.breadcrumb;

          if (breadcrumb?.path.includes('/event-setting')) {
            const searchParams = new URLSearchParams(location.search);
            const tab = searchParams.get('tab') ?? TATAMIS_TAB;

            switch (tab) {
              case TATAMIS_TAB:
                breadcrumbName = t('assignTatami');
                break;
              case CATEGORIES_TAB:
                breadcrumbName = t('assignCategories');
                break;
              case DISTRIBUTE_PARTICIPANTS_TAB:
                breadcrumbName = t('distributeParticipantsByCategories');
                break;
              case REASSIGN_CATEGORIES_TAB:
                breadcrumbName = t('distributeCategories');
                break;
              default:
                breadcrumbName = [t('tatami'), t('categories')].join('/');
            }
          } else if (url.match(/^\/event\/(\d+)\/participants$/)?.[1]) {
            const searchParams = new URLSearchParams(location.search);
            const tab = searchParams.get('tab') ?? 1;

            switch (tab) {
              case '1':
                breadcrumbName = t('listOfParticipants');
                break;
              case '2':
                breadcrumbName = t('categoriesList');
                break;
              default:
                breadcrumbName = t('participants');
            }
          }

          return isLast ? (
            <Typography key={url} style={{ color: '#262441' }}>
              {breadcrumbName}
            </Typography>
          ) : (
            !specificDetails?.hasMobileView && (
              <Link
                key={url}
                component={RouterLink}
                to={url}
                style={{
                  color: '#8996AC'
                }}>
                {breadcrumbName}
              </Link>
            )
          );
        }
        return null;
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
