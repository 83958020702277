import { Fragment, useState } from 'react';
import { ro, ru, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { TextField, List, ListItem } from '@material-ui/core';

import { ReactComponent as DateIcon } from 'assets/img/date.svg';
import { ReactComponent as Clear } from 'assets/img/new_x_grey.svg';
import { ReactComponent as DropdownGreyBg } from 'assets/img/dropdown_white_bg&grey_border.svg';

import {
  ID,
  GENDER_LABEL,
  GENDER,
  NAME,
  DATE_PLACEHOLDER,
  COUNTRY_ID,
  QDAN,
  AVATAR,
  NEXT_QDAN,
  QDAN_EXAM,
  ADD_CATEGORIES
} from 'helpers/constants';
import { fetchTournamnetInformation } from 'helpers/util';
import {
  selectedValue,
  countryLabel,
  isSmallScreen,
  DDMMYYYY,
  isSchool
} from 'helpers/selectors';

import CheckboxBtn from '../../CheckboxBtn/CheckboxBtn';
import Button from '../../Buttons/ActionButtons';
import UploadImage from '../../UploadImage/UploadImage';
import Filter from '../../Filter/Filter';
import Places2Win from 'components/Places2Win/Places2Win';

import { useStyles } from './Styles';

const ParticipantForm = (props) => {
  const [toggled, setToggled] = useState({ isToggled: false, value: [] });
  const {
    countries,
    allRegions,
    fetchRegionBySelectedCountry,
    changeTxt,
    values,
    selectOption,
    changeImage,
    errors,
    param,
    qdanList,
    changeDate,
    saveParticipant,
    componentElems,
    personalAssignedCategories,
    tournamentData,
    tournamentIdParams,
    partFirstLastNameOrder,
    openRegistration,
    isFormWidthSet,
    selectCheckboxSchool,
    openAssignFormInsideParticipantForm,
    onRemoveCategoriesInForm,
    all_sport_rank
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const isTraining = isSchool(tournamentData);
  const currentLang = localStorage.getItem('i18nextLng');
  const role = localStorage.getItem('role');
  const isRuLang = currentLang === 'ru';

  const COACH_FIRST_NAME_VAL =
    (tournamentIdParams && !+tournamentData?.last_name_first) ||
    (!tournamentIdParams && !isRuLang)
      ? 'coach_first_name'
      : 'coach_last_name';

  const COACH_LAST_NAME_VAL =
    (tournamentIdParams && !+tournamentData?.last_name_first) ||
    (!tournamentIdParams && !isRuLang)
      ? 'coach_last_name'
      : 'coach_first_name';

  const COACH_FIRST_NAME_LABEL =
    (tournamentIdParams && !+tournamentData?.last_name_first) ||
    (!tournamentIdParams && !isRuLang)
      ? t('coachFirstName')
      : t('coachLastName');

  const COACH_LAST_NAME_LABEL =
    (tournamentIdParams && !+tournamentData?.last_name_first) ||
    (!tournamentIdParams && !isRuLang)
      ? t('coachLastName')
      : t('coachFirstName');

  const inputsData = [
    [
      {
        name: partFirstLastNameOrder?.firstNameVal,
        label: partFirstLastNameOrder?.firstNameLabel,
        disabled: role === 'recorder'
      },
      {
        name: partFirstLastNameOrder?.lastNameVal,
        label: partFirstLastNameOrder?.lastNameLabel,
        disabled: role === 'recorder'
      }
    ],
    ...(!tournamentData || !!+tournamentData?.show_reg_patronymic
      ? [
          {
            name: 'patronymic',
            label: t('patronymic'),
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || (tournamentData && +tournamentData.type === 2)
      ? [
          [
            {
              name: partFirstLastNameOrder?.firstNameNationalVal,
              label: partFirstLastNameOrder?.firstNameNationalLabel,
              disabled: role === 'recorder'
            },
            {
              name: partFirstLastNameOrder?.lastNameNationalVal,
              label: partFirstLastNameOrder?.lastNameNationalLabel,
              disabled: role === 'recorder'
            }
          ]
        ]
      : []),

    {
      gender: true,
      isFilter: true,
      disabled: role === 'recorder'
    },
    [
      {
        birthday: true,
        isDate: true
      },
      {
        name: 'age',
        label: t('age'),
        int: true,
        disabled: role === 'recorder'
      }
    ],
    {
      name: 'weight',
      label: t('weight'),
      int: true
    },
    ...(!tournamentData || // for "My Team"
    !!+tournamentData?.height_needed // for "Participants"
      ? [
          {
            name: 'height',
            label: t('height'),
            int: true
          }
        ]
      : []),
    ...(!tournamentData || !!+tournamentData?.show_reg_qdan
      ? [
          {
            kdan: true,
            isFilter: true,
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || //show sport rank in "MyTeam" despite sport_rank_needed
    !!+tournamentData?.sport_rank_needed //show sport rank in "Participants" depending on sport_rank_needed
      ? [
          {
            sport_rank: true,
            isFilter: true,
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || !!+tournamentData?.show_reg_iko
      ? [
          {
            name: 'iko',
            label: t('ikoCard'),
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || !+tournamentData?.no_country
      ? [
          {
            country: true,
            isFilter: true,
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || !!+tournamentData?.show_reg_region
      ? [
          allRegions?.length > 0
            ? {
                region: true,
                isFilter: true,
                disabled: role === 'recorder'
              }
            : {
                name: 'region',
                label: t('district'),
                disabled: role === 'recorder'
              }
        ]
      : []),
    ...(!tournamentData || !!+tournamentData?.show_reg_city
      ? [
          {
            name: 'city',
            label: t('city'),
            disabled: role === 'recorder'
          }
        ]
      : []),
    ...(!tournamentData || !!+tournamentData?.show_reg_club
      ? [
          {
            name: 'club',
            label: t('cluborFederationName'),
            disabled: role === 'recorder'
          }
        ]
      : []),
    [
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL,
        disabled: role === 'recorder'
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL,
        disabled: role === 'recorder'
      }
    ],
    ...(!!+tournamentData?.show_reg_coach_2 ||
    (!tournamentData && role === 'administrator')
      ? [
          [
            {
              name: [COACH_FIRST_NAME_VAL, '_2'].join(''),
              label: [COACH_FIRST_NAME_LABEL, '/2'].join(''),
              disabled: role === 'recorder'
            },
            {
              name: [COACH_LAST_NAME_VAL, '_2'].join(''),
              label: [COACH_LAST_NAME_LABEL, '/2'].join(''),
              disabled: role === 'recorder'
            }
          ]
        ]
      : []),
    {
      name: 'history',
      label: t('bestResults'),
      rows: true,
      className: classes.span2and3,
      disabled: role === 'recorder'
    },
    ...(componentElems?.tournamentsList
      ? [{ tournamentsList: true, className: classes.span2and3 }]
      : []),
    { categories: true },
    ...(openRegistration
      ? [
          ...(Array.isArray(personalAssignedCategories) &&
          !isTraining &&
          role !== 'recorder'
            ? [{ assign: true }]
            : []),
          { save: true, className: classes.saveBtn }
        ]
      : [])
  ];

  const filter = (
    label,
    options,
    attribute,
    value,
    key,
    item,
    className,
    error,
    helperText,
    disabled,
    cb,
    freeSolo,
    additionalItem
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      value,
      attribute === COUNTRY_ID || attribute === 'sport_rank'
    );

    return (
      <Filter
        className={clsx(
          classes.maxHeightAutocomplete,
          !error && classes.textField,
          className
        )}
        {...{ options }}
        {...{ label }}
        value={currentVal || ''}
        onChange={(evt, val) =>
          selectOption(evt, val, param, attribute, key, errors, cb)
        }
        {...{ item }}
        variant="outlined"
        {...{ error }}
        {...{ helperText }}
        {...{ disabled }}
        id={attribute}
        fullWidth
        focused
        placeholder={t('selectValue')}
      />
    );
  };

  const schoolComponent = () => (
    <>
      <CheckboxBtn
        checked={values.training}
        className={classes.checkboxPaddingLeft}
        onChange={(evt) => selectCheckboxSchool(evt, values, param, 'training')}
        label={t('training')}
      />
      <span className={clsx(classes.flex)}>
        <CheckboxBtn
          checked={values.nextQdan}
          classControlLabel={classes.labelCheckbox}
          onChange={(evt) =>
            selectCheckboxSchool(evt, values, param, NEXT_QDAN)
          }
          disabled={!values.training}
        />
        {filter(
          t('nextKyuDan'),
          qdanList,
          QDAN_EXAM,
          values.next_qdan_id,
          ID,
          NAME,
          null,
          Boolean(errors.next_qdan_id),
          errors.next_qdan_id,
          !values.training || !values.nextQdan
        )}
      </span>
    </>
  );

  const categoriesComponent = () => (
    <span className={classes.assignedCategoriesWrapper}>
      <h3>{t('categories')}</h3>
      <List className={classes.listWrapper}>
        {personalAssignedCategories.map((it, idx) => (
          <ListItem
            key={idx}
            className={clsx(classes.listRecord, classes.padding08)}>
            <span
              className={clsx(classes.marginRight, it.issue && classes.issue)}>
              {!!+it.team_id && `(${it.team_id}) `}
              {it.name}
            </span>
            {role !== 'recorder' && openRegistration && (
              <Clear
                className={clsx(classes.buttonsBorder, classes.onHover)}
                onClick={() => onRemoveCategoriesInForm(it, idx)}
              />
            )}
          </ListItem>
        ))}
      </List>
    </span>
  );

  const inputsComponent = (it, idx) => (
    <Fragment key={idx}>
      {!it.isFilter &&
        !it.isDate &&
        !it.categories &&
        !it.save &&
        !it.tournamentsList &&
        !it.assign && (
          <TextField
            value={values[it.name] || ''}
            name={it.name}
            label={it.label}
            className={clsx(
              it.rows
                ? classes.maxHeightTxtInputMultipleline
                : classes.maxHeightTxtInput,
              classes.ellipsis,
              !errors[it.name] && classes.textField,
              it.disabled && classes.noEvent,
              it.className
            )}
            variant="outlined"
            inputProps={{
              ...(it.name === 'region' ? { autoComplete: 'off' } : {}),
              ...(!!+values.duplicate &&
              (it.name === 'first_name' ||
                it.name === 'last_name' ||
                it.name === 'patronymic')
                ? { style: { color: 'red' } }
                : {})
            }}
            multiline={it.rows}
            required={it.required}
            onChange={(evt) =>
              changeTxt(
                evt,
                param,
                errors,
                it.int,
                null,
                null,
                tournamentData,
                null,
                null,
                values,
                values?.['id' ?? 'participant_id']
              )
            }
            error={errors && Boolean(errors[it.name])}
            helperText={errors && errors[it.name]}
            fullWidth
            focused
            placeholder={t('enterValue')}
          />
        )}
      {it.isDate && (
        <>
          {it.birthday && (
            <MuiPickersUtilsProvider
              locale={[ru, ro, de].find((it) => it.code === currentLang)}
              utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={clsx(
                  classes.maxHeightTxtInput,
                  !errors['birthday'] && classes.textField,
                  it.className
                )}
                fullWidth
                keyboardIcon={<DateIcon />}
                cancelLabel={t('cancel')}
                okLabel={t('apply')}
                value={values.birthdayInputDate || null}
                onChange={changeDate}
                placeholder={DATE_PLACEHOLDER}
                format="dd/MM/yyyy"
                label={t('birthday')}
                inputVariant="outlined"
                error={Boolean(errors.birthday)}
                helperText={errors.birthday}
                focused
              />
            </MuiPickersUtilsProvider>
          )}
        </>
      )}
      {it.isFilter && (
        <>
          {it.sport_rank &&
            filter(
              t('sportsCategory'),
              all_sport_rank,
              'sport_rank',
              values.sport_rank,
              ID,
              {
                firstName: 'shortName',
                lastName: NAME,
                parathesis: true
              },
              [it.className, it.disabled && classes.noEvent]
            )}
          {it.region &&
            filter(
              t('district'),
              allRegions,
              'region',
              values.region,
              NAME,
              NAME,
              [it.className, it.disabled && classes.noEvent],
              Boolean(errors?.region),
              errors?.region,
              null,
              null,
              true,
              { name: values.region }
            )}
          {it.gender &&
            filter(
              t('gender'),
              GENDER,
              GENDER_LABEL,
              values.gender,
              NAME,
              NAME,
              [it.className, it.disabled && classes.noEvent],
              Boolean(errors.gender),
              errors.gender
            )}
          {it.kdan &&
            filter(t('kyuDan'), qdanList, QDAN, values.qdan, ID, NAME, [
              it.className,
              it.disabled && classes.noEvent
            ])}
          {it.country &&
            filter(
              t('country'),
              countries,
              COUNTRY_ID,
              values.country_id,
              ID,
              {
                firstName: 'iso3',
                lastName: countryLabel(currentLang),
                parathesis: true
              },
              [it.className, it.disabled && classes.noEvent],
              null,
              null,
              null,
              (val) =>
                fetchRegionBySelectedCountry &&
                fetchRegionBySelectedCountry(val)
            )}
        </>
      )}

      {it.categories &&
        tournamentData &&
        tournamentData.type &&
        (isTraining ? (
          isSmallScreen(isFormWidthSet) ? (
            schoolComponent()
          ) : (
            <span className={clsx(classes.flex)}>{schoolComponent()}</span>
          )
        ) : (
          Array.isArray(personalAssignedCategories) && categoriesComponent()
        ))}

      {it.assign ||
        (it.save && (
          <>
            <Button
              id={ADD_CATEGORIES}
              className={clsx(classes.formSave)}
              onClick={(evt) =>
                openAssignFormInsideParticipantForm(evt, values)
              }
              label={[t('assign'), t('categories')].join(' ')}
              isSaveBtn
            />
            <Button
              label={t('save')}
              onClick={saveParticipant}
              className={clsx(classes.formSave, it.className)}
              isSaveBtn
            />
          </>
        ))}

      {it.tournamentsList && (!!+values?.participant_id || !!+values?.id) && (
        <span className={clsx(classes.span2and3)}>
          <Button
            label={
              <span
                className={clsx(
                  classes.flex,
                  classes.fullWidth,
                  classes.centerVertically
                )}>
                {t('tournamentInfo')}
                <DropdownGreyBg
                  className={clsx(
                    classes.leftAuto,
                    toggled?.isToggled && classes.rotate180
                  )}
                />
              </span>
            }
            onClick={showTournamentInformation}
            className={clsx(
              classes.tournamentsListElem,
              classes.centerVertically,
              it.className
            )}
          />
          {toggled?.isToggled &&
            (toggled?.value?.length > 0 ? (
              toggled?.value?.map((tourn, tournIdx) => {
                return (
                  <div
                    key={tournIdx}
                    className={classes.tournamentsListItemWrapper}>
                    <span
                      className={clsx(
                        classes.tournamentsListItem,
                        classes.centerVertically
                      )}>
                      <span>{tourn.tournament_name}</span>
                      <span>{DDMMYYYY(tourn.tournament_date)}</span>
                    </span>
                    <div
                      className={clsx(
                        classes.tournamentsListItemCategoriesWrapper
                      )}>
                      {tourn?.categories?.map((cat, catIdx) => {
                        return (
                          <div
                            className={clsx(
                              classes.tournamentsListCategory,
                              classes.centerVertically
                            )}
                            key={catIdx}>
                            {catIdx + 1}. {cat.category_name}
                            {cat.place && (
                              <Places2Win
                                className={{ wrapper: classes.places2Win }}
                                item={cat}
                                paramName="place"
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                {t('noTournaments')}
              </span>
            ))}
        </span>
      )}
    </Fragment>
  );

  const showTournamentInformation = () => {
    fetchTournamnetInformation(
      values,
      (data) => {
        setToggled((prev) => {
          return { isToggled: !prev?.isToggled, value: data };
        });
      },
      () => {
        console.error();
      }
    );
  };

  return (
    <div
      className={clsx(classes.paper, classes.gridForm, classes.marginBottom)}>
      <div className={classes.formAvatarWrapper}>
        <div
          className={clsx(classes.fullWidth, classes.formAvatarInnerWrapper)}>
          <UploadImage
            id={`${param}-file`}
            className={clsx(
              !openRegistration && classes.noEvent,
              classes.formAvatar
            )}
            onChange={(evt) => changeImage(evt, param, AVATAR, 'imagePreview')}
            img={values.imagePreview}
          />
          {componentElems?.topElem}
          {componentElems?.rateElem}
        </div>
      </div>
      {inputsData.map((it1, itIdx1) =>
        Array.isArray(it1) ? (
          <div key={itIdx1} className={classes.pairInputs}>
            {it1.map((it2, itIdx2) => (
              <div
                key={itIdx2}
                className={clsx(classes.flex, classes.fullWidth)}>
                {inputsComponent(it2, itIdx2)}
              </div>
            ))}
          </div>
        ) : (
          inputsComponent(it1, itIdx1)
        )
      )}
    </div>
  );
};
export default ParticipantForm;
