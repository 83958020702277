import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { selectedValue } from '../../helpers/selectors';
import { FORM, ID } from '../../helpers/constants';
import { TextField } from '@material-ui/core';
import Filter from '../../components/Filter/Filter';
import { useStyles } from './Styles';

const Form = (props) => {
  const {
    onChange,
    form,
    errors,
    selectOption,
    onBlur,
    showError,
    toggleShow,
    isPasswordVisible,
    registrarCoachOptions
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

  const STATUS = [
    { id: '1', label: t('active') },
    { id: '3', label: t('inactive') }
  ];
  const txtData = [
    {
      name: !isRuLang ? 'first_name' : 'last_name',
      label: t(!isRuLang ? 'firstname' : 'lastname'),
      gridArea: 'firstname'
    },
    {
      name: !isRuLang ? 'last_name' : 'first_name',
      label: t(!isRuLang ? 'lastname' : 'firstname'),
      gridArea: 'lastname'
    },
    { name: 'username', label: t('username'), gridArea: 'username' },
    { name: 'password', label: t('password'), gridArea: 'password' }
  ];
  const filterData = [
    {
      options: registrarCoachOptions,
      label: t('role'),
      name: 'role_id',
      error: Boolean(errors.role_id),
      helperText: Boolean(errors.role_id) && errors.role_id,
      gridArea: 'role'
    },
    form.id && {
      options: STATUS,
      label: t('status'),
      name: 'status_id',
      error: Boolean(errors.status_id),
      helperText: Boolean(errors.status_id) && errors.status_id,
      gridArea: 'status'
    }
  ];

  return (
    <div className={classes.grid}>
      {txtData.map((it, itIdx) => {
        const isPasswordType = it.name === 'password';

        return (
          it && (
            <div key={itIdx}>
              <TextField
                className={clsx(classes.maxHeight, classes.gridTextArea)}
                style={{ gridArea: it.gridArea }}
                name={it.name}
                key={it.name}
                label={it.label}
                {...{ onBlur }}
                fullWidth
                inputProps={{
                  autoComplete: 'new-password'
                }}
                type={
                  isPasswordType && !isPasswordVisible ? 'password' : 'text'
                }
                {...{ onChange }}
                value={form[it.name] || ''}
                error={Boolean(errors[it.name]) && showError[it.name]}
                disabled={it.name === 'username' && !!form?.id}
                helperText={
                  Boolean(errors[it.name]) &&
                  showError[it.name] &&
                  errors[it.name]
                }
                variant="outlined"
                InputProps={
                  isPasswordType || (it.name === 'username' && !form?.id)
                    ? {
                        className: classes.endAdornment,
                        endAdornment: (
                          <div
                            className={clsx(
                              isPasswordType
                                ? [
                                    classes.iconWrapper,
                                    isPasswordVisible && classes.eyeIcon
                                  ]
                                : classes.marginLR
                            )}
                            onClick={isPasswordType ? toggleShow : undefined}>
                            {isPasswordType ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              `@${localStorage.getItem('user_id')}`
                            )}
                          </div>
                        )
                      }
                    : {}
                }
              />
            </div>
          )
        );
      })}
      {filterData.map(
        (it) =>
          it && (
            <Filter
              className={clsx(classes.maxHeight, classes.gridFilterArea)}
              key={it.name}
              style={{ gridArea: it.gridArea }}
              options={it.options}
              label={it.label}
              value={selectedValue(it.options, ID, form[it.name]) || ''}
              onChange={(evt, value) =>
                selectOption(evt, value, FORM, it.name, ID, errors, null, true)
              }
              item="label"
              error={it.error}
              helperText={it.helperText}
              variant="outlined"
            />
          )
      )}
    </div>
  );
};
export default Form;
