import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },

  tournamentsListElem: {
    background: '#E9ECF6',
    color: '#262441',
    fontSize: '1.08rem',
    height: '2.45em',
    padding: '0.2rem 1rem',
    marginBottom: '1rem',
    border: 'none',
    minHeight: '2.625em'
  },

  span2and3: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  tournamentsListItemWrapper: {
    display: 'grid',
    marginBottom: '1rem',
    border: '1px solid #8996AC',
    borderRadius: '0.3rem'
  },

  tournamentsListItem: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridTemplateColumns: '1fr 5.5em'
    },
    background: '#8996AC',
    padding: '0.5rem 1rem',
    color: '#fff',
    borderTopLeftRadius: '0.3rem',
    borderTopRightRadius: '0.3rem',
    minHeight: '2.625em'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  gridForm: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: '0.5rem'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '0.5rem'
    }
  },

  formAvatar: {
    '@media (min-width:1024px)': {
      gridRow: 'span 7',
      width: '16em',
      height: '18.5em'
    },
    '@media (max-width:1023px)': {
      gridRow: 'span 2',
      height: '8.5em',
      width: '7em',
      justifySelf: 'center'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem'
    }
  },

  fullWidth: {
    width: '100%'
  },

  pairInputs: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 2',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.5rem'
    },
    '@media (max-width:1023px)': {
      display: 'contents'
    }
  },

  formSave: {
    marginBottom: '1.5rem',
    height: '3.35em',
    width: '100%'
  },

  saveBtn: {
    '@media (min-width:1024px)': {
      gridColumnStart: '4'
    },
    '@media (max-width:676px)': {
      marginTop: '1rem'
    }
  },

  inputHeight: {
    height: '5em'
  }
}));
