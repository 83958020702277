import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  paper: {
    borderRadius: '5px',
    backgroundColor: '#fff'
  },

  column: {
    flexDirection: 'column'
  },

  blockWrapper: {
    background: '#fff',
    padding: '0.3rem 1rem 1rem 1rem',
    marginTop: '0.5rem',
    borderRadius: '10px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  tatamiBlock: {
    width: '12em',
    height: '4em',
    justifyContent: 'center',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 10%), 0 1px 3px 0 rgb(63 63 68 / 20%)'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  tatami: {
    position: 'relative',
    borderRadius: '2rem',
    marginTop: '0.4rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  verticallyCentered: {
    alignItems: 'center'
  },

  left: {
    marginLeft: 'auto'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginRight0And5: {
    marginRight: '0.5rem'
  },

  marginLeft0And5: {
    marginLeft: '0.9rem'
  },

  btn: {
    height: '2.2em',
    width: '2.2em',
    padding: 0,
    borderRadius: '4px'
  },

  badge: {
    background: '#005FEE',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    padding: '0.35em 0.65em',
    fontSize: '.75em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    whiteSpace: 'nowrap',
    borderRadius: '6px',
    left: '80%',
    top: 0,
    zIndex: 1
  },

  relative: {
    position: 'relative'
  },

  weight600: {
    fontWeight: 600,
    fontSize: '16px'
  },

  grid: {
    display: 'grid',
    gridGap: '14px',
    '@media (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr));'
    },

    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
    },

    '@media (max-width: 767px)': {
      gridTemplateRows: '1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  },

  tatamiWrapper: {
    height: '70px',
    borderRadius: '10px'
  },

  updateTatami: {
    padding: 0,
    height: '30px',
    width: '30px',
    paddingBottom: '2px',
    color: '#fff',
    border: '0.1rem solid rgba(255, 255, 255, 0.2)',
    borderRadius: ' 0.2rem'
  },

  spaceBetween: {
    justifyContent: 'spaceBetween'
  },

  padding08: {
    padding: '0 8px'
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA',
    cursor: 'pointer',
    marginLeft: '1rem'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  grey: {
    color: '#8996AC'
  },

  margin01: {
    margin: '0 1rem'
  },

  filterWrapper: {
    minWidth: '15em'
  },

  fullWidth: {
    width: '100%'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  }
}));

export const tatamiStyle = (bg) => ({
  color: '#fff',
  background: bg || '#8996AC'
});
