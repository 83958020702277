import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Tooltip, IconButton } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import videoIc from '../../assets/img/videoIc.svg';
import { ReactComponent as PencilEdit } from 'assets/img/pencil_edit_grey.svg';
import Filter from '../Filter/Filter';
import { ID, NAME } from '../../helpers/constants';

import Button from '../Buttons/ActionButtons';

import { useStyles, tatamiStyle } from './Styles';

export default ({
  tatamis,
  addTatamis,
  shouldDisableEditing,
  openEditTatamiForm,
  tatamisTheme,
  lastBlockWithSelection,
  onAddBlock,
  onRemoveBlock,
  allBlockName,
  smallScreen,
  selectedValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let tatamiCounter = 0;

  return (
    <>
      {Array.isArray(tatamis) &&
        tatamis.map((tatami, tatamiIdx) => {
          const findPickedTatamiInBlock =
            Array.isArray(tatami) && tatami?.find((it) => !!+it.is_picked);
          const blockName = allBlockName?.[`block_name_${tatamiIdx}`];
          return (
            +tatami[0]?.tatami_block <= lastBlockWithSelection && (
              <span
                key={tatamiIdx}
                className={clsx(
                  classes.flex,
                  classes.marginTop1,
                  classes.column
                )}>
                {tatamiIdx === 0 && (
                  <span
                    className={clsx(
                      classes.flex,
                      classes.verticallyCentered,
                      smallScreen && classes.column
                    )}>
                    {!shouldDisableEditing && (
                      <div
                        className={clsx(
                          classes.flex,
                          classes.verticallyCentered,
                          classes.marginBottom1,
                          smallScreen && classes.fullWidth
                        )}>
                        <span className={classes.weight600}>
                          {t('addDeleteBlock')}
                        </span>
                        <Button
                          className={clsx(
                            classes.btn,
                            classes.marginBottom,
                            classes.marginLeft0And5
                          )}
                          label={<RemoveIcon />}
                          onClick={onRemoveBlock}
                          disabled={lastBlockWithSelection === 0}
                          whiteBackground
                        />
                        <Button
                          className={clsx(
                            classes.btn,
                            classes.marginBottom,
                            classes.marginLeft0And5
                          )}
                          label={<AddIcon />}
                          onClick={onAddBlock}
                          disabled={
                            tatamis.length - 1 === lastBlockWithSelection
                          }
                          whiteBackground
                        />
                      </div>
                    )}
                  </span>
                )}
                <span
                  className={clsx(
                    classes.flex,
                    classes.blockWrapper,
                    classes.column
                  )}>
                  <span className={clsx(classes.flex, classes.column)}>
                    <span
                      className={clsx(
                        classes.flex,
                        classes.verticallyCentered,
                        classes.marginBottom1,
                        classes.weight600
                      )}>
                      <span className={classes.grey}>
                        {t('block', { count: tatamiIdx })}
                        {findPickedTatamiInBlock?.day &&
                          ` / ${t('day')} ${findPickedTatamiInBlock.day}`}
                      </span>
                      {!shouldDisableEditing && findPickedTatamiInBlock && (
                        <Tooltip arrow title={t('edit')}>
                          <PencilEdit
                            onClick={() => {
                              openEditTatamiForm('EDIT_BLOCK', {
                                ...findPickedTatamiInBlock
                              });
                            }}
                            className={clsx(classes.radiusIc, classes.pointer)}
                          />
                        </Tooltip>
                      )}
                      {!smallScreen && findPickedTatamiInBlock?.day && (
                        <span className={classes.marginLeft1}>{blockName}</span>
                      )}
                    </span>
                  </span>
                  <span className={classes.grid}>
                    {Array.isArray(tatami) &&
                      tatami.map((item, idx) => {
                        const tatamiBg =
                          !!+item.is_picked &&
                          selectedValue(tatamisTheme, ID, item.theme_id)
                            ?.color1;

                        !!+item.is_picked && tatamiCounter++;

                        return (
                          <span
                            onClick={(evt) => {
                              if (
                                !!+item.is_picked &&
                                !!+item.categories_count
                              ) {
                                openEditTatamiForm(
                                  'REMOVE_CONFIRMATION',
                                  item,
                                  tatamiCounter
                                );
                              } else {
                                addTatamis(evt, item);
                              }
                            }}
                            style={tatamiStyle(tatamiBg)}
                            className={clsx(
                              classes.flex,
                              classes.relative,
                              classes.tatamiWrapper,
                              !shouldDisableEditing
                                ? classes.onHover
                                : classes.noEvent,
                              classes.column
                            )}
                            key={idx}>
                            {!!+item.is_picked &&
                              item?.categories_count !== '0' && (
                                <span className={clsx(classes.badge)}>
                                  {item?.categories_count}
                                </span>
                              )}
                            <span className={clsx(classes.tatami)}>
                              <span>
                                <Tooltip
                                  arrow
                                  title={[
                                    t('select'),
                                    t('tatami'),
                                    '-',
                                    item.tatami_name
                                  ].join(' ')}>
                                  <span>{item.tatami_name}</span>
                                </Tooltip>
                              </span>
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.spaceBetween,
                                classes.padding08
                              )}>
                              {item.translation_link_1 && (
                                <img src={videoIc} alt="link" />
                              )}
                              {!!+item.is_picked && (
                                <IconButton
                                  className={clsx(
                                    classes.left,
                                    classes.updateTatami
                                  )}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    if (!shouldDisableEditing) {
                                      openEditTatamiForm('EDIT_TATAMI', item);
                                    }
                                  }}>
                                  <EditIcon
                                    className={clsx(
                                      classes.updateTatami,
                                      classes.noEvent
                                    )}
                                  />
                                </IconButton>
                              )}
                            </span>
                          </span>
                        );
                      })}
                  </span>
                </span>
              </span>
            )
          );
        })}
    </>
  );
};
