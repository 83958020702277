import { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import Invitation from '../../components/Invitation/Invitation';
import SideModal from '../../components/Snackbar/SideModal';
import {
  fetchTemplates,
  changeModal,
  fetchTournaments,
  updateTemplate,
  fetchCoaches,
  sendInvitation,
  getInvitationToken,
  changeHead,
  fetchReference,
  fetchExcelFields,
  fetchExcelImport,
  fetchAllCategoryTypesV2,
  fetchUsers,
  importExcel,
  selectOption,
  saveTournInvitations,
  fetchTournInvitations,
  textChangeHandler,
  fetchCountries,
  changeImage,
  changeDate,
  validateForm,
  fetchReferenceRegion,
  usersRegisterNewUser,
  deleteTournInvitations,
  onBlur
} from '../../helpers/util';
import { DDMMYYYY } from '../../helpers/selectors';
import {
  ENV_URL,
  KEEP_EDITING,
  CLOSE_DISCARD,
  CHOOSE_FROM_LIST
} from '../../helpers/constants';

import { AuthContext } from 'AuthContext';

import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import Excel from '../../components/ImportExcel/ImportExcel';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import CopyTxt from '../../components/CopyTxt/CopyTxt';

import styles from './Styles';
import { first } from 'underscore';

const initialState = {
  checkedValues: [],
  indeterminate: false,
  checkAll: false,
  recipients: []
};

const defaultForm = {
  first_name: '',
  last_name: '',
  email: '',
  country_id: ''
};
class InviteCoaches extends Component {
  state = {
    tournamentId: +this.props.match.params.id,
    tournInvitations: [],
    templateLang: '1',
    type: 1,
    ...initialState,
    showModal: false,
    coachesList: [],
    selectedCheckboxes: [],
    selectedTemplate: {},
    tournamentData: {},
    errors: {},
    modalData: { selectedCheckboxes: [] },
    excelData: {},
    showError: {},
    countries: [],
    allRegions: [],
    categoriesTypesPresent: [],
    teamRepresentatives: [],
    loading: true,

    langOnLoad: localStorage.getItem('i18nextLng')
  };

  fetchTemplates = fetchTemplates.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  updateTemplate = updateTemplate.bind(this);
  fetchCoaches = fetchCoaches.bind(this);
  sendInvitation = sendInvitation.bind(this);
  getInvitationToken = getInvitationToken.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);
  fetchExcelFields = fetchExcelFields.bind(this);
  fetchExcelImport = fetchExcelImport.bind(this);
  fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
  fetchUsers = fetchUsers.bind(this);
  fetchCountries = fetchCountries.bind(this);
  importExcel = importExcel.bind(this);
  selectOption = selectOption.bind(this);
  validateForm = validateForm.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  fetchTournInvitations = fetchTournInvitations.bind(this);
  saveTournInvitations = saveTournInvitations.bind(this);
  deleteTournInvitations = deleteTournInvitations.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  changeImage = changeImage.bind(this);
  changeDate = changeDate.bind(this);
  onBlur = onBlur.bind(this);

  static contextType = AuthContext;

  componentDidMount() {
    const { tournamentId, type, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;

      this.fetchAllCategoryTypesV2({ tournament_id: tournamentId }, (types) => {
        let categoriesTypesPresent = [];
        types.map((type) => {
          if (tournamentData?.category_types?.some((it) => +it === +type?.id)) {
            categoriesTypesPresent = [...categoriesTypesPresent, type];
          }
        });

        this.getInvitationToken(tournamentData?.id);

        this.setState({ categoriesTypesPresent });
      });

      if (tournamentData) {
        if (!!+tournamentData?.section_import_on) {
          this.fetchExcelFields((data) => {
            let fields = [];

            data.map((it) => {
              switch (it) {
                case 'first_name':
                  fields = [
                    ...fields,
                    {
                      name: 'firstName',
                      label: t('firstname'),
                      name_db: it,
                      isRequired: true
                    }
                  ];
                  break;
                case 'last_name':
                  fields = [
                    ...fields,
                    {
                      name: 'lastName',
                      label: t('lastname'),
                      name_db: it,
                      isRequired: true
                    }
                  ];
                  break;
                case 'patronymic':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('patronymic'),
                      name_db: it
                    }
                  ];
                  break;
                case 'birthday':
                  fields = [
                    ...fields,
                    {
                      name: 'birthdayInput',
                      label: t('birthday'),
                      name_db: it,
                      isRequired: true
                    }
                  ];
                  break;
                case 'age':
                  fields = [
                    ...fields,
                    {
                      name: 'age',
                      label: t('age'),
                      name_db: it
                    }
                  ];
                  break;
                case 'gender':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('gender'),
                      name_db: it,
                      isRequired: true
                    }
                  ];
                  break;
                case 'weight':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('weight'),
                      name_db: it,
                      isRequired: true
                    }
                  ];
                  break;
                case 'height':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('height'),
                      name_db: it
                    }
                  ];
                  break;
                case 'coach_first_name':
                  fields = [
                    ...fields,
                    {
                      name: 'coachFirstName',
                      label: [t('coach'), t('firstname')].join(' '),
                      name_db: it
                    }
                  ];
                  break;
                case 'coach_last_name':
                  fields = [
                    ...fields,
                    {
                      name: 'coachLastName',
                      label: [t('coach'), t('lastname')].join(' '),
                      name_db: it
                    }
                  ];
                  break;
                case 'coach_first_name_2':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: [t('coach'), t('firstname'), '/2'].join(' '),
                      name_db: it
                    }
                  ];
                  break;
                case 'coach_last_name_2':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: [t('coach'), t('lastname'), '/2'].join(' '),
                      name_db: it
                    }
                  ];
                  break;
                case 'region':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('region'),
                      name_db: it
                    }
                  ];
                  break;
                case 'city':
                  fields = [
                    ...fields,
                    {
                      name: it,
                      label: t('city'),
                      name_db: it
                    }
                  ];
                  break;
                default:
                  fields = [...fields, { name: it, label: it }];
              }
            });

            let currentFields = [];
            let optionalFields = [];

            fields.map((it) => {
              if (it.isRequired) {
                currentFields = [...currentFields, it];
              } else {
                optionalFields = [...optionalFields, it];
              }
            });

            this.setState({ excelData: { currentFields, optionalFields } });
          });

          this.fetchUsers(null, null, null, null, (data) => {
            this.setState({ teamRepresentatives: data });
          });
        }

        this.fetchTournInvitations({ tournamentId }, (data) =>
          this.setState({
            tournInvitations: data,
            tournInvitationsFiltered: data,
            loading: false
          })
        );
      }

      changeHead(tournamentData, t('registrationN'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'invite');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCheckboxes, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      isEditing &&
      selectedCheckboxes &&
      selectedCheckboxes !== prevState.selectedCheckboxes
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('registrationN'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  showModal = (elem) => {
    const modalData = { open: true, targetID: elem?.targetID, isLoading: true };

    this.setState({ modalData });

    if (elem?.targetID === CHOOSE_FROM_LIST) {
      new Promise((resolve) => {
        this.fetchCoaches(null, () => {
          const { tournInvitations, coachesList } = this.state;
          let selectedInvitations = [];

          tournInvitations?.map((it) => {
            const coach = coachesList.find(
              (coach) => +coach?.id === +it?.user_id
            );

            if (coach) {
              selectedInvitations = [...selectedInvitations, coach];
            }
          });

          resolve(selectedInvitations);
        });
      }).then((res) => {
        this.setState((prev) => ({
          modalData: {
            ...prev.modalData,
            selectedCheckboxes: [],
            isLoading: false
          }
        }));
      });
    } else {
      const data1 = new Promise((resolve) => {
        this.fetchCountries(() => resolve(true));
      });
      const data2 = new Promise((resolve) => {
        if (elem?.country_id) {
          this.fetchReferenceRegion(elem?.country_id, null, () =>
            resolve(true)
          );
        } else {
          resolve();
        }
      });

      Promise.all([data1, data2]).then(() => {
        this.setState((prevState) => ({
          modalData: { ...prevState.modalData, isLoading: false }
        }));
      });
    }
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        modalData: { selectedCheckboxes: [] },
        shouldShowDiscardChanges: false,
        isEditing: false,
        allRegions: []
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onDropExcel = (acceptedFiles) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };
    const fields = JSON.stringify(
      excelData?.currentFields?.map((it) => it.name_db)
    );

    if (acceptedFiles[0]) {
      this.fetchExcelImport({ file: acceptedFiles[0], fields }, (data) => {
        cpy.data = data?.map((it) => ({
          ...it,
          isChecked: it.passed,
          birthday: it.birthday,
          birthdayInput: DDMMYYYY(it.birthday),
          categoryTypes: []
        }));

        this.setState({ excelData: cpy });
      });
    }
  };

  onResizeColumn = (width, index) => {
    const { excelData } = this.state;
    const newColumnsOrder = [...excelData?.currentFields];

    newColumnsOrder[index].width = width;

    this.setState((prev) => ({
      excelData: { ...prev.excelData, currentFields: newColumnsOrder }
    }));
  };

  onToggleOptionalFields = (el) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };

    cpy.currentFields = cpy.currentFields.some((it) => it.name === el.name)
      ? cpy.currentFields.filter((it) => it.name !== el.name)
      : [...cpy.currentFields, el];

    this.setState({ excelData: cpy });
  };

  onTogglePassedStatus = (evt, el) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };

    cpy.data[el?.idx].isChecked = evt.target.checked;

    this.setState({ excelData: cpy });
  };

  onToggleCategoryTypes = (el) => {
    const { excelData } = this.state;
    let cpy = { ...excelData };

    cpy.data[el.idx].categoryTypes = cpy.data[el.idx].categoryTypes.some(
      (it) => +it === +el.type
    )
      ? cpy.data[el.idx].categoryTypes.filter((it) => +it !== +el.type)
      : [...cpy.data[el.idx].categoryTypes, el.type];

    this.setState({ excelData: cpy });
  };

  onDragEnd = (result) => {
    const { excelData } = this.state;
    if (!result.destination) return;

    const newColumnsOrder = [...excelData?.currentFields];
    const [reorderedColumn] = newColumnsOrder.splice(result.source.index, 1);

    newColumnsOrder.splice(result.destination.index, 0, reorderedColumn);

    this.setState((prev) => ({
      excelData: { ...prev.excelData, currentFields: newColumnsOrder }
    }));
  };

  onImportExcel = () => {
    const { t } = this.props;
    const { tournamentData, excelData } = this.state;

    if (excelData?.selectedRepresentative) {
      this.importExcel(
        {
          userId: excelData?.selectedRepresentative,
          tournamentId: tournamentData?.id,
          data: excelData.data.map((it) => ({
            ...it,
            passed: it?.isChecked
          }))
        },
        () => {
          const defaultData = {
            currentFields: excelData?.currentFields,
            optionalFields: excelData?.optionalFields
          };

          this.setState({ excelData: defaultData });
        }
      );
    } else {
      this.changeModal(t('teamRepresentativeNoSelection'));
      this.setState((prev) => ({
        excelData: {
          ...prev.excelData,
          selectedRepresentativeHelperTxtError: t('required')
        }
      }));
    }
  };

  onSwitchRepresentative = (e, val) => {
    this.selectOption(e, val, 'excelData', 'selectedRepresentative', 'id');
    this.setState((prev) => ({
      excelData: {
        ...prev.excelData,
        selectedRepresentativeHelperTxtError: null
      }
    }));
  };

  onSearch = (param) => {
    const { modalData } = this.state;
    const tournInvitationsFiltered =
      modalData?.targetID !== CHOOSE_FROM_LIST && this.onFilterInvitations(); //filter invitations

    this.setState(
      (prevState) => ({
        [param]: {
          ...prevState[param],
          searchedValue: this.state[param]?.searchBar
        },
        ...(tournInvitationsFiltered ? { tournInvitationsFiltered } : {})
      }),
      () => {
        if (modalData?.targetID === CHOOSE_FROM_LIST) {
          this.onFilterRecordsModal();
        }
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onFilterRecordsModal = () => {
    const { coachesList, modalData } = this.state;
    const len = coachesList?.length;
    let newData = [];

    for (let i = 0; i < len; i++) {
      const el = coachesList[i];
      const bySearch = modalData?.searchBar
        ? [el.c_first_name, el.c_last_name, el.email, el.club].some((val) =>
            val
              ?.toLowerCase()
              .includes(modalData.searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newData = [...newData, el];
      }
    }
    this.setState({ filteredCoaches: newData });
  };

  onFilterInvitations = (data) => {
    const { tournInvitations, filters } = this.state;
    const currentData = data ?? tournInvitations;
    const len = currentData?.length;
    let newData = [];

    for (let i = 0; i < len; i++) {
      const el = currentData[i];
      const bySearch = filters?.searchBar
        ? [el.c_first_name, el.c_last_name, el.email, el.club].some((val) =>
            val?.toLowerCase().includes(filters?.searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newData = [...newData, el];
      }
    }

    return newData;
  };

  onChangeSearchText = (evt, param) => {
    this.setState((prevState) => ({
      [param]: { ...prevState[param], searchBar: evt.target.value }
    }));
  };

  onClearSearch = (param) => {
    this.setState(
      (prevState) => ({ [param]: { ...prevState[param], searchBar: '' } }),
      () => this.onSearch(param)
    );
  };

  onEnterPress = (evt, param) => {
    if (evt.key === 'Enter') {
      this.onSearch(param);
    }
  };

  select2Invite = (elem) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };

    cpy.selectedCheckboxes = cpy?.selectedCheckboxes?.some(
      (it) => +it?.id === +elem?.id
    )
      ? cpy?.selectedCheckboxes?.filter((it) => +it?.id !== +elem?.id)
      : [...(cpy.selectedCheckboxes ?? []), elem];

    this.setState({ modalData: cpy });
  };

  onSave = (evt) => {
    const { tournamentData, modalData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          { ...defaultForm },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, (body) => {
              this.setState({
                modalData: { selectedCheckboxes: [] },
                errors: {},
                imagePreview: null
              });
              this.saveTournInvitations(
                { tournamentId: tournamentData?.id, id: body?.id },
                () => {
                  this.triggerFetchTournInvitations(this.hideModal);
                }
              );
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  triggerFetchTournInvitations = (cb) => {
    const { tournamentId } = this.state;

    this.fetchTournInvitations({ tournamentId }, (data) => {
      const tournInvitationsFiltered = this.onFilterInvitations(data);

      this.setState({ tournInvitations: data, tournInvitationsFiltered });
      cb && cb();
    });
  };

  render() {
    const {
      modalData,
      showModal,
      success,
      modalInfo,
      filteredCoaches,
      invitationToken,
      tournamentData,
      loading,
      shouldShowDiscardChanges,
      categoriesTypesPresent,
      teamRepresentatives,
      excelData,
      tournInvitationsFiltered,
      showError,
      errors,
      countries,
      allRegions,
      filters
    } = this.state;

    const { viewportWidth } = this.context;
    const { t, classes } = this.props;

    const pageHeaderStatistics = {
      title: t('invitationLink'),
      noImg: true,
      info: [
        {
          name: (
            <span
              className={clsx(
                classes.flex,
                classes.spaceBetween,
                classes.alignItems,
                classes.fullWidth
              )}>
              <label>{`${ENV_URL}/register_to_tournament/${invitationToken}`}</label>
              <CopyTxt
                copiedTxt={`${ENV_URL}/register_to_tournament/${invitationToken}`}
              />
            </span>
          )
        },
        {
          label: t('pincode'),
          name: tournamentData.id
        },
        {
          name: (
            <span
              className={clsx(
                classes.flex,
                classes.spaceBetween,
                classes.alignItems,
                classes.fullWidth
              )}>
              <label>{`${ENV_URL}/sign-in`}</label>
              <CopyTxt copiedTxt={`${ENV_URL}/sign-in`} />
            </span>
          )
        }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, viewportWidth, pageHeaderStatistics }}
              shouldShowBtn={true}
            />
            {!!+tournamentData?.limited_reg_on && (
              <Invitation
                textChange={this.onChangeSearchText}
                clearSearch={this.onClearSearch}
                pressEnter={this.onEnterPress}
                {...{ filters }}
                selectOption={this.selectOption}
                {...{ viewportWidth }}
                {...{ modalData }}
                showModal={this.showModal}
                hideModal={this.hideModal}
                {...{ filteredCoaches }}
                select2Invite={this.select2Invite}
                tournInvitations={tournInvitationsFiltered}
                {...{ tournamentData, countries }}
                discardOrKeepEditing={this.discardOrKeepEditing}
                {...{
                  shouldShowDiscardChanges,
                  showError,
                  countries,
                  errors,
                  allRegions
                }}
                onSaveInvitations={(elem) => {
                  this.saveTournInvitations(elem, () => {
                    this.triggerFetchTournInvitations(this.hideModal);
                  });
                }}
                onSave={this.onSave}
                onBlur={this.onBlur}
                onChangeTxtForm={this.textChangeHandler}
                changeImage={this.changeImage}
                changeDate={this.changeDate}
                fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
                onDelete={(elem) => {
                  this.deleteTournInvitations(elem, () => {
                    this.triggerFetchTournInvitations();
                  });
                }}
              />
            )}
            {!!+tournamentData?.section_import_on && (
              <Excel
                onDropExcel={this.onDropExcel}
                onResizeColumn={this.onResizeColumn}
                onToggleOptionalFields={this.onToggleOptionalFields}
                onTogglePassedStatus={this.onTogglePassedStatus}
                onToggleCategoryTypes={this.onToggleCategoryTypes}
                onDragEnd={this.onDragEnd}
                onSwitchRepresentative={this.onSwitchRepresentative}
                onImportExcel={this.onImportExcel}
                {...{
                  excelData,
                  categoriesTypesPresent,
                  tournamentData,
                  teamRepresentatives
                }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(InviteCoaches)));
