import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  marginBottom20: {
    marginBottom: '20px'
  },
  goBackArrow: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA',
    cursor: 'pointer',
    marginLeft: '1rem',
    height: '40px'
  },
  heightFitContent: {
    height: 'fit-content'
  },
  left: {
    marginLeft: 'auto'
  },

  displayContent: {
    display: 'contents'
  },

  paper: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  column: {
    flexDirection: 'column'
  },
  fullWidth: {
    width: '100%'
  },
  img: {
    objectFit: 'cover',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    height: '112px',
    borderRadius: '4px'
  },
  panelWithoutImgBtn: {
    width: 'calc(100% - 13em)'
  },
  marginLeftRight: {
    padding: '0 .5em'
  },
  paddingBottom: {
    paddingBottom: '.5em'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  secondaryTxt: {
    paddingRight: '.2em',
    whiteSpace: 'break-spaces',
    color: '#92979F'
  },

  paperShadow: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    '@media (min-width:901px)': {
      borderRadius: '4px 0 0 4px'
    }
  },
  borderRightLargeViewport: {
    '@media (min-width:901px)': {
      borderRight: ' 1px solid rgba(0, 29, 61, 0.5)'
    }
  },
  textAlign: {
    textAlign: 'center'
  },
  nameOfSportEvent: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    display: 'block'
  },

  fontSize14: {
    fontSize: '14px'
  },

  justifyContent: {
    justifyContent: 'space-between'
  },

  padding14_8: {
    padding: '14px 8px'
  },

  marginBottom6: {
    marginBottom: '6px'
  },

  padding10: {
    padding: '10px'
  },

  padding7: {
    padding: '7px'
  },

  centerVertically: {
    justifyContent: 'center'
  },

  fullWidth: {
    width: '100%'
  },

  title: {
    flexGrow: 1,
    textAlign: 'center'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  red: {
    color: '#F5635C'
  },

  green: {
    color: '#00BB92'
  },

  grey: {
    color: '#8996AC'
  },

  fullHeight: {
    height: '100%'
  },

  height110: {
    height: '110px'
  },

  alignItems: {
    alignItems: 'center'
  }
}));
