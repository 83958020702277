import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ro, ru, de } from 'date-fns/locale';
import clsx from 'clsx';
import UploadImage from '../UploadImage/UploadImage';
import { TextField } from '@material-ui/core';
import Filter from '../Filter/Filter';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import {
  DATE_PLACEHOLDER,
  ID,
  COUNTRY_ID,
  TYPE,
  LABEL,
  getEventTypes,
  NAME
} from '../../helpers/constants';
import { selectedValue, countryLabel, isSchool } from '../../helpers/selectors';
import { ReactComponent as DateIcon } from '../../assets/img/date.svg';

import { AuthContext } from 'AuthContext';

import { useStyle, styleFilter } from './StylesNewDesign';

const currentLang = localStorage.getItem('i18nextLng');

const TournamentFormNewDesign = ({
  handleTextChange,
  countries,
  tournamentDateChange,
  registrationDateChange,
  tournamentTimeChange,
  commissionTimeChange,
  commissionDateChange,
  regJudgeDateChange,
  errors,
  imageChange,
  values,
  updateAble,
  selectOption,
  fetchRegionBySelectedCountry
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const isTraining = isSchool(values);

  const context = useContext(AuthContext);
  const role = context.authState.role;

  const inputs = [
    { type: true, isFilter: true },
    {
      name: 'tournament_name',
      label: t('eventName'),
      required: true
    },
    {
      country: true,
      isFilter: true,
      required: true
    },
    {
      name: 'region',
      label: t('district')
    },
    {
      name: 'city',
      label: t('city'),
      required: true
    },
    {
      name: 'address',
      label: t('address'),
      required: true
    },
    { time: true, isTime: true },
    { startEvent: true, isDate: true },
    {
      name: 'duration_days',
      label: t('durationDays')
    },
    ...(isTraining ? [{ endReg: true, isFilter }] : []),
    ...(updateAble
      ? [
          {
            name: 'description',
            label: t('description'),
            rows: true
          }
        ]
      : []),
    ...(!isTraining ? [{ comissionLabel: true, isDivideTxt: true }] : []),
    ...(!isTraining
      ? [
          {
            name: 'reg_address',
            label: t('address')
          }
        ]
      : []),
    { regDate: true, isDate: true },
    { reg_time: true, isTime: true },
    ...(!isTraining
      ? [
          { endReg: true, isDate: true },
          { reg_judge_date: true, isDate: true }
        ]
      : []),
    ...(!isTraining ? [{ committeeLabel: true, isDivideTxt: true }] : [])
  ];

  const dateComponent = (onChange, label, param, className) => (
    <MuiPickersUtilsProvider
      locale={[ru, ro, de].find((it) => it.code === currentLang)}
      utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={clsx(
          classes.asterisk,
          classes.ellipsis,
          classes.maxHeightTxtInput,
          className
        )}
        InputLabelProps={{
          classes: {
            root: classes.overlappingLabel,
            shrink: classes.labelShrinked
          }
        }}
        disableToolbar
        format="dd/MM/yyyy"
        id="date-picker-inline"
        keyboardIcon={<DateIcon />}
        name="date"
        placeholder={DATE_PLACEHOLDER}
        variant="inline"
        {...{ label }}
        value={values[param] || null}
        {...{ onChange }}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        error={Boolean(errors?.[param])}
        helperText={errors?.[param]}
        required
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
  );

  const filter = (
    label,
    options,
    param,
    key,
    item,
    error,
    helperText,
    required,
    className,
    cb,
    freeSolo,
    additionalItem
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      values[param],
      param === COUNTRY_ID
    );

    return (
      <Filter
        {...{ options }}
        className={clsx(
          classes.maxHeightAutocomplete,
          classes.asterisk,
          className
        )}
        onChange={(evt, val) =>
          selectOption(evt, val, 'tournamentData', param, key, errors, cb)
        }
        {...{ label }}
        {...{ item }}
        value={currentVal || ''}
        {...{ required }}
        {...{ helperText }}
        {...{ error }}
        id={param}
        variant="outlined"
      />
    );
  };

  const textFieldComponent = (
    name,
    label,
    className,
    required,
    rows,
    int,
    disabled
  ) => (
    <TextField
      multiline={rows}
      className={clsx(
        classes.maxHeightTxtInput,
        classes.ellipsis,
        classes.asterisk,
        className
      )}
      value={values[name] || ''}
      {...{ name }}
      {...{ label }}
      {...{ required }}
      onChange={(evt) =>
        handleTextChange(
          evt,
          'tournamentData',
          errors,
          int,
          null,
          null,
          null,
          name === 'city'
        )
      }
      error={Boolean(errors?.[name])}
      helperText={errors?.[name]}
      variant="outlined"
    />
  );

  return (
    <>
      <div
        className={clsx(
          classes.paper,
          classes.grid,
          classes.marginTop1,
          classes.noEvent
        )}>
        <UploadImage
          className={classes.formAvatar}
          id="upload-file"
          isPoster={true}
          img={values.imagePreview}
          onChange={imageChange}
        />

        {inputs.map((it, index) => (
          <Fragment key={index}>
            {!it.isDate &&
              !it.isFilter &&
              !it.isTime &&
              !it.isDivideTxt &&
              textFieldComponent(
                it.name,
                it.label,
                it.className,
                it.required,
                it.rows
              )}
            {it.isFilter && (
              <>
                {it.country &&
                  filter(
                    t('country'),
                    countries,
                    COUNTRY_ID,
                    ID,
                    {
                      firstName: 'iso3',
                      lastName: countryLabel(currentLang),
                      parathesis: true
                    },
                    Boolean(errors?.[COUNTRY_ID]),
                    errors?.[COUNTRY_ID],
                    true,
                    [it.className],
                    (val) => fetchRegionBySelectedCountry(val)
                  )}
                {it?.type &&
                  filter(
                    t('eventType'),
                    getEventTypes(),
                    TYPE,
                    ID,
                    LABEL,
                    Boolean(errors?.[TYPE]),
                    errors?.[TYPE],
                    true,
                    it.className
                  )}
                {it.region &&
                  filter(
                    t('district'),
                    allRegions,
                    'region',
                    NAME,
                    NAME,
                    Boolean(errors?.region),
                    errors?.region,
                    null,
                    it.className,
                    null,
                    true,
                    { name: values.region }
                  )}
              </>
            )}
            {it.isDate && (
              <>
                {it.startEvent &&
                  dateComponent(
                    tournamentDateChange,
                    t('eventStartDate'),
                    'date'
                  )}
                {it.endReg &&
                  dateComponent(
                    registrationDateChange,
                    t('registrationDate'),
                    'registration_date'
                  )}
                {it.regDate &&
                  dateComponent(
                    commissionDateChange,
                    t('commissionStartDate'),
                    'reg_date'
                  )}
                {it.reg_judge_date &&
                  dateComponent(
                    regJudgeDateChange,
                    t('applicationsForRefereeingAreAcceptedUntil'),
                    'reg_judge_date'
                  )}
              </>
            )}
            {it.isTime && (
              <>
                {it?.time && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className={clsx(
                        classes.maxHeightTxtInput,
                        classes.ellipsis,
                        classes.asterisk
                      )}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                      cancelLabel={t('cancel')}
                      okLabel={t('apply')}
                      ampm={false}
                      //if we get time from server it won't work with material ui timepicker
                      //because it requires a date object as a value
                      //so we convert our to time to time in this way
                      //we do not care about year month and day because it will not affect anything
                      error={Boolean(errors?.tour_time)}
                      helperText={errors?.tour_time}
                      id="time-picker"
                      label={t('time')}
                      onChange={tournamentTimeChange}
                      required
                      value={
                        values?.tour_time
                          ? new Date(
                              null,
                              null,
                              null,
                              values.tour_time.split(':')[0],
                              values.tour_time.split(':')[1]
                            )
                          : null
                      }
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                )}

                {it.reg_time && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className={clsx(
                        classes.ellipsis,
                        classes.asterisk,
                        classes.maxHeightTxtInput
                      )}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                      cancelLabel={t('cancel')}
                      okLabel={t('apply')}
                      ampm={false}
                      error={Boolean(errors?.reg_time)}
                      helperText={errors?.reg_time}
                      id="time-picker"
                      label={t('time')}
                      onChange={commissionTimeChange}
                      required
                      value={
                        values.reg_time
                          ? new Date(
                              null,
                              null,
                              null,
                              values.reg_time.split(':')[0],
                              values.reg_time.split(':')[1]
                            )
                          : null
                      }
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                )}
              </>
            )}
            {it.isDivideTxt && (
              <>
                {it.comissionLabel && (
                  <h4 className={clsx(classes.comissionLabel)}>
                    {t('theCommission')}
                  </h4>
                )}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};
export default TournamentFormNewDesign;
