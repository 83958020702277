import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    padding: '0 .5rem'
  },

  grid: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateAreas:
        "'avatarArea  . . .''avatarArea  . . .''avatarArea  . . .''avatarArea  comission comission comission''avatarArea  . . .''avatarArea  . . .'",
      gridColumnGap: '1rem',
      gridTemplateColumns: '15em 1fr 1fr 1fr'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateAreas:
        "'avatarArea  .''avatarArea  .''avatarArea  .'' .  .''.  .''.  .''comission  comission''. .''.  .'",
      gridColumnGap: '1rem',
      gridTemplateColumns: '50%'
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '100%'
    }
  },

  formAvatar: {
    '@media (min-width:768px)': {
      gridArea: 'avatarArea'
    },
    width: '15em',
    height: '18em',
    '@media (max-width:1023px)': {
      marginBottom: '1rem',
      width: '10em',
      height: '13em'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem',
      justifySelf: 'center'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  comissionLabel: {
    marginBottom: '1rem',
    '@media (min-width:767px)': {
      gridArea: 'comission'
    },
    fontWeight: '400'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  maxHeightTxtInput: {
    height: '5em'
  },

  ellipsis: {
    '& .MuiInputLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 1.5rem)'
    },
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
}));
