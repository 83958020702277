const styles = () => ({
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center',
    padding: '0 12px'
  },

  centerVertical: {
    alignItems: 'center'
  },

  classNamePaper: {}, //to remove form margins

  time: {
    border: '1px solid #001D3D',
    borderRadius: '0.2rem',
    padding: '0 0.2rem',
    width: '7em'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  },

  marginRight0dot5: {
    marginRight: '0.5rem'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  disabled: {
    opacity: '0.6'
  },

  kataFinalRoundInput: {
    width: '7em'
  },

  marginRight0: {
    marginRight: 0
  },

  timeInputWrapper: {
    height: '2.5em',
    margin: '0.2rem 0.5rem',
    marginBottom: 0
  },

  centerVertically: {
    alignItems: 'center'
  },

  marginLeft08: {
    marginLeft: '0.8rem'
  }
});

export default styles;
