import React, { useEffect } from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_white_bg&border.svg';

import {
  ID,
  FONT_TYPES_OPTIONS,
  FONT_WEIGHT_OPTIONS,
  TEXT_ALIGN_OPTIONS
} from 'helpers/constants';
import { selectedValue } from 'helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';

import { useStyles } from './StylesDiploma';

const FONT_SIZE_OPTIONS = Array.from({ length: 41 }, (_, i) => ({
  id: `${i + 10}`
}));

const SetDiploma = ({
  data,
  drawImageOnCanvas,
  onAddTextField,
  onMouseDown,
  onFilterSetDiploma,
  onSaveDiploma,
  onChangeTxt
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    drawImageOnCanvas();
  }, []);

  return (
    <div className={classes.wrapper}>
      <div id="canvasContainer" className={classes.canvasContainer}>
        <canvas id="canvas" className={classes.canvas} />
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            pointerEvents: 'none'
          }}>
          {data?.textFields?.map((val) => {
            const { field, id, label } = val;
            const {
              x,
              y,
              width,
              fontSize,
              fontFamily,
              fontWeight,
              isEditable
            } = val[`${field}_params`];
            const { scaleX, scaleY } = data;

            const scaledX = x * scaleX;
            const scaledY = y * scaleY;
            const scaledHeight = fontSize;
            const scaledFontSize = fontSize * scaleX;

            return (
              val?.isActive && (
                <div
                  key={id}
                  id={`textField-${id}`}
                  className={classes.draggable}
                  style={{
                    height: `${scaledHeight}px`,
                    position: 'absolute',
                    left: `${scaledX}px`,
                    top: `${scaledY}px`,
                    width: `${width}px`,
                    fontSize: `${scaledFontSize}px`,
                    fontFamily: fontFamily,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    pointerEvents: 'auto'
                  }}
                  onMouseDown={(e) => onMouseDown(e, val)}>
                  <TextField
                    variant="outlined"
                    value={label}
                    fullWidth
                    onChange={onChangeTxt}
                    inputProps={{
                      readOnly: !isEditable
                    }}
                    InputProps={{
                      style: {
                        fontSize: `${scaledFontSize}px`,
                        fontFamily: `${fontFamily}`,
                        fontWeight: `${fontWeight}`,
                        border: '2px solid'
                      }
                    }}
                    className={classes.textFieldCanvas}
                  />
                  <div
                    id={`resizeHandle-${id}`}
                    className={classes.resizeHandle}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
      <div className={classes.fieldsWrapper}>
        {data?.textFields?.map((it, itIdx) => {
          return (
            <div className={classes.fields} key={itIdx}>
              <span
                className={clsx(classes.field, classes.fieldName)}
                style={{
                  ...(it?.isActive
                    ? { background: '#1566DB', color: '#fff' }
                    : {})
                }}
                onClick={() => onAddTextField(it)}>
                {it?.label}
                {it?.isActive && (
                  <Clear
                    style={{
                      width: '20px',
                      height: '20px',
                      marginLeft: 'auto'
                    }}
                  />
                )}
              </span>
              {[
                { options: FONT_TYPES_OPTIONS, item: 'id', arg: 'fontFamily' },
                {
                  options: FONT_SIZE_OPTIONS,
                  item: 'id',
                  arg: 'fontSize',
                  int: true,
                  width: '40px'
                },
                {
                  options: FONT_WEIGHT_OPTIONS,
                  item: 'label',
                  arg: 'fontWeight'
                },
                { options: TEXT_ALIGN_OPTIONS, item: 'label', arg: 'fontAlign' }
              ].map((key, keyIdx) => {
                return (
                  <Filter
                    classes={{
                      root: classes.outlined
                    }}
                    key={keyIdx}
                    value={
                      selectedValue(
                        key.options,
                        ID,
                        it?.[`${it.field}_params`]?.[key.arg],
                        key?.int
                      ) || ''
                    }
                    className={classes.textField}
                    onChange={(e, val) =>
                      onFilterSetDiploma(e, val, {
                        arg: key.arg,
                        paramN: `${it.field}_params`,
                        elemIdx: itIdx,
                        name: 'id'
                      })
                    }
                    options={key?.options}
                    item={key?.item}
                    variant="outlined"
                  />
                );
              })}
            </div>
          );
        })}
        <Button
          isSaveBtn={true}
          className={classes.saveBtn}
          onClick={onSaveDiploma}
          label={t('saveDiploma')}
        />
      </div>
    </div>
  );
};

export default SetDiploma;
