import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';

import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as NewBronze } from '../../assets/img/medal.svg';
import { ReactComponent as Bronze } from '../../assets/img/thirdPlaceMedal.svg';

import { Tooltip, IconButton } from '@material-ui/core';

import {
  isSmallScreen,
  selectedValue,
  convertMs,
  setCategoryName,
  isMobileView
} from '../../helpers/selectors';
import { ID, DELETE_ICON, MODAL_EDIT_IC } from '../../helpers/constants';

import EmptyState from '../EmptyState/EmptyState';
import Button from '../Buttons/ActionButtons';
import SearchInput from '../SearchInput/SearchInput';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';

import { useStyles } from './style';
import { Clear } from '@material-ui/icons';

const EXTRA_TIME = (item) => [
  {
    label: 'MT',
    value: item.main_time_ms && convertMs(item.main_time_ms, true),
    param: 'main_time_ms'
  },
  {
    label: 'ET',
    value: item.extra_time_ms && convertMs(item.extra_time_ms, true),
    param: 'extra_time_ms'
  },
  {
    label: 'FMT',
    value: item.final_main_time_ms && convertMs(item.final_main_time_ms, true),
    param: 'final_main_time_ms'
  },
  {
    label: 'FET',
    value:
      item.final_extra_time_ms && convertMs(item.final_extra_time_ms, true),
    param: 'final_extra_time_ms'
  }
];
const getMax = (category) => {
  return category?.weight_f
    ?.filter((cat) => !!+cat?.tournament_presence)
    ?.reduce(
      (max, currentVal) => (max.name > currentVal.name ? max : currentVal),
      0
    );
};

export default ({
  categories,
  tournamentId,
  toggleCategoryStatus,
  shouldDisableEditing,
  onSelectCheckbox,
  selectedCheckboxes,
  kata_names,
  categoryTypes,
  viewportWidth,
  showModal,
  onSearch,
  onClearSearch,
  searchBar,
  exportTournamentCategories,
  cancelFilter
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const filterVision = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const currentLang = localStorage.getItem('i18nextLng');
  const KATA_ROUND = ['kara_round_1', 'kara_round_2'];
  const mobileScreen = isMobileView(viewportWidth);

  const categoryName = (category) => {
    //Display the option to change the ALT name only for custom-made categories in the tournament
    const showEditAltName =
      `${category.type}` !== '4' &&
      ((category?.kata && +category.kata.tournament_presence === 1) ||
        category?.weight_t?.some(
          (kumite_cat) => +kumite_cat.tournament_presence === 1
        )) &&
      !shouldDisableEditing;

    const convertNumberToRoman = (num) => {
      if (!isNaN(num)) {
        switch (num) {
          case 0:
            return 'I';
          case 1:
            return 'II';
          case 2:
            return 'III';
        }
      }
    };

    return (
      <span
        className={clsx(
          classes.flex,
          classes.verticallyCentered,
          classes.column
        )}>
        <div
          className={clsx(
            classes.flex,
            classes.verticallyCentered,
            classes.marginRightAuto,
            mobileScreen && classes.column
          )}>
          <div
            className={clsx(
              classes.flex,
              classes.spaceBetween,
              classes.verticallyCentered
            )}>
            <span className={clsx(classes.text, classes.marginRight1)}>
              {setCategoryName(category, true)}
            </span>
            {showEditAltName && (
              <span
                className={
                  !mobileScreen ? classes.margin04_1 : classes.marginLeft05
                }>
                <IconButton
                  className={classes.padding0}
                  onClick={(evt) => showModal(evt, category, MODAL_EDIT_IC)}>
                  <EditIcon className={classes.radiusIc} />
                </IconButton>
              </span>
            )}
          </div>
          {showEditAltName && (
            <span
              className={clsx(classes.marginRightAuto, classes.marginTop05)}>
              {(`${category.type}` === '1' || `${category.type}` === '3') &&
                `${t('round')} II: ${category.kata.kata_final}`}
            </span>
          )}
        </div>
        <span
          className={clsx(
            classes.flex,
            classes.column,
            classes.fontSize09,
            classes.marginRightAuto,
            classes.marginTop05
          )}>
          {category.kata && (
            <span className={clsx(classes.flex, classes.verticallyCentered)}>
              <span className={clsx(classes.flex, classes.column)}>
                {[
                  ...KATA_ROUND,
                  ...(+category.type === 5 ? ['kata_round_3'] : [])
                ].map(
                  (kataRound, kataRoundIdx) =>
                    category?.kata?.[kataRound]?.length > 0 && (
                      <span key={kataRoundIdx}>
                        {`${t('round')} ${convertNumberToRoman(
                          kataRoundIdx
                        )}: `}
                        {category.kata[kataRound].map((kata_n, idx) => {
                          const currentVal = selectedValue(
                            kata_names,
                            ID,
                            `${kata_n}`
                          );
                          return (
                            <span
                              className={classes.kataNamesMargin05}
                              key={idx}>
                              {currentVal &&
                                currentVal[
                                  currentLang === 'ru' ? 'name_ru' : 'name_en'
                                ]}
                              ;
                            </span>
                          );
                        })}
                      </span>
                    )
                )}
              </span>
              {[
                ...KATA_ROUND,
                ...(+category.type === 5 ? ['kata_round_3'] : [])
              ].some(
                (kataRound) =>
                  Array.isArray(category.kata[kataRound]) &&
                  category.kata[kataRound].length > 0 &&
                  !shouldDisableEditing
              ) && (
                <span
                  className={classes.flex}
                  id={DELETE_ICON}
                  onClick={(evt) => showModal(evt, category, DELETE_ICON)}>
                  <Clear
                    className={clsx(classes.marginLeft1, classes.radiusIc)}
                  />
                </span>
              )}
            </span>
          )}
        </span>
      </span>
    );
  };

  const Kata = (category, idx) =>
    category.kata && (
      <>
        <span
          className={clsx(
            classes.flex,
            classes.kumiteWrapper,
            classes.paddingTop1
          )}>
          <Tooltip arrow title={category.kata.category_name}>
            <span className={clsx(classes.flex, classes.relative)}>
              {!!+category?.kata?.tournament_presence &&
                category?.kata?.participants_count !== '0' &&
                category?.kata?.participants_count !== '0/0' && (
                  <span
                    className={clsx(
                      classes.badge,
                      +category?.kata?.participants_count === 1
                        ? classes.backgroundBadgeRed
                        : classes.backgroundBadgeBlue
                    )}>
                    {category?.kata?.participants_count}
                  </span>
                )}
              <Button
                label={+category.type !== 4 ? t('kata') : t('tameshiwari')}
                isSaveBtn={category.kata.tournament_presence !== 0}
                className={clsx(
                  +category.type !== 4 && classes.buttons,
                  category.kata.tournament_presence === 0
                    ? classes.notSelected
                    : classes.selected,
                  shouldDisableEditing && classes.noEvent,
                  classes.marginLeft,
                  classes.tatamiText
                )}
                onClick={(e) =>
                  toggleCategoryStatus(e, category, category.kata)
                }
              />
            </span>
          </Tooltip>
        </span>
      </>
    );

  const KumiteMax = (category) => {
    const maxCategory = getMax(category);

    return (
      category.weight_t &&
      category.weight_f && (
        <span className={clsx(classes.relative, classes.kumiteBtns)}>
          {maxCategory?.category_id &&
            +maxCategory?.participants_count !== 0 && (
              <span
                className={clsx(
                  classes.badge,
                  +maxCategory?.participants_count === 1
                    ? classes.backgroundBadgeRed
                    : classes.backgroundBadgeBlue
                )}>
                {maxCategory?.participants_count}
              </span>
            )}
          <Button
            className={clsx(
              classes.buttons,
              classes.tatamiText,
              !maxCategory?.category_id && classes.notSelected,
              classes.noEvent,
              maxCategory?.category_id ? classes.selected : classes.notSelected
            )}
            label={
              <label
                className={clsx(
                  !maxCategory?.category_id && classes.marginBottom28,
                  classes.fullWidth
                )}>
                {maxCategory?.category_id
                  ? parseFloat(maxCategory.name)
                    ? [parseFloat(maxCategory.name), '+'].join('')
                    : maxCategory.name
                  : 'Max'}
                {maxCategory?.category_id && (
                  <div
                    className={clsx(
                      classes.flex,
                      classes.center,
                      classes.spaceBetween
                    )}>
                    <NewBronze />
                  </div>
                )}
              </label>
            }
          />
        </span>
      )
    );
  };

  return (
    <>
      <span
        className={clsx(
          classes.gridButtons,
          classes.verticallyCentered,
          !isFilterOpen ? classes.marginBottom1 : undefined
        )}>
        <Button
          onClick={() => filterVision()}
          label={
            <span
              className={clsx(
                classes.flex,
                classes.verticallyCentered,
                classes.center,
                classes.flexGrow1
              )}>
              <span className={classes.flexGrow1}>{t('filter')}</span>
              <ArrowDropDownIcon
                className={isFilterOpen && classes.arrowDropUp}
              />
            </span>
          }
          isSaveBtn={!isFilterOpen}
          className={isFilterOpen && classes.selected}
          style={{ gridArea: 'filterButton' }}
        />
        <SearchInput
          className={classes.search}
          onChange={onSearch}
          clearSearch={onClearSearch}
          value={searchBar}
          isSearchInactive={!searchBar}
          style={{ gridArea: 'searchInput' }}
        />
        <Button
          onClick={() => exportTournamentCategories(tournamentId, true)}
          label={t('saveList')}
          isSaveBtn={true}
          className={classes.center}
          style={{ gridArea: 'exportButton' }}
        />
      </span>
      <FilterCheckboxes
        {...{
          categoryTypes,
          selectedCheckboxes,
          isFilterOpen
        }}
        filterDetails={{
          ownerCategories: true,
          tounamentPresence: true,
          noUnisex: true
        }}
        onSelectCheckbox={onSelectCheckbox}
        cancelFilter={cancelFilter}
      />
      {Array.isArray(categories) && categories.length > 0 ? (
        categories.map((category, index) => {
          return (
            <div
              className={clsx(
                classes.paper,
                classes.categoryWrapper,
                classes.marginBottom1
              )}
              key={index}>
              <span
                className={clsx(
                  !isSmallScreen(viewportWidth) && classes.flex,
                  classes.verticallyCentered
                )}>
                {categoryName(category)}
                <div
                  className={clsx(
                    classes.flex,
                    classes.verticallyCentered,
                    mobileScreen && classes.justifyContentCenter
                  )}>
                  {!!+category.bronze_fight_enabled && (
                    <Bronze className={clsx(classes.marginRightTop05)} />
                  )}
                  <Fragment>
                    {category?.hasOwnProperty('final_main_time_ms') &&
                      EXTRA_TIME(category).map(({ label, value }, timeIdx) => (
                        <MuiPickersUtilsProvider
                          key={timeIdx}
                          utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            KeyboardButtonProps={{
                              disabled: true,
                              style: { display: 'none' }
                            }}
                            ampm={false}
                            value={
                              (value &&
                                new Date(
                                  null,
                                  null,
                                  null,
                                  value.split(':')[0],
                                  value.split(':')[1]
                                )) ||
                              ''
                            }
                            views="seconds"
                            classes={{ root: classes.timeInputWrapper }}
                            InputProps={{
                              disableUnderline: true,
                              className: clsx(
                                classes.noEvent,
                                classes.time,
                                classes.input
                              ),
                              startAdornment: (
                                <span className={classes.marginRight0dot5}>
                                  {label}:
                                </span>
                              )
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      ))}
                  </Fragment>
                </div>
              </span>
              <span className={clsx(classes.flex, classes.kumiteWrapper)}>
                {Array.isArray(category.weight_t) &&
                  category.weight_t.map((weightTo, weightIndex) => {
                    return (
                      <Tooltip
                        key={weightIndex}
                        arrow
                        title={weightTo.category_name}>
                        <span
                          className={clsx(
                            classes.relative,
                            classes.kumiteBtns
                          )}>
                          {!!+weightTo?.tournament_presence &&
                            +weightTo?.participants_count !== 0 && (
                              <span
                                className={clsx(
                                  classes.badge,
                                  +weightTo?.participants_count === 1
                                    ? classes.backgroundBadgeRed
                                    : classes.backgroundBadgeBlue
                                )}>
                                {weightTo?.participants_count}
                              </span>
                            )}
                          <Button
                            className={clsx(
                              classes.buttons,
                              classes.pointer,
                              classes.tatamiText,
                              weightTo.tournament_presence === 0
                                ? classes.notSelected
                                : classes.selected,
                              shouldDisableEditing && classes.noEvent
                            )}
                            onClick={(e) =>
                              toggleCategoryStatus(
                                e,
                                category,
                                weightTo,
                                weightIndex
                              )
                            }
                            heightException
                            label={
                              <label
                                className={clsx(
                                  classes.fullWidth,
                                  classes.pointer,
                                  weightTo.tournament_presence === 0 &&
                                    classes.marginBottom28
                                )}>
                                <span>{weightTo.name}</span>
                                {weightTo.tournament_presence !== 0 && (
                                  <div
                                    className={clsx(
                                      classes.flex,
                                      classes.center,
                                      classes.spaceBetween
                                    )}>
                                    <NewBronze />
                                    {/* <IconButton
                                      className={classes.padding0}
                                      onClick={}>
                                      <EditIcon className={classes.update}/>
                                    </IconButton> */}
                                  </div>
                                )}
                              </label>
                            }
                          />
                        </span>
                      </Tooltip>
                    );
                  })}
                {!isSmallScreen(viewportWidth) && (
                  <>
                    {!(
                      +category?.type === 2 &&
                      +category?.age_from >= 18 &&
                      !category.age_to &&
                      !+category.group_id
                    ) && KumiteMax(category)}
                    {Kata(category, index)}
                  </>
                )}
              </span>
              {isSmallScreen(viewportWidth) && (
                <>
                  <span
                    className={clsx(classes.flex, classes.condensedViewMaxBtn)}>
                    {!(
                      +category?.type === 2 &&
                      +category?.age_from >= 18 &&
                      !category.age_to &&
                      !+category.group_id
                    ) && KumiteMax(category)}
                  </span>
                  {Kata(category, index)}
                </>
              )}
            </div>
          );
        })
      ) : (
        <EmptyState />
      )}
    </>
  );
};
