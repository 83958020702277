import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ID, DOWNLOAD_DOCUMENTS_OPTIONS } from '../../../helpers/constants';
import { selectedValue } from '../../../helpers/selectors';

import Dropzone from './Dropzone';
import Radio from '../../RadioBtn/RadioBtn';
import Alert from '../../../components/Alerts/Alert';
import Filter from '../../Filter/Filter';
import TextField from '../../Filter/TextFiled';

import useStyles from './StylesDropzone';

const DropFile = (props) => {
  const {
    onDrop,
    removeFile,
    changeTextHandler,
    fileData,
    errors,
    selectOption,
    fileDataErrors,
    onFilter
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const DOWNLOAD_PERMISSION = [
    { id: '1', label: t('allPersons') },
    { id: '2', label: t('masterOnly') },
    { id: '3', label: t('teamRepresentativesOnly') }
  ];

  return (
    <div className={classes.wrapper}>
      <Filter
        value={
          selectedValue(
            DOWNLOAD_DOCUMENTS_OPTIONS,
            ID,
            fileData.tournament_file_type,
            true
          ) || ''
        }
        onChange={onFilter}
        options={DOWNLOAD_DOCUMENTS_OPTIONS}
        label={t('type')}
        item="label"
        error={Boolean(fileDataErrors.tournament_file_type)}
        helperText={fileDataErrors.tournament_file_type}
        variant="outlined"
      />
      <TextField
        name="attachment_name"
        label={t('nameDocument')}
        fullWidth
        onChange={changeTextHandler}
        value={fileData.attachment_name || ''}
        error={Boolean(errors.attachment_name)}
        helperText={Boolean(errors.attachment_name) && errors.attachment_name}
        variant="outlined"
      />
      <Dropzone
        {...{ onDrop }}
        fileData={
          Array.isArray(fileData.files) ? fileData : { ...fileData, files: [] }
        }
        {...{ removeFile }}
        preview={(file) => file.name}
      />
      {Boolean(fileDataErrors.files) && (
        <Alert message={fileDataErrors.files} />
      )}
      {String(fileData.tournament_file_type) === '2' ? (
        <>
          <span className={clsx(classes.flex, classes.marginTop)}>
            {t('permissionToDownload')}
          </span>
          {Boolean(fileDataErrors.allowUser) && (
            <Alert message={fileDataErrors.allowUser} />
          )}
          <Radio
            value={fileData.allowUser || ''}
            options={DOWNLOAD_PERMISSION}
            onClick={selectOption}
            item="id"
            label="label"
            specificDetails={{ className: { radioGroup: classes.radioGroup } }}
          />
        </>
      ) : null}
    </div>
  );
};

export default DropFile;
