import { Component } from 'react';
import { withRouter } from '../../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { Tooltip } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/img/delete_grey_bg&border.svg';
import { ReactComponent as EditIcon } from 'assets/img/edit_grey_bg&border.svg';
import { ReactComponent as Document } from 'assets/img/document_new.svg';
import { ReactComponent as Diploma } from 'assets/img/diploma_new.svg';

import {
  fetchTournaments,
  getCertificates,
  uploadCertificate,
  changeModal,
  textChangeHandler,
  uploadFile,
  fetchUploadedFiles,
  downloadUplaodedFile,
  deleteUploadedFile,
  downloadFile,
  validateForm,
  pageChangeHandler,
  rowsPerPageHandler,
  changeHead,
  fetchReference
} from '../../../helpers/util';
import {
  serverDefaultPath,
  DOWNLOAD_DOCUMENTS_OPTIONS,
  FILE_DATA,
  DELETE_ICON,
  KEEP_EDITING,
  CLOSE_DISCARD
} from '../../../helpers/constants';
import {
  DDMMYYYY,
  finishedTournament,
  resizeUploadedFile
} from '../../../helpers/selectors';

import { AuthContext } from 'AuthContext';

import SideModal from '../../../components/Snackbar/SideModal';
import Modal from '../../../components/Modal/ModalNewDesign';
import DropFile from '../../../components/CertificateBuilder/Dropzone/DropFile';
import LoadingState from '../../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../../components/HeaderTournInfo/HeaderTournInfo';
import Pagination from '../../../components/TablePagination/TablePagination';
import Table from '../../../components/Table/CardsList';
import EmptyState from '../../../components/EmptyState/EmptyState';
import Toolbar from 'components/Table/Toolbar/Toolbar';
import Button from 'components/Buttons/ActionButtons';
import SetDiploma from 'components/CertificateBuilder/SetDiploma';

import styles from '../../Winners/styles';

const DIPLOMA = 'DIPLOMA';

const INITIAL_ALL_FIELDS = (t) => [
  { label: t('name'), name: 'name', field: 'name_field' },
  { label: t('date'), name: 'date', field: 'date_field' },
  { label: t('category'), name: 'category', field: 'category_field' },
  { label: t('age'), name: 'age', field: 'age_field' },
  { label: t('weight'), name: 'weight', field: 'weight_field' },
  { label: t('rank'), name: 'place', field: 'place_field' },
  { label: t('region'), name: 'region', field: 'region_field' },
  {
    label: t('additional'),
    name: 'additional',
    field: 'additional_field',
    isEditable: true
  }
];

class Attachment extends Component {
  state = {
    tournamentId: +this.props.match.params.id,
    open: {},
    success: false,
    showModal: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    isModalOpened: false,
    fileData: { scaleX: 1, scaleY: 1 },
    fileDataErrors: {},
    errors: {},
    tab: 1,
    tournamentData: {},
    filteredFiles: [],
    statistics: {},
    uploadedFiles: [],
    page: 1,
    rowsPerPage: 10,
    filesCount: 0
  };
  fetchTournaments = fetchTournaments.bind(this);
  getCertificates = getCertificates.bind(this);
  uploadCertificate = uploadCertificate.bind(this);
  changeModal = changeModal.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  uploadFile = uploadFile.bind(this);
  fetchUploadedFiles = fetchUploadedFiles.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  deleteUploadedFile = deleteUploadedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  validateForm = validateForm.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  changeHead = changeHead.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { open, fileData, tournamentData, isEditing, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (Object.keys(open).length > 0) {
      document.addEventListener('keydown', this.onKeyPress);
    }

    if (isEditing && fileData && fileData !== prevState.fileData) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('attachments'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;

      this.setState({ shouldDisableEditing }, () => {
        this.fetchFiles(tournamentId);
      });

      changeHead(tournamentData, t('attachments'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'select-attachment'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  tableSearch = (event) => {
    const { value } = event.target;
    const { uploadedFiles } = this.state;

    this.setState(
      {
        searchBar: value,
        page: 1,
        filteredFiles: uploadedFiles.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase().trim())
        )
      },
      () => this.setState({ filesCount: this.state.filteredFiles.length })
    );
  };

  clearSearch = () => {
    const { uploadedFiles } = this.state;
    const len = uploadedFiles?.length;

    this.setState({
      searchBar: '',
      filteredFiles: uploadedFiles,
      filesCount: len
    });
  };

  onKeyPress = (evt) => {
    if (evt.key === 'Escape') this.setState({ open: {} });
  };

  onFilterSetDiploma = (_, value, elem) => {
    const { fileData } = this.state;
    let cpy = { ...fileData };

    if (value !== null) {
      cpy.textFields[elem.elemIdx][elem.paramN][elem.arg] = value[elem.name];

      this.setState({
        fileData: cpy
      });
    }
  };

  hideSnackBar = () => this.setState({ showModal: false });
  onFilter = (_, value) => {
    const { fileDataErrors } = this.state;
    if (value !== null) {
      let allowUser = '';
      if (value.id === '2' || value.id === '3') {
        allowUser = value.id;
      }
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          tournament_file_type: value.id,
          type: value.type,
          allowUser
        }
      }));
      delete fileDataErrors.tournament_file_type;
    }
  };

  handleTournDownloadPermission = (evt) => {
    const { fileDataErrors } = this.state;
    const allowUser = evt.target.value;
    this.setState((prevState) => ({
      isEditing: true,
      fileData: {
        ...prevState.fileData,
        allowUser
      }
    }));
    delete fileDataErrors.allowUser;
  };

  showModal = (evt, item) => {
    const { tournamentData } = this.state;
    const { t } = this.props;
    const targetID = evt.currentTarget.id;
    const id = item?.id;
    let modalData = {};

    new Promise((resolve) => {
      if (item) {
        modalData = {
          ...item,
          tournament_file_type: item.tournament_file_type,
          attachment_name: item.name,
          id,
          type: item.type,
          allowUser:
            item.allow_all === 1
              ? '1'
              : item.allow_recorder_in === 1
              ? '2'
              : item.allow_coach_in === 1
              ? '3'
              : ''
        };
        if (targetID === DIPLOMA) {
          this.getCertificates(
            { tournament_id: tournamentData?.id },
            (data) => {
              const template = data?.[0];
              let textFields = [];
              const allFields = INITIAL_ALL_FIELDS(t);

              allFields?.map((it) => {
                let splitVal = '';
                if (typeof template?.[it.field] === 'string') {
                  splitVal =
                    template?.[it.field] && template[it.field].split('|');
                } else {
                }

                const fieldParam = {
                  x: parseInt(splitVal?.[0] ? splitVal[0] : 0),
                  y: parseInt(splitVal?.[1] ? splitVal[1] : 0),
                  fontSize: parseInt(splitVal?.[2] ?? 20),
                  width: parseInt(splitVal?.[3] ?? 150),
                  fontFamily:
                    splitVal?.[5]?.length > 0 ? splitVal?.[5] : 'Arial',
                  fontWeight: 'normal',
                  fontAlign: 'center'
                };
                textFields = [
                  ...textFields,

                  {
                    ...it,
                    isActive: !!splitVal,
                    id: it.name,
                    [`${it.field}_params`]: fieldParam
                  }
                ];
              });

              modalData = {
                ...template,
                path: template?.file_name,
                textFields
              };

              resolve(modalData);
            }
          );
        } else {
          resolve(modalData);
        }
      } else {
        resolve();
      }
    }).then((res) => {
      this.setState({
        ...(res ? { fileData: res } : {}),
        pressedIcon: targetID,
        isModalOpened: true,
        ...(targetID === DELETE_ICON ? { fileId: id } : {}),
        selectedRow: id
      });
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        isModalOpened: false,
        selectedRow: null,
        fileData: {},
        fileDataErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  onDropHandler = (acceptedFiles) => {
    const { fileDataErrors } = this.state;
    delete fileDataErrors.files;

    const setNewFile = (newFile) => {
      this.setState((prevState) => ({
        isEditing: true,
        fileData: {
          ...prevState.fileData,
          files: newFile
        }
      }));
    };

    if (acceptedFiles[0]) {
      if (acceptedFiles[0].type.includes('image/')) {
        resizeUploadedFile(acceptedFiles[0], (newFile) =>
          setNewFile([newFile])
        );
      } else {
        setNewFile(acceptedFiles);
      }
    }
  };
  removeFileHandler = () => {
    this.setState((prevState) => ({
      fileDataErrors: {
        ...prevState.fileDataErrors,
        files: this.props.t('required')
      },
      fileData: {
        ...prevState.fileData,
        files: null
      }
    }));
  };

  fetchFiles = (tournamentId) => {
    this.fetchUploadedFiles(null, tournamentId, null, () => {
      const { uploadedFiles } = this.state;
      const filteredFiles = [...uploadedFiles];
      const filesCount = uploadedFiles.length;
      const groupedByType = uploadedFiles.reduce((acc, currentVal) => {
        const key = currentVal.type;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(currentVal);

        return acc;
      }, {});
      const statistics = {
        totalCount: filesCount,
        documentsCount: groupedByType?.document?.length ?? 0,
        certificatesCount: groupedByType?.certificate?.length ?? 0
      };

      this.setState({ filteredFiles, filesCount, loading: false, statistics });
    });
  };

  addFile = (evt) => {
    const { fileData, tournamentId } = this.state;
    const requiredFields =
      fileData.tournament_file_type === '2'
        ? {
            ...(fileData.id ? {} : { files: '' }),
            tournament_file_type: '',
            allowUser: ''
          }
        : { ...(fileData.id ? {} : { files: '' }), tournament_file_type: '' };
    this.validateForm(evt, requiredFields, fileData, 'fileDataErrors', () => {
      this.uploadFile(evt, fileData, tournamentId, () => {
        this.fetchFiles(tournamentId);
        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      });
    });
  };
  deleteFile = (evt) => {
    const { fileId, tournamentId } = this.state;
    this.deleteUploadedFile(evt, fileId, () => {
      this.fetchFiles(tournamentId);
      this.hideModal();
    });
  };
  onFormEdit = (evt) => {
    const { errors } = this.state;
    this.textChangeHandler(
      evt,
      FILE_DATA,
      errors,
      null,
      null,
      null,
      null,
      null,
      () => {
        this.setState({ isEditing: true });
      }
    );
  };

  onAddTextField = (elem) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };
    const findIdx = cpy.textFields?.findIndex((it) => it.id === elem.name);

    cpy.textFields[findIdx].isActive = !cpy.textFields[findIdx]?.isActive;

    this.setState({ fileData: cpy });
  };

  onUpdatePosition = (id, x, y) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };
    const findIdx = cpy.textFields?.findIndex((it) => it.id === id);
    const currentVal = cpy.textFields[findIdx];
    cpy.textFields[findIdx][`${currentVal?.field}_params`] = {
      ...cpy.textFields[findIdx][`${currentVal?.field}_params`],
      x,
      y
    };

    this.setState({ fileData: cpy });
  };

  onUpdateSize = (id, width) => {
    const { fileData } = this.state;
    const cpy = { ...fileData };
    const findIdx = cpy.textFields?.findIndex((it) => it.id === id);
    const currentVal = cpy.textFields[findIdx];
    cpy.textFields[findIdx][`${currentVal?.field}_params`] = {
      ...cpy.textFields[findIdx][`${currentVal?.field}_params`],
      width
    };

    this.setState({ fileData: cpy });
  };

  onMouseDown = (e, elem) => {
    if (e.target.id === `resizeHandle-${elem?.id}`) {
      this.setState({ isResizing: true, currentTextField: elem });
    } else {
      this.setState({ isDragging: true, currentTextField: elem });
    }
  };

  onMouseMove = (e) => {
    const { isDragging, isResizing, currentTextField } = this.state;
    const container = document.getElementById('canvasContainer');

    if (!container) return;
    if (!currentTextField) return;

    if (isDragging) {
      const containerRect = container.getBoundingClientRect();
      const x = e.clientX - containerRect.left;
      const y = e.clientY - containerRect.top;
      this.onUpdatePosition(currentTextField.id, x, y);
    }
    if (isResizing) {
      const containerRect = container.getBoundingClientRect();
      const newWidth =
        e.clientX -
        containerRect.left -
        currentTextField[`${currentTextField.field}_params`].x;
      this.onUpdateSize(currentTextField.id, newWidth);
    }
  };

  onMouseUp = () => {
    const { isDragging, isResizing } = this.state;

    if (isDragging || isResizing) {
      this.setState({
        isDragging: false,
        isResizing: false,
        currentTextField: {}
      });
    }
  };

  drawImageOnCanvas = () => {
    const { fileData } = this.state;
    let cpy = { ...fileData };
    const canvas = document.getElementById('canvas');

    if (canvas) {
      const context = canvas.getContext('2d');
      const image = new Image();

      image.src = serverDefaultPath + fileData?.path;

      image.onload = () => {
        const imgWidth = image.width;
        const imgHeight = image.height;
        const imgRotation = imgHeight > imgWidth;

        const renderSize = fileData?.render_size?.split('|');

        const realDiplomHeight =
          renderSize?.length > 1
            ? imgRotation
              ? renderSize[0]
              : renderSize[1]
            : imgRotation
            ? 1122.24
            : 793.92;
        const realDiplomWidth =
          renderSize?.length > 1
            ? imgRotation
              ? renderSize[1]
              : renderSize[0]
            : imgRotation
            ? 793.92
            : 1122.24;

        const displayDiplomWidth = realDiplomHeight;

        const displayDiplomHeight = realDiplomWidth;
        let canvasDiplomaHeight, canvasDiplomaWidth;

        if (imgRotation) {
          const heightCoeficient = realDiplomHeight / displayDiplomHeight;

          canvasDiplomaHeight = displayDiplomHeight;
          canvasDiplomaWidth = realDiplomWidth / heightCoeficient;
        } else {
          const widthCoeficient = realDiplomWidth / displayDiplomWidth;

          canvasDiplomaWidth = displayDiplomWidth;
          canvasDiplomaHeight = realDiplomHeight / widthCoeficient;
        }

        const scaleX = canvasDiplomaWidth / realDiplomWidth;
        const scaleY = canvasDiplomaHeight / realDiplomHeight;

        canvas.width = canvasDiplomaWidth;
        canvas.height = canvasDiplomaHeight;

        context.drawImage(image, 0, 0, canvasDiplomaWidth, canvasDiplomaHeight);

        cpy = {
          ...cpy,
          scaleX,
          scaleY
        };

        this.setState({ fileData: cpy });
      };
    }
  };

  onSaveDiploma = (evt) => {
    const { fileData, tournamentData } = this.state;

    this.uploadCertificate(
      evt,
      {
        ...fileData,
        tournament_id: tournamentData?.id
      },
      this.hideModal
    );
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      loading,
      isModalOpened,
      fileData,
      errors,
      pressedIcon,
      tournamentData,
      fileDataErrors,
      page,
      rowsPerPage,
      filteredFiles,
      statistics,
      selectedRow,
      filesCount,
      shouldDisableEditing,
      searchBar,
      open,
      shouldShowDiscardChanges
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    let onClick, dialogTitle, dialogContent, buttonPurpose;

    if (pressedIcon !== DELETE_ICON) {
      onClick = (evt) => this.addFile(evt);

      if (pressedIcon !== DIPLOMA) {
        dialogTitle = fileData.id ? t('editAttachment') : t('addAttachment');
        buttonPurpose = fileData.id ? t('save') : t('addNew');
        dialogContent = (
          <DropFile
            changeTextHandler={this.onFormEdit}
            onDrop={this.onDropHandler}
            removeFile={this.removeFileHandler}
            {...{ errors }}
            {...{ fileData }}
            {...{ fileDataErrors }}
            selectOption={this.handleTournDownloadPermission}
            onFilter={this.onFilter}
          />
        );
      } else {
        dialogContent = (
          <SetDiploma
            drawImageOnCanvas={this.drawImageOnCanvas}
            onFilterSetDiploma={this.onFilterSetDiploma}
            onSaveDiploma={this.onSaveDiploma}
            data={fileData}
            onMouseDown={this.onMouseDown}
            onAddTextField={this.onAddTextField}
          />
        );
        onClick = undefined;
        dialogTitle = t('diplomaConstructor');
      }
    } else {
      onClick = (evt) => this.deleteFile(evt);
      dialogTitle = t('deleteAttachment');
      dialogContent = t('deleteDocumenMsg');
      buttonPurpose = t('deleteRecord', { name: '' });
    }

    const recordDataParams = (item, idx) => {
      const mobileView = viewportWidth <= 767;

      return {
        class: { gridRecords: classes.gridRecordsAttachments },
        main: {
          ...(mobileView ? { name: item.name } : {}),
          style: {
            cardMainPart: classes.cardWrapper,
            recordName: classes.txtWrapper
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1
          },
          hasPhoto: true,
          photo: item?.participant_photo,
          defaultImg: { ic: <Document className={classes.docIc} /> }
        },
        info: [
          ...(!mobileView
            ? [
                {
                  name: t('title'),
                  value: item.name
                }
              ]
            : []),
          {
            name: t('attachmentType'),
            value: DOWNLOAD_DOCUMENTS_OPTIONS.find(
              (it) => it.type === item.type
            )?.label
          },
          {
            name: t('uploadDate'),
            value: DDMMYYYY(item.uploading_date)
          },
          {
            value: (
              <span
                style={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: 'repeat(4, max-content)',
                  alignItems: 'center'
                }}>
                <Tooltip arrow title={t('download')}>
                  <Button
                    className={clsx(classes.onHover, classes.btnDownloadDoc)}
                    onClick={() => this.downloadUplaodedFile(item.id)}
                    label={t('download')}
                    isSaveBtn
                  />
                </Tooltip>
                {!shouldDisableEditing && (
                  <>
                    <Tooltip arrow title={t('edit')}>
                      <EditIcon
                        className={clsx(classes.actionIcDoc, classes.onHover)}
                        onClick={(evt) => this.showModal(evt, item)}
                      />
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={t('deleteRecord', { name: 'attachment' })}>
                      <DeleteIcon
                        id={DELETE_ICON}
                        className={clsx(classes.actionIcDoc, classes.onHover)}
                        onClick={(evt) => this.showModal(evt, item)}
                      />
                    </Tooltip>
                    {+item?.tournament_file_type === 3 ? (
                      <Tooltip arrow title={t('diploma')}>
                        <Diploma
                          id={DIPLOMA}
                          className={clsx(classes.actionIcDoc, classes.onHover)}
                          onClick={(evt) => this.showModal(evt, item)}
                        />
                      </Tooltip>
                    ) : (
                      <span
                        className={clsx(classes.actionIcDoc)}
                        style={{
                          display: 'grid'
                        }}
                      />
                    )}
                  </>
                )}
              </span>
            ),
            className: classes.center
          }
        ]
      };
    };

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: t('documentsCount'),
          name: statistics?.totalCount
        },
        {
          label: t('regulations'),
          name: statistics?.documentsCount
        },
        { label: t('diplomas'), name: statistics?.certificatesCount }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
              shouldShowBtn
            />
            <Modal
              open={isModalOpened}
              close={this.hideModal}
              {...{ onClick, dialogTitle, dialogContent, buttonPurpose }}
              specificDetails={{
                className: {
                  ...(pressedIcon !== DELETE_ICON && pressedIcon !== DIPLOMA
                    ? { paper: classes.maxWidthModal }
                    : {})
                }
              }}
              {...{ shouldShowDiscardChanges }}
              discardOrKeepEditing={this.discardOrKeepEditing}
            />
            <Toolbar
              search={searchBar}
              clearSearch={this.clearSearch}
              textChange={this.tableSearch}
              onClick={this.showModal}
              showButton={!shouldDisableEditing}
              specificDetails={{
                className: { wrapper: classes.toolbar, search: classes.search }
              }}
            />
            <Table
              {...{ recordDataParams }}
              data={filteredFiles.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )}
              onClick={(evt, item) => this.showModal(evt, item)}
              shouldAllowEditing={() => !shouldDisableEditing}
            />
            {filteredFiles.length > 0 && (
              <Pagination
                count={filesCount}
                onPageChange={this.pageChangeHandler}
                onRowsPerPageChange={this.rowsPerPageHandler}
                {...{ page }}
                {...{ rowsPerPage }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Attachment)));
