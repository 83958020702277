import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  paper: {
    backgroundColor: '#fff',
    padding: '0.5rem'
  },

  gridForm: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: '0.5rem'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '0.5rem'
    }
  },

  formAvatarWrapper: {
    '@media (min-width:1024px)': {
      gridRow: 'span 12'
    },
    '@media (max-width:1023px)': {
      gridRow: 'span 3'
    }
  },

  formAvatarInnerWrapper: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridRowGap: '1.5rem'
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  formAvatar: {
    '@media (min-width:1024px)': {
      width: '16em',
      height: '18.5em'
    },
    '@media (max-width:1023px)': {
      height: '8.5em',
      width: '7em',
      justifySelf: 'center'
    },
    '@media (max-width:767px)': {
      marginBottom: '1rem'
    }
  },

  formSave: {
    marginBottom: '1.5rem',
    height: '3.35em',
    width: '100%'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  pairInputs: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 2',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.5rem'
    },
    '@media (max-width:1023px)': {
      display: 'contents'
    }
  },

  span2and3: {
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  tournamentsListElem: {
    background: '#E9ECF6',
    color: '#262441',
    fontSize: '1.08rem',
    height: '2.45em',
    padding: '0.2rem 1rem',
    marginBottom: '1rem',
    border: 'none',
    minHeight: '2.625em'
  },

  tournamentsListItem: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridTemplateColumns: '1fr 5.5em'
    },
    background: '#8996AC',
    padding: '0.5rem 1rem',
    color: '#fff',
    borderTopLeftRadius: '0.3rem',
    borderTopRightRadius: '0.3rem',
    minHeight: '2.625em'
  },

  tournamentsListItemWrapper: {
    display: 'grid',
    marginBottom: '1rem',
    border: '1px solid #8996AC',
    borderRadius: '0.3rem'
  },

  tournamentsListItemCategoriesWrapper: {
    display: 'grid',
    gridRowGap: '0.5rem',
    padding: '0.5rem 1rem'
  },

  tournamentsListCategory: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 5.5em'
    },
    '@media (max-width:1023px)': {
      gridTemplateColumns: '1fr 2em'
    }
  },

  places2Win: {
    '@media (min-width:1024px)': {
      marginLeft: 0
    }
  },

  assignedCategoriesWrapper: {
    display: 'grid',
    marginBottom: '1.5rem',
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  fullWidth: {
    width: '100%'
  },

  icon: {
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#fff'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  listWrapper: {
    display: 'grid',
    gridRowGap: '0.5rem'
  },

  listRecord: {
    border: '1px solid #000',
    padding: '1rem',
    borderRadius: '0.2rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1.3rem'
  },

  issue: {
    color: '#ff0000'
  },

  marginRight: {
    marginRight: '5px'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  },

  checkboxPaddingLeft: {
    '&.MuiCheckbox-root': {
      paddingLeft: 0
    }
  },

  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  maxHeightTxtInput: {
    height: '5em'
  },

  maxHeightTxtInputMultipleline: {
    minHeight: '5em'
  },

  ellipsis: {
    '& .MuiInputLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 1.5rem)'
    },
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },

  saveBtn: {
    '@media (min-width:1024px)': {
      gridColumnStart: '4'
    },
    '@media (max-width:676px)': {
      marginTop: '1rem'
    }
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused, &.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 1)'
      }
    }
  },

  buttonsBorder: {
    border: '1px solid #98A5BA',
    borderRadius: '0.2rem'
  }
}));
