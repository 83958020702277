export const styles = (theme) => ({
  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginTop0: {
    marginTop: 0
  },

  margin1lg: {
    '@media (min-width:901px)': {
      margin: '0 1rem'
    }
  },

  docImg: {
    width: '5em',
    height: '5em',
    objectFit: 'contain',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '0.5rem'
  },

  downloadIc: {
    width: '1.5em',
    height: '1.5em',
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  left: {
    marginLeft: 'auto'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  halfWidth: {
    width: '50%'
  },

  sortLabel: {
    color: '#99A5B1'
  },

  fullWidth: {
    width: '100%'
  },

  labelLine: {
    borderTop: '1px solid',
    width: '25em',
    marginLeft: '0.2rem'
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#2D63EE'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2D63EE',
      opacity: 0.6
    }
  },

  checkboxDefault: {
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#2D63EE'
    }
  }
});
