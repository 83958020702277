import { Fragment } from 'react';
import { ro, ru, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import Logo from '../../assets/img/club_logo.png';
import Signature from '../../assets/img/signature.jpg';
import Stamp from '../../assets/img/stamp.webp';

import {
  GENDER,
  ID,
  NAME,
  EMAIL,
  AVATAR,
  COUNTRY_ID
} from '../../helpers/constants';
import {
  selectedValue,
  countryLabel,
  isDesktopView
} from '../../helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';
import { ReactComponent as DateIcon } from '../../assets/img/date.svg';
import UploadImage from '../UploadImage/UploadImage';
import { useStyles } from './StylesAccountDetails';

const AccountDetails = (props) => {
  const {
    updateUser,
    values,
    countries,
    allRegions,
    selectOption,
    fetchRegionBySelectedCountry,
    handleChange,
    dateChange,
    selectedDate,
    role,
    errors,
    onChangeImg,
    showChangePassword,
    showModal,
    viewportWidth
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('i18nextLng');
  const isRuLang = currentLang === 'ru';
  const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
  const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

  const desktopScreen = isDesktopView(viewportWidth);

  const inputs = [
    {
      label: !isRuLang ? t('firstname') : t('lastname'),
      name: FIRST_NAME,
      error: Boolean(!isRuLang ? errors.first_name : errors.last_name),
      helperText: !isRuLang ? errors.first_name : errors.last_name,
      required: true,
      gridArea: 'firstName'
    },
    {
      label: !isRuLang ? t('lastname') : t('firstname'),
      name: LAST_NAME,
      error: Boolean(!isRuLang ? errors.last_name : errors.first_name),
      helperText: !isRuLang ? errors.last_name : errors.first_name,
      required: true,
      gridArea: 'lastName'
    },
    {
      label: t('patronymic'),
      name: 'patronymic',
      gridArea: 'patronymic'
    },
    {
      label: EMAIL,
      name: 'email',
      disabled: role !== 'administrator',
      required: true,
      gridArea: 'email'
    },
    {
      birthday: true,
      gridArea: 'birthday'
    },
    { gender: true, gridArea: 'gender' },
    {
      label: t('nameBranchChief'),
      name: 'branch_chief',
      error: Boolean(errors.branch_chief),
      helperText: errors.branch_chief,
      required: true,
      gridArea: 'branchChief'
    },
    {
      label: !isRuLang ? t('coachFirstName') : t('coachLastName'),
      name: !isRuLang ? 'coach_first_name' : 'coach_last_name',
      error: Boolean(
        errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name']
      ),
      helperText: errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name'],
      gridArea: 'coachFirstName'
    },
    {
      label: !isRuLang ? t('coachLastName') : t('coachFirstName'),
      name: !isRuLang ? 'coach_last_name' : 'coach_first_name',
      error: Boolean(
        errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name']
      ),
      helperText: errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name'],
      gridArea: 'coachLastName'
    },
    { country: true, gridArea: 'country' },
    allRegions?.length > 0
      ? { region: true, gridArea: 'region' }
      : {
          label: t('district'),
          name: 'region',
          gridArea: 'region'
        },
    {
      label: t('city'),
      name: 'city',
      gridArea: 'city'
    },
    {
      label: t('cluborFederationName'),
      name: 'club',
      gridArea: 'club'
    },
    {
      label: t('phone'),
      name: 'phone',
      error: Boolean(errors?.phone),
      helperText: errors?.phone,
      gridArea: 'phone'
    },
    {
      label: t('website'),
      name: 'website',
      gridArea: 'website'
    }
  ];

  const cards_pofile = [
    {
      title: t('logo'),
      id: 'TEST_ID_UPLOAD_LOGO',
      description: t('useImgLater', { name: t('logo').toLowerCase() }),
      param: 'logo',
      src: Logo,
      preview: 'logoPreview',
      className: classes.marginTop20
    },
    {
      title: t('stamp'),
      id: 'TEST_ID_UPLOAD_STAMP',
      description: t('useImgLater', { name: t('stamp').toLowerCase() }),
      param: 'stamp',
      src: Stamp,
      preview: 'stampPreview',
      className: classes.marginTop20
    },
    {
      title: t('signature'),
      id: 'TEST_ID_UPLOAD_SIGNATURE',
      description: t('useImgLater', {
        name: t('signature').toLowerCase()
      }),
      param: 'signature',
      src: Signature,
      preview: 'signaturePreview',
      className: classes.marginTop20
    }
  ];

  const buttons = [
    {
      show: role !== 'operator' && role !== 'recorder',
      style: { gridArea: 'save' },
      label: t('save'),
      isSaveBtn: true,
      onClick: updateUser
    },
    {
      show: showChangePassword,
      style: { gridArea: 'changePassword' },
      label: t('changePassword'),
      isSaveBtn: true,
      onClick: showModal,
      id: 'changePassword'
    },
    {
      show: true,
      style: { gridArea: 'license' },
      label: t('license'),
      isSaveBtn: true,
      onClick: showModal,
      id: 'license'
    }
  ];

  const filterComponent = (
    options,
    label,
    param,
    name,
    item,
    required,
    cb,
    freeSolo,
    additionalItem,
    className,
    gridArea
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      param,
      values[name],
      name === COUNTRY_ID
    );

    return (
      <Filter
        style={{ gridArea: gridArea }}
        className={clsx(classes.fullWidth, classes.asterisk, className)}
        {...{ options }}
        onChange={(evt, val) =>
          selectOption(evt, val, 'userForm', name, param, errors, cb)
        }
        {...{ label }}
        value={currentVal || ''}
        {...{ item }}
        variant="outlined"
        {...{ required }}
        id={name}
      />
    );
  };

  return (
    <div className={clsx(classes.column, classes.paper)}>
      <div className={classes.myProfileLabel}>{t('profile')}</div>
      <div className={classes.flex}>
        <div className={classes.fullWidth}>
          <div className={clsx(classes.formWrapper, classes.fullWidth)}>
            <UploadImage
              id="upload-file"
              className={classes.img}
              onChange={(evt) =>
                onChangeImg(evt, 'userForm', AVATAR, 'imagePreview')
              }
              img={values.imagePreview}
            />
            {inputs.map((item, idx) => (
              <Fragment key={idx}>
                {!item.birthday &&
                  !item.gender &&
                  !item.country &&
                  !item.region && (
                    <TextField
                      style={{ gridArea: item.gridArea }}
                      className={clsx(
                        classes.fullWidth,
                        item.className,
                        classes.asterisk
                      )}
                      inputProps={
                        item.name === 'region' ? { autoComplete: 'off' } : {}
                      }
                      label={item.label}
                      name={item.name}
                      onChange={handleChange}
                      value={values[item.name] || ''}
                      variant="outlined"
                      disabled={item.disabled}
                      error={item.error}
                      helperText={item.helperText}
                      required={item.required}
                      InputLabelProps={{
                        style: { color: '#8996AC' }
                      }}
                    />
                  )}
                {item.birthday && (
                  <MuiPickersUtilsProvider
                    locale={[ru, ro, de].find(
                      (item) => item.code === currentLang
                    )}
                    utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ gridArea: item.gridArea }}
                      cancelLabel={t('cancel')}
                      okLabel={t('apply')}
                      keyboardIcon={<DateIcon />}
                      className={clsx(classes.fullWidth, item.className)}
                      inputVariant="outlined"
                      name="birthday"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t('birthday')}
                      value={selectedDate}
                      onChange={(date) => dateChange(date)}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                      InputLabelProps={{
                        style: { color: '#8996AC' }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
                {item.gender &&
                  filterComponent(
                    GENDER,
                    t('gender'),
                    NAME,
                    'gender',
                    NAME,
                    null,
                    null,
                    null,
                    null,
                    null,
                    item.gridArea
                  )}
                {item.country &&
                  filterComponent(
                    countries,
                    t('country'),
                    ID,
                    'country_id',
                    {
                      firstName: 'iso3',
                      lastName: countryLabel(currentLang),
                      parathesis: true
                    },
                    true,
                    (val) =>
                      fetchRegionBySelectedCountry &&
                      fetchRegionBySelectedCountry(val),
                    null,
                    null,
                    null,
                    item.gridArea
                  )}
                {item.region &&
                  filterComponent(
                    allRegions,
                    t('district'),
                    NAME,
                    'region',
                    NAME,
                    null,
                    null,
                    true,
                    { name: values.region },
                    item.className,
                    item.gridArea
                  )}
              </Fragment>
            ))}
          </div>
          <div className={classes.marginLeft230}>
            <div className={classes.logoStampWrapper}>
              {cards_pofile.map((card, cardIdx) => (
                <div
                  key={cardIdx}
                  className={clsx(classes.flex, card.className)}>
                  <UploadImage
                    id={card.id}
                    className={classes.cardImg}
                    onChange={(evt) =>
                      onChangeImg(evt, 'userForm', card.param, card.preview)
                    }
                    img={values[card.preview] || card.src}
                  />
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.cardTxt
                    )}>
                    <span className={clsx(classes.flex, classes.h1)}>
                      {card.title}
                    </span>
                    <span className={classes.description}>
                      {card.description}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.gridButton}>
            {buttons.map(
              (button, index) =>
                button.show && (
                  <Button
                    key={index}
                    style={button.style}
                    label={button.label}
                    isSaveBtn={button.isSaveBtn}
                    onClick={button.onClick}
                    id={button.id}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
