import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  marginTop40: {
    marginTop: '40px'
  },
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },

  fullWidth: {
    width: '100%'
  },

  left: {
    marginLeft: 'auto'
  },
  expandLess: {
    transform: 'rotate(180deg)'
  },
  column: {
    flexDirection: 'column'
  },
  marginTop: {
    marginTop: '1em'
  },
  finalistsMatch: {
    padding: '.4em'
  },
  paper: {
    margin: '20px 0',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    fontWeight: 'bold',
    fontSize: '20px',
    transitionDuration: '0.8s',
    alignContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  },
  transferBattle: {
    display: 'none'
  },
  teamKatas: {
    whiteSpace: 'pre-line'
  },
  panelHeader: {
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    padding: '.5em',
    marginBottom: '1em',
    fontSize: '1.2rem',
    fontWeight: 500,
    color: theme.palette.primary.contrastText
  },
  categoryType: {
    marginRight: '5px'
  },
  bronzeSkirmish: {
    marginTop: '1em',
    marginLeft: 'auto'
  },
  skirmisheNum: {
    transform: 'rotate(-90deg)',
    height: 'fit-content',
    alignSelf: 'center',
    minHeight: '1em'
  },

  fighters: {
    borderLeft: '1px solid rgba(0, 29, 61, 0.4)',
    width: '100%',
    '& > :first-child': {
      borderBottom: '1px solid rgba(0, 29, 61, 0.4)'
    }
  },

  fighter: {
    padding: '.5rem',
    height: '2em',
    display: 'flex',
    alignItems: 'center'
  },

  winnerTriangle: {
    borderTop: '5px solid transparent',
    borderLeft: `10px solid ${theme.palette.primary.main}`,
    borderBottom: '5px solid transparent'
  },
  bracket: {
    display: 'flex',
    flexDirection: 'row'
  },
  roundTitle: {
    color: theme.palette.primary.main,
    fontSize: '0.95rem',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '0.5em'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 'calc(100% - 1em)'
  },
  item: {
    listStyleType: 'none',
    flex: '0 1 auto',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    padding: '0.5em 0'
  },
  match: {
    display: 'flex',
    width: 'calc(100% - 1em)',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 29, 61, 0.4)',
    borderRadius: '4px'
  },
  header: {
    height: '5em',
    display: 'flex',
    justifyContent: 'center'
  },
  rectangle: {
    margin: '0 0.5em',
    background: theme.palette.primary.main,
    height: '3em',
    position: 'relative',
    '@media(max-width:580px)': {
      width: '14em'
    },
    '@media(min-width:581px) and (max-width:680px)': {
      width: '20em'
    },
    '@media(min-width:681px) and (max-width:900px)': {
      width: '30em'
    },
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    padding: '0.5em',
    '&:after': {
      borderRadius: '4px',
      borderTop: `2em solid ${theme.palette.primary.main}`,
      '@media(max-width:580px)': {
        borderLeft: '7em solid transparent',
        borderRight: '7em solid transparent'
      },
      '@media(min-width:581px) and (max-width:680px)': {
        borderLeft: '10em solid transparent',
        borderRight: '10em solid transparent'
      },
      '@media(min-width:681px) and (max-width:900px)': {
        borderLeft: '15em solid transparent',
        borderRight: '15em solid transparent'
      },
      content: '""',
      position: 'absolute',
      top: '3em'
    }
  },
  rectangleTxt: {
    zIndex: 1,
    top: '100%',
    position: 'absolute'
  },
  rectangleRight: {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    height: '3em',
    position: 'relative',
    width: '2em',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    '&:after': {
      borderRadius: '4px',
      borderTop: '1.5em solid transparent',
      borderLeft: `1.5em solid ${theme.palette.primary.main}`,
      borderBottom: '1.5em solid transparent',
      content: '""',
      position: 'absolute',
      left: '1.95em'
    }
  },
  rectangleRightTxt: {
    zIndex: 1,
    bottom: '30%',
    left: '30%',
    position: 'absolute'
  },
  rectangleLeftTxt: {
    zIndex: 1,
    bottom: '30%',
    right: '40%',
    position: 'absolute'
  },
  rectangleLeft: {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    height: '3em',
    position: 'relative',
    width: '2em',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    '&:before': {
      borderRadius: '4px',
      borderTop: '1.5em solid transparent',
      borderRight: `1.5em solid ${theme.palette.primary.main}`,
      borderBottom: '1.5em solid transparent',
      content: '""',
      height: 0,
      position: 'absolute',
      right: '1.85em',
      width: 0
    }
  },
  iconWrapper: {
    height: '1.5em'
  },
  icon: {
    width: '1.5em',
    height: '1.5em'
  },
  relative: {
    position: 'relative'
  },
  nextSkirmishNum: {
    transform: 'rotate(-90deg)',
    top: '40%',
    position: 'absolute',
    left: '-0.8em',
    zIndex: 1
  },
  link: {
    position: 'absolute',
    listStyleType: 'none',
    top: '50%',
    height: '6em',
    width: '1em',
    right: '0',
    border: `2px solid ${theme.palette.primary.main}`,
    borderLeft: 'none'
  },
  linkArrow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '30%',
      left: '1em',
      borderTop: '1em solid transparent',
      borderLeft: `1em solid ${theme.palette.primary.main}`,
      borderBottom: '1em solid transparent'
    }
  },
  linkTransfer: {
    position: 'absolute',
    listStyleType: 'none',
    top: '50%',
    right: '0',
    width: '1em',
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 0.5rem)'
  }
}));
