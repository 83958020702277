import { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Bronze } from '../../assets/img/medal_bronze.svg';
import { ReactComponent as Gold } from '../../assets/img/medal_gold.svg';
import { ReactComponent as Silver } from '../../assets/img/medal_silver.svg';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from '@material-ui/core/Collapse';

import Katas from './Katas/Katas';
import Tameshiwari from './Tameshiwari/Tameshiwari';
import Places2Win from 'components/Places2Win/Places2Win';

import { useStyles } from './StylesTournamentBracket';

const TournamentBracket = (props) => {
  const {
    bracketTatami,
    itemToggle,
    collapsed,
    bracketCategoryType,
    tournamentData
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const placesComponent = (item) => {
    return <Places2Win {...{ item }} paramName="Place" />;
  };
  const skirmisheComponent = (number, fighters, idx, matchClass, itemClass) => (
    <li key={idx} className={clsx(classes.item, itemClass)}>
      <div className={clsx(classes.match, matchClass)}>
        <div className={classes.skirmisheNum}>{number}</div>
        <div className={classes.fighters}>
          {fighters.map((fighter, fighterIdx) => {
            const currentColor =
              tournamentData?.[
                fighterIdx === 0
                  ? 'participant_up_color'
                  : 'participant_down_color'
              ];

            return (
              <div
                style={{
                  borderLeft: `.4em solid ${
                    currentColor === 'white' ? 'lavender' : currentColor
                  }`
                }}
                className={clsx(classes.fighter)}
                key={fighterIdx}>
                <span
                  className={clsx(
                    classes.flex,
                    classes.fullWidth,
                    classes.centerVertically
                  )}>
                  <span className={classes.ellipsis}>
                    {fighter.Number
                      ? [fighter.Number, '.', fighter.Name].join(' ')
                      : fighter.Name}
                  </span>
                  {!fighter.Place
                    ? fighter.WonThisFight && (
                        <div className={classes.left}>
                          <div className={classes.winnerTriangle}></div>
                        </div>
                      )
                    : placesComponent(fighter)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </li>
  );

  return (
    <div className={classes.marginTop40}>
      {bracketTatami ? (
        <>
          <span className={clsx(classes.panelHeader, classes.flex)}>
            <span className={classes.categoryType}>{bracketCategoryType}</span>
            {[t('tatami'), bracketTatami && bracketTatami.Name].join(' ')}
          </span>
          {bracketTatami.Categories &&
            bracketTatami.Categories.length > 0 &&
            bracketTatami.Categories.map((category, key) => {
              const lastRoundNumber = category.Rounds[0].Number;
              const finalistsByCategory = Object.values(category.Finalists);

              return (
                <Fragment key={key}>
                  <span
                    className={clsx(classes.paper, classes.flex)}
                    onClick={() => itemToggle(key)}>
                    {category.Name}
                    <ExpandMore
                      className={clsx(
                        classes.left,
                        collapsed[key] && classes.expandLess
                      )}
                    />
                  </span>
                  <Collapse in={collapsed[key]} timeout="auto" unmountOnExit>
                    <PerfectScrollbar>
                      <div className={classes.container}>
                        <div className={clsx(classes.bracket)}>
                          {[...category.Rounds]
                            .reverse()
                            .map((round, roundIdx) => {
                              return (
                                <div
                                  key={roundIdx}
                                  className={clsx(
                                    classes.section,
                                    classes.roundFighter
                                  )}>
                                  {category.Rounds.length > 1 && (
                                    <h3 className={classes.roundTitle}>
                                      {[t('round'), round.Number].join(' ')}
                                    </h3>
                                  )}
                                  <ul
                                    key={round.Number}
                                    className={clsx(classes.list)}>
                                    {round.Skirmishes.map(
                                      (skirmish, skirmishIdx) =>
                                        skirmisheComponent(
                                          skirmish.Number,
                                          skirmish.Fighters,
                                          skirmishIdx,
                                          round.Number !== '1' &&
                                            classes.matchLink,
                                          [
                                            lastRoundNumber !== round.Number &&
                                              classes.itemLink,
                                            category.Rounds.length > 1 &&
                                              round.Number === '1' &&
                                              skirmish.Fighters.some(
                                                (fighter) =>
                                                  !fighter.Name.trim()
                                              ) &&
                                              classes.transferBattle
                                          ]
                                        )
                                    )}
                                  </ul>
                                </div>
                              );
                            })}
                        </div>
                        {!category.BronzeSkirmish?.Number ? null : (
                          <div
                            className={clsx(
                              classes.section,
                              classes.bronzeSkirmish
                            )}>
                            <h3 className={classes.roundTitle}>
                              {t('bronzeSkirmish')}
                            </h3>
                            <ul className={clsx(classes.list)}>
                              {skirmisheComponent(
                                category.BronzeSkirmish.Number,
                                category.BronzeSkirmish.Fighters
                              )}
                            </ul>
                          </div>
                        )}
                        {finalistsByCategory &&
                          finalistsByCategory.some((it) => it.Id) && (
                            <div
                              className={clsx(
                                classes.section,
                                classes.bronzeSkirmish
                              )}>
                              <h3 className={classes.roundTitle}>
                                {t('winners')}
                              </h3>
                              {finalistsByCategory.map((it, finalistsIdx) =>
                                it.Id ? (
                                  <span
                                    className={classes.flex}
                                    key={finalistsIdx}>
                                    <span className={classes.item}>
                                      <span className={classes.flex}>
                                        {it.Place} {t('rank')}
                                      </span>
                                      <span
                                        className={clsx(
                                          classes.flex,
                                          classes.match,
                                          classes.finalistsMatch,
                                          classes.fighter
                                        )}>
                                        {[
                                          it.Number ? `${it.Number}.` : '',
                                          it.Name
                                        ].join('')}
                                        {placesComponent(it)}
                                      </span>
                                    </span>
                                  </span>
                                ) : null
                              )}
                            </div>
                          )}
                      </div>
                    </PerfectScrollbar>
                  </Collapse>
                </Fragment>
              );
            })}
          {bracketTatami.TameshiwariGLCategories && (
            <Tameshiwari
              {...{ bracketTatami }}
              {...{ itemToggle }}
              {...{ collapsed }}
              {...{ placesComponent }}
            />
          )}
          {bracketTatami.KataCategories && (
            <Katas
              {...{ bracketTatami }}
              {...{ itemToggle }}
              {...{ collapsed }}
              {...{ placesComponent }}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default TournamentBracket;
