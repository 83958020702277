import clsx from 'clsx';

import TopbarP2 from './TopbarP2';
import { useStyles } from './Styles';
import Main from '../Main';

const Minimal = (props) => {
  const { children, hideNav, pageTitle, specificDetails } = props;
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <Main
        {...{ children }}
        {...{ pageTitle, specificDetails }}
        {...{ hideNav }}
      />
    </main>
  );
};

export default Minimal;
