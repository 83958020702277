import { makeStyles } from '@material-ui/styles';
import { margins } from 'containers/RegistrarsCoaches/Styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  dropdownIcon: {
    '& .MuiAutocomplete-hasPopupIcon, & .MuiAutocomplete-inputRoot': {
      paddingRight: '1em'
    }
  },

  fullWidth: {
    width: '100%'
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6'
    }
  },

  z10: {
    zIndex: 10
  },

  main: {
    padding: '.5rem 1.5rem',
    background: '#E9ECF6'
  },

  breadcrumbsDefault: { color: '#8996AC' },

  breadcrumbsActive: {
    color: '#262441'
  },

  marginTB1LR0: {
    margin: '1rem 0'
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  marginRL0Dot5: {
    margin: '0 0.5rem'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  buttonSize: {
    width: '2.2rem',
    height: '2.2rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  filterInput: {
    border: '1px solid #98A5BA',
    backgroundColor: '#ffffff',
    borderRadius: '0.2rem',
    textColor: '#8996AC',
    width: '2.3rem',
    height: '2.3rem',
    textAlign: 'center'
  },
  langText: {
    fontSize: 18, 
    padding: '2px 6px',
    color: '#8996AC',
    weight: 700,
    '& .MuiAutocomplete-input': {
      textOverflow: 'clip'
    }
  }
}));
