const styles = () => ({
  flex: {
    display: 'flex'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  bgWhite: { background: '#fff' },

  marginLeft0dot4: {
    marginLeft: '0.4rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  center: {
    justifyContent: 'center'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  gridFilterArea: {
    gridArea: 'gridFilterArea'
  },

  gridButtonArea: {
    gridArea: 'gridButtonArea'
  },
  gridSearchArea: {
    gridArea: 'gridSearchArea'
  },

  grid: {
    display: 'grid',
    gap: '20px',
    alignItems: 'center',
    '@media (min-width:1024px)': {
      gridTemplateColumns: 'repeat(8, 1fr)',
      gridTemplateAreas: `
        "gridSearchArea gridSearchArea gridSearchArea gridSearchArea gridFilterArea gridFilterArea gridFilterArea gridButtonArea"
      `
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
          "gridSearchArea gridSearchArea"
          "gridFilterArea gridButtonArea"
        `,
      marginBottom: '24px'
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
          "gridSearchArea"
          "gridFilterArea"
          "gridButtonArea"
        `,
      marginBottom: '24px'
    }
  },

  plus: {
    marginRight: '.5em',
    fontSize: '26px'
  },

  isSaveBtn: {
    width: '285px',
    height: '47px'
  },
  maxHeight: {
    '@media (min-width:768px)': {
      width: '860px'
    },
    '@media (max-width:767px)': {
      width: '400px'
    }
  }
});

export default styles;
