const styles = () => ({
  flex: {
    display: 'flex'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  maxColumnWidth: {
    maxWidth: '100px'
  },

  marginTop05: {
    '@media (max-width: 1023px)': {
      marginTop: '0.5rem'
    }
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  },

  modalWidthViewEvent: {
    '@media (min-width:1024px)': {
      minWidth: '80em'
    }
  },

  gridFilterArea: {
    gridArea: 'gridFilterArea'
  },

  gridButtonArea: {
    gridArea: 'gridButtonArea'
  },
  gridSearchArea: {
    gridArea: 'gridSearchArea'
  },

  grid: {
    display: 'grid',
    gap: '20px',
    alignItems: 'center',
    '@media (min-width:1024px)': {
      gridTemplateColumns: 'repeat(8, 1fr)',
      gridTemplateAreas: `
        "gridSearchArea gridSearchArea gridSearchArea gridSearchArea gridFilterArea gridFilterArea gridFilterArea gridButtonArea"
      `
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
          "gridSearchArea gridSearchArea"
          "gridFilterArea gridButtonArea"
        `,
      marginBottom: '24px'
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
          "gridSearchArea"
          "gridFilterArea"
          "gridButtonArea"
        `,
      marginBottom: '24px'
    }
  },

  plus: {
    marginRight: '.5em',
    fontSize: '26px'
  },

  usersHeader: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    marginBottom: '24px'
  },
  outlined: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        width: '160px',
        padding: '0',
        height: '11px'
      }
    }
  }
});

export default styles;
