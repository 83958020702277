import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as CopyButton } from '../../assets/img/copy_button.svg';
import CheckIcon from '@material-ui/icons/Check';
import { useStyles } from './Styles';

const CopyTxt = (props) => {
  const { copiedTxt, componentClassName, informativeText } = props;

  const classes = useStyles();
  const [state, setState] = useState(null);
  const onCopyTxt = async () => {
    try {
      await navigator.clipboard.writeText(copiedTxt);
      setState(true);
      setTimeout(() => setState(false), 500);
    } catch {}
  };

  return (
    <span
      className={clsx(
        classes.root,
        classes.wordBreak,
        classes.flex,
        componentClassName
      )}>
      <span className={clsx(classes.flex, classes.informativeTxt)}>
        {informativeText}
      </span>
      <span
        className={clsx(classes.flex, classes.alignItems, classes.fullWidth)}>
        {state && <CheckIcon className={classes.success} />}
        <CopyButton onClick={onCopyTxt} />
      </span>
    </span>
  );
};

export default CopyTxt;
