import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  grid: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridColumnGap: '0.5rem',
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '0.5rem'
    }
  },

  gridAltNames: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridColumn: 'span 3'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  paper: {
    backgroundColor: '#fff'
  },

  paperMargins: {
    backgroundColor: '#fff',
    padding: '20px',
    paddingTop: 0,
    marginBottom: '40px'
  },

  flex: {
    display: 'flex'
  },

  checkboxAbsoluteCatsm: {
    margin: 0,
    justifyContent: 'flex-end',
    alignSelf: 'flex-start'
  },

  input: {
    height: '5em'
  },

  btn: {
    height: '3.82em',
    '@media (min-width:1024px)': {
      width: 'calc((100%  - 1.5rem)/4)'
    },
    '@media (min-width:768px)': {
      gridColumn: '1 / -1'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      width: 'calc((100%  - .5rem)/2)'
    },
    '@media (max-width:767px)': {
      width: '100%'
    },
    display: 'flex',
    marginLeft: 'auto'
  },

  marginLeft05: {
    marginLeft: '0.5rem'
  },

  infoMsg: {
    padding: '0.5rem 0',
    fontSize: '12px',
    opacity: '0.5',
    alignItems: 'center',
    '@media (min-width:1024px)': {
      gridColumn: 'span 4'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumn: 'span 2'
    }
  },

  loadingState: {
    height: 'fit-content'
  },

  marginTop15: {
    marginTop: '15px'
  },

  fullWidth: {
    width: '100%'
  },

  ellipsis: {
    '& .MuiInputLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 1.5rem)'
    },
    '& input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
}));
