import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '../../Buttons/ActionButtons';
import { SearchInput } from 'components';
import Filter from '../../Filter/Filter';
import useStyles from './Styles';

const UsersToolbar = (props) => {
  const {
    textChange,
    pressEnter,
    onClick,
    search,
    clearSearch,
    showButton,
    styleCard,
    className,
    filter,
    role,
    content,
    showParticipantForm,
    shouldHideSearch,
    specificDetails,
    closeIcon
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.row,
        styleCard,
        !shouldHideSearch && (filter ? classes.padding : classes.paddingRight),
        specificDetails?.className?.wrapper
      )}>
      {content && content}
      {filter &&
        role !== 'coach' &&
        filter
          .filter((it) => it)
          .map(({ options, onChange, label, item, optional, value }, idx) => (
            <Filter
              value={value || ''}
              disableClearable
              key={`${item}_${idx}`}
              className={clsx(classes.filter, idx !== 0 && classes.paddingLeft)}
              {...{ options }}
              {...{ onChange }}
              {...{ label }}
              {...{ item }}
              {...{ optional }}
            />
          ))}
      {!shouldHideSearch && (
        <SearchInput
          onChange={textChange}
          id="search-input"
          onKeyPress={pressEnter}
          isSearchInactive={!search}
          value={search || ''}
          {...{ clearSearch }}
          className={clsx(
            className,
            classes.marginLR0dot65,
            specificDetails?.className?.search
          )}
          {...{ specificDetails }}
        />
      )}
      {showButton && !showParticipantForm && (
        <Button
          className={classes.addButton}
          id="add_new_button"
          {...{ onClick }}
          isSaveBtn={true}
          label={
            <>
              <span className={classes.plus}>&#x002B;</span>
              {t('addNew')}
            </>
          }
        />
      )}
      {closeIcon}
    </div>
  );
};

export default UsersToolbar;
