import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid'
  },

  gridRecords: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridTemplateColumns: '15em 1fr'
    }
  },

  border: {
    borderRadius: '0.563rem',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  borderBtn: {
    borderRadius: '0.2rem',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  padding0dot05: {
    padding: '0.05rem'
  },

  gridRecordsValue: {
    display: 'grid',
    background: '#fff',
    padding: '0.05rem',
    '@media (min-width:768px)': {
      borderTopRightRadius: '.5rem'
    },
    borderBottomRightRadius: '.5rem'
  },

  cardMainPartWrapper: {
    borderTopLeftRadius: '.5rem',
    '@media (min-width:768px)': {
      borderBottomLeftRadius: '.5rem'
    },
    '@media (max-width:767px)': {
      borderTopRightRadius: '.5rem'
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginLeft0dot75: {
    marginLeft: '.75rem'
  },

  placeholderRecord: {
    color: '#8996AC',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap'
  },

  collpasewrapperInner: {
    '& .MuiCollapse-wrapperInner': { display: 'grid', gap: '0.625rem' }
  },

  gridGap10: {
    display: 'grid',
    gap: '0.625rem'
  },

  valueRecord: {
    color: '#262441'
  },

  recordValuesInnerWrapper: {
    lineHeight: '1.5rem',
    padding: '0.45rem'
  },

  isCategoriesColumn: {
    '@media (max-width:1023px)': {
      gridArea: 'categories'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  marginLeft0dot5: {
    marginLeft: '.5rem'
  },

  block: {
    display: 'block'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  activeSort: {
    color: '#000'
  },

  scrollToTopBtn: {
    display: 'none',
    bottom: '3.5rem',
    right: '30px',
    zIndex: 10,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 29, 61, 0.5)',
    width: '3em',
    height: '3em',
    position: 'fixed',
    padding: '5px',
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  searchIpnut: {
    gridArea: 'search',
    height: '3em',
    padding: '0.2em 0.5rem',
    color: '#8996AC',
    border: '1px solid #8996AC',
    borderRadius: '.5rem',
    margin: 0,
    fontSize: 'unset',
    boxShadow: 'none'
  },

  heightFitContent: {
    height: 'fit-content'
  },

  thumbnail: {
    border: '1px solid #8996AC',
    marginTop: '0.5rem',
    width: '54px',
    height: '72px',
    objectFit: 'cover',
    borderRadius: '0.5rem'
  },

  buttonsBorder: {
    border: '1px solid #98A5BA',
    borderRadius: '0.2rem'
  },

  buttonsSize: {
    width: '1.5em',
    height: '1.5em'
  },

  recordName: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.1rem',
    minHeight: '2.75rem'
  },

  hideElem: {
    '@media (max-width:1023px)': {
      display: 'none'
    }
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  marginLeft04: {
    marginLeft: '0.4rem'
  },

  issue: {
    color: '#ff0000',
    marginRight: '5px'
  },

  fullHeight: {
    height: '100%'
  },

  nameWithoutAvatar: {
    margin: '0.5rem',
    width: 'calc(100% - 6.5em)'
  },

  marginTop05: {
    margin: '0.25rem 0 0 0'
  },

  categoryListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },

  rowOrder: {
    minWidth: '2.063em',
    justifyContent: 'center'
  },

  marginLeft10: {
    marginLeft: '10px'
  },

  sortLabel: {
    '@media (max-width:767px)': {
      maxWidth: '130px',
      fontSize: '11px'
    }
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },

  textColor: {
    color: '#2D63EE',
    border: '1px solid #2D63EE'
  },

  paddingLeft01: {
    '@media (min-width:768px)': {
      paddingLeft: '1rem'
    }
  },

  height32: {
    height: '32px'
  }
}));

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width:1024px)': {
        gridTemplateAreas: "'" + props?.map((it) => '.').join(' ') + "'",
        gridTemplateColumns:
          '' +
          props
            ?.map((it) =>
              it.list ? '3fr' : it.key === 'coach_name' ? '2fr' : '1fr'
            )
            .join(' ') +
          ''
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridTemplateAreas:
          "'. . . . . . .' '. categories categories categories categories categories categories'",
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:767px)': {
        gridTemplateAreas:
          "'. . . . .' '. . . . .' 'categories categories categories categories categories'"
      }
    };
  }
}));

export const useStylesRecordsCollapsed = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width:1024px)': {
        gridTemplateAreas: "'" + props?.map((it) => '.').join(' ') + "'",
        gridTemplateColumns:
          '' +
          props
            ?.map((it) =>
              it.list ? '3fr' : it.key === 'coach_name' ? '2fr' : '1fr'
            )
            .join(' ') +
          ''
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridTemplateAreas:
          "'. . . . . . .' '. categories categories categories categories categories categories'",
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:767px)': {
        gridTemplateAreas:
          "'. . . . .' '. . . . .' 'categories categories categories categories categories'"
      }
    };
  }
}));
