import { Fragment, useState } from 'react';
import { ro, ru, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { ReactComponent as DateIcon } from '../../assets/img/date.svg';
import { ReactComponent as DropdownGreyBg } from 'assets/img/dropdown_white_bg&grey_border.svg';

import {
  GENDER,
  ID,
  NAME,
  EMAIL,
  AVATAR,
  COUNTRY_ID
} from '../../helpers/constants';
import { selectedValue, DDMMYYYY, countryLabel } from '../../helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';
import UploadImage from '../UploadImage/UploadImage';

import { useStyles } from './StylesAccountDetailsForm';

const currentLang = localStorage.getItem('i18nextLng');

const AccountDetailsForm = (props) => {
  const {
    updateUser,
    values,
    countries,
    allRegions,
    selectOption,
    formName,
    fetchRegionBySelectedCountry,
    fetchTournamnetInformation,
    handleChange,
    specificDetails,
    dateChange,
    onChangeImg,
    errors
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const role = localStorage.getItem('role');
  const [toggled, setToggled] = useState({ isToggled: false, value: [] });

  const isRuLang = currentLang === 'ru';
  const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
  const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

  const inputs = [
    {
      label: !isRuLang ? t('firstname') : t('lastname'),
      name: FIRST_NAME,
      error: Boolean(!isRuLang ? errors.first_name : errors.last_name),
      helperText: !isRuLang ? errors.first_name : errors.last_name,
      required: true
    },
    {
      label: !isRuLang ? t('lastname') : t('firstname'),
      name: LAST_NAME,
      error: Boolean(!isRuLang ? errors.last_name : errors.first_name),
      helperText: !isRuLang ? errors.last_name : errors.first_name,
      required: true
    },
    {
      label: t('patronymic'),
      name: 'patronymic'
    },
    {
      label: EMAIL,
      name: 'email',
      disabled: role !== 'administrator',
      required: true
    },
    {
      birthday: true,
      isDate: true
    },
    { gender: true, isFilter: true },
    {
      label: t('nameBranchChief'),
      name: 'branch_chief',
      error: Boolean(errors.branch_chief),
      helperText: errors.branch_chief,
      required: true
    },
    {
      label: !isRuLang ? t('coachFirstName') : t('coachLastName'),
      name: !isRuLang ? 'coach_first_name' : 'coach_last_name',
      error: Boolean(
        errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name']
      ),
      helperText: errors?.[!isRuLang ? 'coach_first_name' : 'coach_last_name']
    },
    {
      label: !isRuLang ? t('coachLastName') : t('coachFirstName'),
      name: !isRuLang ? 'coach_last_name' : 'coach_first_name',
      error: Boolean(
        errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name']
      ),
      helperText: errors?.[isRuLang ? 'coach_last_name' : 'coach_first_name']
    },
    { country: true, isFilter: true },
    allRegions?.length > 0
      ? { region: true, isFilter: true }
      : {
          label: t('district'),
          name: 'region'
        },
    {
      label: t('city'),
      name: 'city'
    },
    {
      label: t('cluborFederationName'),
      name: 'club'
    },
    {
      label: t('phone'),
      name: 'phone',
      error: Boolean(errors?.phone),
      helperText: errors?.phone
    },
    { label: t('website'), name: 'website', className: classes.marginBottom },
    ...(role !== 'operator' && role !== 'recorder'
      ? [{ save: true, className: classes.saveBtn }]
      : []),
    specificDetails?.requestsList && { requestsList: true }
  ];

  const showTournamentInformation = () => {
    fetchTournamnetInformation(
      values,
      (data) => {
        setToggled((prev) => {
          return { isToggled: !prev?.isToggled, value: data };
        });
      },
      () => {
        console.error();
      }
    );
  };

  const filterComponent = (
    options,
    label,
    param,
    name,
    item,
    required,
    cb,
    freeSolo,
    additionalItem,
    className
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      param,
      values[name],
      name === COUNTRY_ID
    );

    return (
      <Filter
        className={clsx(
          classes.inputHeight,
          classes.fullWidth,
          classes.marginBottom,
          classes.asterisk,
          classes.label,
          className
        )}
        classes={{ inputRoot: classes.outlined }}
        {...{ options }}
        onChange={(evt, val) =>
          selectOption(evt, val, formName, name, param, errors, cb)
        }
        {...{ label }}
        value={currentVal || ''}
        {...{ item }}
        variant="outlined"
        {...{ required }}
        id={name}
      />
    );
  };

  const inputsComponent = (it, idx) => (
    <Fragment key={idx}>
      {!it.isDate && !it.isFilter && !it.save && !it.requestsList && (
        <TextField
          className={clsx(
            classes.inputHeight,
            classes.fullWidth,
            it.className,
            classes.asterisk,
            classes.outlinedTxtInput,
            classes.label
          )}
          inputProps={it.name === 'region' ? { autoComplete: 'off' } : {}}
          label={it.label}
          name={it.name}
          onChange={handleChange}
          value={values[it.name] || ''}
          variant="outlined"
          disabled={it.disabled}
          error={it.error}
          helperText={it.helperText}
          required={it.required}
        />
      )}
      {it.isDate && (
        <MuiPickersUtilsProvider
          locale={[ru, ro, de].find((it) => it.code === currentLang)}
          utils={DateFnsUtils}>
          <KeyboardDatePicker
            cancelLabel={t('cancel')}
            okLabel={t('apply')}
            keyboardIcon={<DateIcon />}
            className={clsx(
              classes.inputHeight,
              classes.fullWidth,
              classes.outlinedTxtInput,
              classes.label,
              it.className
            )}
            inputVariant="outlined"
            name="birthday"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label={t('birthday')}
            value={values.birthday || null}
            onChange={(date) => dateChange(date)}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            error={Boolean(errors.birthday)}
            helperText={errors.birthday}
          />
        </MuiPickersUtilsProvider>
      )}

      {it.isFilter && (
        <>
          {it.gender &&
            filterComponent(GENDER, t('gender'), NAME, 'gender', NAME)}
          {it.country &&
            filterComponent(
              countries,
              t('country'),
              ID,
              'country_id',
              {
                firstName: 'iso3',
                lastName: countryLabel(currentLang),
                parathesis: true
              },
              true,
              (val) =>
                fetchRegionBySelectedCountry &&
                fetchRegionBySelectedCountry(val)
            )}
          {it.region &&
            filterComponent(
              allRegions,
              t('district'),
              NAME,
              'region',
              NAME,
              null,
              null,
              true,
              { name: values.region },
              it.className
            )}
        </>
      )}
      {it.save && (
        <Button
          label={t('save')}
          isSaveBtn={true}
          onClick={updateUser}
          className={clsx(classes.formSave, it.className)}
        />
      )}
      {it.requestsList && (
        <span className={clsx(classes.span2and3)}>
          <Button
            label={
              <span
                className={clsx(
                  classes.flex,
                  classes.fullWidth,
                  classes.centerVertically
                )}>
                {t('tournamentInfo')}
                <DropdownGreyBg
                  className={clsx(
                    classes.leftAuto,
                    toggled?.isToggled && classes.rotate180
                  )}
                />
              </span>
            }
            onClick={showTournamentInformation}
            className={clsx(
              classes.tournamentsListElem,
              classes.centerVertically,
              it.className
            )}
          />
          {toggled?.isToggled &&
            (toggled?.value?.length > 0 ? (
              toggled?.value?.map((tourn, tournIdx) => {
                return (
                  <div
                    key={tournIdx}
                    className={classes.tournamentsListItemWrapper}>
                    <span
                      className={clsx(
                        classes.tournamentsListItem,
                        classes.centerVertically
                      )}>
                      <span>{tourn.name}</span>
                      <span>{DDMMYYYY(tourn.date)}</span>
                    </span>
                  </div>
                );
              })
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                {t('noTournaments')}
              </span>
            ))}
        </span>
      )}
    </Fragment>
  );

  return (
    <div className={classes.gridForm}>
      <UploadImage
        id={`${formName}-file`}
        className={clsx(classes.formAvatar)}
        onChange={(evt) => onChangeImg(evt, formName, AVATAR, 'imagePreview')}
        img={values.imagePreview}
      />
      {inputs.map((it1, itIdx1) =>
        Array.isArray(it1) ? (
          <div key={itIdx1} className={classes.pairInputs}>
            {it1.map((it2, itIdx2) => (
              <div
                key={itIdx2}
                className={clsx(classes.flex, classes.fullWidth)}>
                {inputsComponent(it2, itIdx2)}
              </div>
            ))}
          </div>
        ) : (
          inputsComponent(it1, itIdx1)
        )
      )}
    </div>
  );
};

export default AccountDetailsForm;
